import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { MoreHorizontal } from 'lucide-react';
import { cn } from '@/shadcn/lib/utils';

interface Tab {
  name: string;
  label: string;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  hasNotification?: boolean;
}

interface TabComponentProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (tabName: string) => void;
  className?: string;
  filter?: React.ReactNode;
  hideContent?: boolean;
  activeTabBackgroundTailwindColor?: string;
}

const BREAKPOINTS = {
  MOBILE: 640,
  TABLET: 1024,
  DESKTOP: 1280,
};

const TabComponent = React.forwardRef<HTMLDivElement, TabComponentProps>(
  ({
    tabs,
    activeTab,
    onTabChange,
    className,
    filter,
    hideContent,
    activeTabBackgroundTailwindColor,
    ...props
  }, ref) => {
    const [visibleTabs, setVisibleTabs] = useState<Tab[]>(tabs);
    const [overflowTabs, setOverflowTabs] = useState<Tab[]>([]);
    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

    const getMaxVisibleTabs = useCallback((width: number): number => {
      if (width < BREAKPOINTS.MOBILE) {
        return 2;
      } else if (width < BREAKPOINTS.TABLET) {
        return 3;
      } else if (width < BREAKPOINTS.DESKTOP) {
        return 4;
      } else {
        return 6;
      }
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      const maxVisibleTabs = getMaxVisibleTabs(windowWidth);
      const newVisibleTabs = tabs.slice(0, maxVisibleTabs);
      const newOverflowTabs = tabs.slice(maxVisibleTabs);

      setVisibleTabs(newVisibleTabs);
      setOverflowTabs(newOverflowTabs);
    }, [windowWidth, tabs, getMaxVisibleTabs]);

    return (
      <div ref={ref} className={cn('w-full', className)} {...props}>
        <div className="flex flex-col md:flex-row md:justify-between px-8">
          <div className="flex space-x-2 overflow-hidden">
            {visibleTabs.map((tab) => (
              <button
                key={tab.name}
                className={cn(
                  'relative flex items-center px-4 py-2 rounded-t-lg font-semibold text-sm focus:outline-none transition-colors overflow-hidden font-inter',
                  activeTab === tab.name ? `text-primary ${activeTabBackgroundTailwindColor || 'bg-secondary'}` : 'text-primary-foreground bg-transparent',
                  tab?.disabled ? 'cursor-not-allowed opacity-50' : '',
                )}
                onClick={() => onTabChange(tab.name)}
                disabled={tab?.disabled}
              >
                {activeTab !== tab.name && <span className="absolute inset-0 bg-secondary opacity-20 pointer-events-none" />}
                <div className="relative flex items-center">
                  {tab.hasNotification && <span className="bg-destructive w-1 h-1 -right-1 -top-0.5 absolute rounded-full" />}
                  {tab.icon && <span className="mr-2">{tab.icon}</span>}
                  {tab.label}
                </div>
              </button>
            ))}
            {overflowTabs.length > 0 && (
              <Popover>
                <PopoverTrigger asChild>
                  <button
                    className={cn(
                      'relative flex items-center px-4 py-2 rounded-t-lg font-semibold text-sm focus:outline-none transition-colors overflow-hidden font-inter',
                      'text-primary-foreground bg-transparent hover:text-primary',
                    )}
                  >
                    <span className="absolute inset-0 bg-secondary opacity-20 pointer-events-none" />
                    <div className="relative flex items-center">
                      <MoreHorizontal size={20} />
                    </div>
                  </button>
                </PopoverTrigger>
                <PopoverContent align="end" className="w-48 bg-white shadow-lg rounded-lg p-1">
                  {overflowTabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={cn(
                        'w-full text-left px-4 py-2 text-sm hover:bg-secondary flex items-center',
                        activeTab === tab.name ? 'text-primary bg-secondary' : '',
                        tab.disabled ? 'cursor-not-allowed opacity-50' : '',
                      )}
                      onClick={() => onTabChange(tab.name)}
                      disabled={tab.disabled}
                    >
                      {tab.icon && <span className="mr-2">{tab.icon}</span>}
                      {tab.label}
                    </button>
                  ))}
                </PopoverContent>
              </Popover>
            )}
          </div>
          {filter && <div className="space-x-2 mt-2 md:mt-[-5px]">{filter}</div>}
        </div>
        {!hideContent && (
          <div className="mt-0">
            {tabs.map((tab) => activeTab === tab.name && <div key={tab.name}>{tab.content}</div>)}
          </div>
        )}
      </div>
    );
  },
);

TabComponent.displayName = 'TabComponent';

export default TabComponent;
