import * as React from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ChevronDownIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import { DateRangeFormTriggerProps } from './date-range-form.types';
import { getDisplayText } from './utils';

const DateRangeFormTrigger = React.forwardRef<HTMLButtonElement, DateRangeFormTriggerProps>(
  ({
 className, dateRange, open, ...props
}, ref) => (
  <Button
    ref={ref}
    type="button"
    size="default"
    variant="outlineHeader"
    className={cn('min-w-[200px] justify-between', className)}
    {...props}
  >
    {getDisplayText(dateRange)}
    <ChevronDownIcon className={cn('ml-2 h-4 w-4 transition-transform duration-200', open && 'rotate-180')} />
  </Button>
    ),
);

DateRangeFormTrigger.displayName = 'DateRangeFormTrigger';

export { DateRangeFormTrigger };
