/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';

import { SocialProfileCell } from '@frontend/app/components/MemberTable/CustomCells/SocialProfileCell';
import { getWorkletNameBySpecURI } from '@frontend/app/utils/worklet';
import { TWorklet, TWorkItem, TTask } from '@frontend/app/containers/Projects/types';
import { Button, Tag } from '@revfluence/fresh';
import { EllipsisLabel } from '@frontend/app/components';
import { NameCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/NameCell';
import { LastMessageCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/LastMessageCell';
import { GetAllCompletedMembersQuery_members } from '@frontend/app/queries/types/GetAllCompletedMembersQuery';
import { ColumnKey, Task, FlexTableColumnConfig } from '@frontend/app/containers/Projects/constants';
import { ClientFeature } from '@frontend/app/constants';
import { IMember, IThread, useClientFeatureEnabled } from '@frontend/app/hooks';
import { hasError, hasNotification } from '@frontend/app/utils/task';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import { OwnersCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/OwnersCell';

import { MemberSearchQuery_members_owners as IOwners } from '@frontend/app/queries/types/MemberSearchQuery';
import { GetAllTasksQuery_tasks as ITask } from '@frontend/app/queries/types/GetAllTasksQuery';
import {
  get,
  keyBy,
  filter,
  isEmpty,
  isNumber,
  isNil,
} from 'lodash';
import { ICounts } from '@frontend/app/containers/Projects/hooks';
import styles from '../MembersTable.scss';
import { useMemberColumns } from '../../MemberTable/hooks';
import { formatDate } from '../util';

const { useMemo } = React;

export type FlexMemberTableRow = GetAllCompletedMembersQuery_members & {
  id: string;
  name: string;
  member?: any;
  fields?: any;
  email: string;
  owners?: IOwners[];
  [ColumnKey.DaysInStage]: number;
  [ColumnKey.CTA]?: any;
  [ColumnKey.DateAdded]?: number;
  [ColumnKey.DateCompleted]?: number;
  [ColumnKey.TaskName]?: ITask;
  [ColumnKey.WorkletName]?: ITask;
  [ColumnKey.WorkletTaskName]?: ITask;
  [ColumnKey.PendingContracts]?: string;
  [ColumnKey.PaypalAddress]?: string;
  [ColumnKey.LastPaymentSent]?: Date;
  [ColumnKey.Message]?: string;
  [ColumnKey.Deliverables]?: string;
  [ColumnKey.Price]?: number;
  [ColumnKey.NextDeadline]?: Date;
  [ColumnKey.OpenDeliverables]?: number;
  [ColumnKey.CompletedDeliverables]?: number;
  [ColumnKey.Address1]?: string;
  [ColumnKey.Address2]?: string;
  [ColumnKey.City]?: string;
  [ColumnKey.State]?: string;
  [ColumnKey.PostalCode]?: string;
  [ColumnKey.Country]?: string;
  [ColumnKey.ShippingCountry]?: string;
  [ColumnKey.OrderStatus]?: string;
  [ColumnKey.Quantity]?: string;
  [ColumnKey.Products]?: string;
  [ColumnKey.ShipmentStatus]?: string;
  [ColumnKey.TrackingNumber]?: string;
  [ColumnKey.PromoCode]?: string;
  [ColumnKey.PromoCodeStatus]?: string;
  [ColumnKey.AffiliateLink]?: string;
};

export const useFlexMembersTableColumns = ({
  cellActionsByColumn,
  columnsToDisplay,
  worklets,
  counts,
  task,
}: {
  columnsToDisplay: string[];
  worklets: TWorklet[];
  counts: ICounts;
  task?: TTask;
  cellActionsByColumn?: {
    name: (memberId: IMember['id']) => void;
    lastMessage: (threadId: IThread['id']) => void;
    cta: (workItem: TWorkItem) => void;
    task: THandleTaskSelected;
  };
}) => {
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS);
  const memberColumns = useMemberColumns();
  const isAutomatedApprovalCPSEnabled = useClientFeatureEnabled(ClientFeature.AUTOMATED_APPROVAL_CPS);
  const schemasByName = React.useMemo(
    () => ({
      ...keyBy(memberColumns, (column) => column.headerName),
    }),
    [memberColumns],
  );

  const instagramId = get(schemasByName, 'Instagram')?.schemaId;
  const tiktokId = get(schemasByName, 'TikTok')?.schemaId;

  const startDefaultColumns = [
    {
      ...FlexTableColumnConfig[ColumnKey.Member],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Member].title} />,
      cell: (rowData) => (
        <NameCell
          imageUrl={rowData.row.original.profilePicture}
          name={rowData.row.original.name}
          onClick={() => {
            cellActionsByColumn.name?.(rowData.row.original.id);
          }}
        />
      ),
    },
    {
      ...FlexTableColumnConfig[ColumnKey.Email],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Email].title} />,
      cell: (rowData) => (
        <EllipsisLabel tooltipPlacement="top" showTooltip>
          {rowData.row.original.email}
        </EllipsisLabel>
      ),
    },
    {
      ...FlexTableColumnConfig[ColumnKey.LastMessage],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.LastMessage].title} />,
      cell: (rowData) => (
        <div className={styles.fixedCell}>
          <LastMessageCell
            messageData={rowData.row.original.lastMessage}
            member={rowData.row.original}
            onClick={() => {
              cellActionsByColumn.lastMessage?.(rowData.row.original.lastMessage.threadId);
            }}
            isFlexTable
          />
        </div>
      ),
    },
    {
      ...FlexTableColumnConfig[ColumnKey.WorkletName],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.WorkletName].title} />,
      cell: (rowData) => (
        <Button
          type="link"
          onClick={() => {
            cellActionsByColumn.task?.(rowData.row.original?.workletSpecUri, rowData.row.original?.taskId);
          }}
          style={{ padding: 0 }}
        >
          {getWorkletNameBySpecURI(rowData.row.original?.workletSpecUri, worklets)}
        </Button>
      ),
    },
    {
      ...FlexTableColumnConfig[ColumnKey.CTA],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.CTA].title} />,
      cell: (rowData) => {
        const ctaData = rowData.row.original?.[ColumnKey.CTA];
        return (
          <div className={styles.fixedCell}>
            <Button
              type="link"
              onClick={() => {
                cellActionsByColumn.cta?.(ctaData?.workItem);
              }}
              style={{ padding: 0 }}
            >
              {ctaData?.taskMetaData?.singleCTAText || 'Mark as Done'}
            </Button>
          </div>
        );
      },
    },
    {
      ...FlexTableColumnConfig[ColumnKey.WorkletTaskName],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.WorkletTaskName].title} />,
      cell: (rowData) => {
        const task = rowData.row.original?.[ColumnKey.WorkletTaskName];
        if (isNil(task?.taskName)) return null;

        const error = hasError(task, counts?.[task.workletSpecUri]?.[task.taskId]?.total);
        const notification = hasNotification(task, counts?.[task.workletSpecUri]?.[task.taskId]?.total);
        let color = 'processing'; // Blue
        if (error) {
          color = 'error'; // red
        } else if (notification) {
          color = 'warning'; // Yellow
        }

        return (
          <div className={styles.fixedCell}>
            <Tag
              onClick={() => cellActionsByColumn.task?.(task.workletSpecUri, task.taskId)}
              className={color === 'processing' ? cx(styles.tag, styles.defaultTag) : styles.tag}
              color={color}
            >
              {task.taskName}
            </Tag>
          </div>
        );
      },
    },
  ];

  const endDefaultColumns = [
    {
      ...FlexTableColumnConfig[ColumnKey.Instagram],
      ...{
        id: instagramId,
      },
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Instagram].title} />,
      cell: (rowData) => {
        const memberData = rowData.row.original?._raw;
        return (
          <SocialProfileCell
            isEditable={false}
            isEditing={false}
            network="instagram"
            onChangeValue={() => undefined}
            onToggleIsEditing={() => undefined}
            username={memberData.fields[instagramId]}
            member={memberData}
          />
        );
      },
    },
    {
      ...FlexTableColumnConfig[ColumnKey.TikTok],
      ...{
        id: tiktokId,
      },
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.TikTok].title} />,
      cell: (rowData) => {
        const memberData = rowData.row.original?._raw;
        return (
          <SocialProfileCell
            isEditable={false}
            isEditing={false}
            network="tiktok"
            onChangeValue={() => undefined}
            onToggleIsEditing={() => undefined}
            username={memberData.fields[tiktokId]}
            member={memberData}
          />
        );
      },
    },
    {
      ...FlexTableColumnConfig[ColumnKey.Owners],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Owners].title} />,
      cell: (rowData) => {
        const { owners } = rowData.row.original?.member || {};
        return (
          <div className={styles.fixedCell}>
            <OwnersCell owners={owners} />
          </div>
        );
      },
    },
    {
      ...FlexTableColumnConfig[ColumnKey.DaysInStage],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.DaysInStage].title} />,
      cell: (rowData) => {
        const days = rowData.row.original?.[ColumnKey.DaysInStage];
        return (
          <EllipsisLabel tooltipPlacement="top" showTooltip>
            {days}
          </EllipsisLabel>
        );
      },
    },
    {
      ...FlexTableColumnConfig[ColumnKey.DateAdded],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.DateAdded].title} />,
      cell: (rowData) => (
        <EllipsisLabel tooltipPlacement="top" showTooltip>
          {formatDate(rowData.row.original?.[ColumnKey.DateAdded])}
        </EllipsisLabel>
      ),
    },
    {
      ...FlexTableColumnConfig[ColumnKey.DateCompleted],
      header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.DateCompleted].title} />,
      cell: (rowData) => formatDate(rowData.row.original[ColumnKey.DateCompleted]),
    },
  ];

  const specialColumns = useMemo(
    () => ({
      [ColumnKey.PendingContracts]: {
        ...FlexTableColumnConfig[ColumnKey.PendingContracts],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.PendingContracts].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.PendingContracts]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.PaypalAddress]: {
        ...FlexTableColumnConfig[ColumnKey.PaypalAddress],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.PaypalAddress].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.PaypalAddress]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.LastPaymentSent]: {
        ...FlexTableColumnConfig[ColumnKey.LastPaymentSent],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.LastPaymentSent].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {formatDate(rowData.row.original?.[ColumnKey.LastPaymentSent])}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.Message]: {
        ...FlexTableColumnConfig[ColumnKey.Message],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Message].title} />,
          cell: (rowData) => {
            rowData.row.original?.[ColumnKey.Message];
          },
        },
      },
      [ColumnKey.Deliverables]: {
        ...FlexTableColumnConfig[ColumnKey.Deliverables],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Deliverables].title} />,
          cell: (rowData) => {
            rowData.row.original?.[ColumnKey.Deliverables];
          },
        },
      },
      [ColumnKey.Price]: {
        ...FlexTableColumnConfig[ColumnKey.Price],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Price].title} />,
          cell: (rowData) => (
            <span>
              {isNumber(rowData.row.original?.[ColumnKey.Price])
                ? numeral(rowData.row.original?.[ColumnKey.Price]).format('0,0.[00]')
                : '-'}
            </span>
          ),
        },
      },
      [ColumnKey.NextDeadline]: {
        ...FlexTableColumnConfig[ColumnKey.NextDeadline],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.NextDeadline].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {formatDate(rowData.row.original?.[ColumnKey.NextDeadline])}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.OpenDeliverables]: {
        ...FlexTableColumnConfig[ColumnKey.OpenDeliverables],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.OpenDeliverables].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.OpenDeliverables]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.CompletedDeliverables]: {
        ...FlexTableColumnConfig[ColumnKey.CompletedDeliverables],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.CompletedDeliverables].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.CompletedDeliverables]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.Address]: {
        ...FlexTableColumnConfig[ColumnKey.Address],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Address].title} />,
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            const address1 = schemasByName && member && member.fields[schemasByName[ColumnKey.Address1]?.schemaId];
            const address2 = schemasByName && member && member.fields[schemasByName[ColumnKey.Address2]?.schemaId];
            const city = schemasByName && member && member.fields[schemasByName[ColumnKey.City]?.schemaId];
            const state = schemasByName && member && member.fields[schemasByName[ColumnKey.State]?.schemaId];
            const postalCode = schemasByName && member && member.fields[schemasByName[ColumnKey.PostalCode]?.schemaId];
            const country = schemasByName && member && member.fields[schemasByName[ColumnKey.Country]?.schemaId];

            const combinedAddress = `${address1 || ''} ${address2 || ''} ${city || ''} ${state || ''} ${
              postalCode || ''
            } ${country || ''}`;
            return (
              <EllipsisLabel tooltipPlacement="top" showTooltip>
                {combinedAddress}
              </EllipsisLabel>
            );
          },
        },
      },
      [ColumnKey.Address1]: {
        ...FlexTableColumnConfig[ColumnKey.Address1],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Address1].title} />,
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            return (
              <EllipsisLabel tooltipPlacement="top" showTooltip>
                {schemasByName && member && member.fields[schemasByName[ColumnKey.Address1]?.schemaId]}
              </EllipsisLabel>
            );
          },
        },
      },
      [ColumnKey.Address2]: {
        ...FlexTableColumnConfig[ColumnKey.Address2],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Address2].title} />,
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            return (
              <EllipsisLabel tooltipPlacement="top" showTooltip>
                {schemasByName && member && member.fields[schemasByName[ColumnKey.Address2]?.schemaId]}
              </EllipsisLabel>
            );
          },
        },
      },
      [ColumnKey.City]: {
        ...FlexTableColumnConfig[ColumnKey.City],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.City].title} />,
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            return <span>{schemasByName && member && member.fields[schemasByName[ColumnKey.City]?.schemaId]}</span>;
          },
        },
      },
      [ColumnKey.State]: {
        ...FlexTableColumnConfig[ColumnKey.State],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.State].title} />,
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            return <span>{schemasByName && member && member.fields[schemasByName[ColumnKey.State]?.schemaId]}</span>;
          },
        },
      },
      [ColumnKey.PostalCode]: {
        ...FlexTableColumnConfig[ColumnKey.PostalCode],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.PostalCode].title} />,
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            return (
              <span>{schemasByName && member && member.fields[schemasByName[ColumnKey.PostalCode]?.schemaId]}</span>
            );
          },
        },
      },
      [ColumnKey.Country]: {
        ...FlexTableColumnConfig[ColumnKey.Country],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Country].title} />,
          cell: (rowData) => {
            const member = rowData.row.original?.member;
            return <span>{schemasByName && member && member.fields[schemasByName[ColumnKey.Country]?.schemaId]}</span>;
          },
        },
      },
      [ColumnKey.OrderStatus]: {
        ...FlexTableColumnConfig[ColumnKey.OrderStatus],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.OrderStatus].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.OrderStatus]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.Quantity]: {
        ...FlexTableColumnConfig[ColumnKey.Quantity],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Quantity].title} />,
          cell: (rowData) => {
            rowData.row.original?.[ColumnKey.Quantity];
          },
        },
      },
      [ColumnKey.Products]: {
        ...FlexTableColumnConfig[ColumnKey.Products],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.Products].title} />,
          cell: (rowData) => {
            const products = rowData.row.original?.[ColumnKey.Products];
            return (
              <EllipsisLabel tooltipPlacement="top" showTooltip>
                {!isEmpty(products) && products.join(', ')}
              </EllipsisLabel>
            );
          },
        },
      },
      [ColumnKey.ShipmentStatus]: {
        ...FlexTableColumnConfig[ColumnKey.ShipmentStatus],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.ShipmentStatus].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.ShipmentStatus]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.TrackingNumber]: {
        ...FlexTableColumnConfig[ColumnKey.TrackingNumber],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.TrackingNumber].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.TrackingNumber]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.PromoCode]: {
        ...FlexTableColumnConfig[ColumnKey.PromoCode],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.PromoCode].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.PromoCode]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.PromoCodeStatus]: {
        ...FlexTableColumnConfig[ColumnKey.PromoCodeStatus],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.PromoCodeStatus].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.PromoCodeStatus]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.AffiliateLink]: {
        ...FlexTableColumnConfig[ColumnKey.AffiliateLink],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.AffiliateLink].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.AffiliateLink]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.ShippingAddress]: {
        ...FlexTableColumnConfig[ColumnKey.ShippingAddress],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.ShippingAddress].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.ShippingAddress]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.ShippingCountry]: {
        ...FlexTableColumnConfig[ColumnKey.ShippingCountry],
        ...{
          header: () => <ColumnHeader title={FlexTableColumnConfig[ColumnKey.ShippingCountry].title} />,
          cell: (rowData) => (
            <EllipsisLabel tooltipPlacement="top" showTooltip>
              {rowData.row.original?.[ColumnKey.ShippingCountry]}
            </EllipsisLabel>
          ),
        },
      },
    }),
    [schemasByName],
  );

  const sendProductOrderTaskColumns = useMemo(() => {
    let columns = [];
    if (isPFAColumnsEnabled) {
      columns = [specialColumns[ColumnKey.Address]];
    } else {
      columns = [
        specialColumns[ColumnKey.Address1],
        specialColumns[ColumnKey.Address2],
        specialColumns[ColumnKey.City],
        specialColumns[ColumnKey.State],
        specialColumns[ColumnKey.PostalCode],
        specialColumns[ColumnKey.Country],
      ];
    }
    return columns;
  }, [specialColumns, isPFAColumnsEnabled]);

  const reviewOrderRequestTaskColumns = useMemo(() => {
    const columns = [specialColumns[ColumnKey.ShippingAddress], specialColumns[ColumnKey.ShippingCountry]];

    if (isAutomatedApprovalCPSEnabled) {
      columns.push(specialColumns[ColumnKey.OrderStatus]);
    }

    return columns;
  }, [specialColumns, isAutomatedApprovalCPSEnabled]);

  const taskSpecialColumns = {
    [Task.MemberSignContractTask]: [specialColumns[ColumnKey.PendingContracts]],
    [Task.BrandSignContractTask]: [specialColumns[ColumnKey.PendingContracts]],
    [Task.SendPaymentTask]: [specialColumns[ColumnKey.PaypalAddress], specialColumns[ColumnKey.LastPaymentSent]],
    [Task.WaitingForAgreementTask]: [specialColumns[ColumnKey.Message]],
    [Task.FixErrorSendingTermsTask]: [specialColumns[ColumnKey.Message]],
    [Task.ReviewTermsTask]: [specialColumns[ColumnKey.Deliverables], specialColumns[ColumnKey.Price]],
    [Task.WaitingForContentTask]: [
      specialColumns[ColumnKey.NextDeadline],
      specialColumns[ColumnKey.OpenDeliverables],
      specialColumns[ColumnKey.CompletedDeliverables],
    ],
    [Task.ReviewContentTask]: [
      specialColumns[ColumnKey.NextDeadline],
      specialColumns[ColumnKey.OpenDeliverables],
      specialColumns[ColumnKey.CompletedDeliverables],
    ],
    [Task.WaitingForPostsTask]: [
      specialColumns[ColumnKey.NextDeadline],
      specialColumns[ColumnKey.OpenDeliverables],
      specialColumns[ColumnKey.CompletedDeliverables],
    ],
    [Task.ConfirmAddressesTask]: [
      specialColumns[ColumnKey.Address1],
      specialColumns[ColumnKey.Address2],
      specialColumns[ColumnKey.City],
      specialColumns[ColumnKey.State],
      specialColumns[ColumnKey.PostalCode],
      specialColumns[ColumnKey.Country],
    ],
    [Task.WaitingForAddressConfirmationTask]: [
      specialColumns[ColumnKey.Address1],
      specialColumns[ColumnKey.Address2],
      specialColumns[ColumnKey.City],
      specialColumns[ColumnKey.State],
      specialColumns[ColumnKey.PostalCode],
      specialColumns[ColumnKey.Country],
    ],
    [Task.SubmitOrderTask]: sendProductOrderTaskColumns,
    [Task.ReviewOrderRequestTask]: reviewOrderRequestTaskColumns,
    [Task.OfflineReviewOrderRequestTask]: [
      specialColumns[ColumnKey.ShippingAddress],
      specialColumns[ColumnKey.ShippingCountry],
      specialColumns[ColumnKey.Quantity],
      specialColumns[ColumnKey.Products],
    ],
    [Task.OfflineFulfillOrderTask]: [
      {
        ...specialColumns[ColumnKey.Quantity],
        ...{ title: 'Original Quantity' },
      },
      {
        ...specialColumns[ColumnKey.Products],
        ...{ title: 'Original Product Selected' },
      },
    ],
    [Task.OfflineOrderInTransitTask]: [
      specialColumns[ColumnKey.ShipmentStatus],
      specialColumns[ColumnKey.TrackingNumber],
    ],
    [Task.FixErrorPromoTask]: [specialColumns[ColumnKey.PromoCode], specialColumns[ColumnKey.PromoCodeStatus]],
    [Task.SendLinkTask]: [specialColumns[ColumnKey.AffiliateLink]],
    [Task.SendPromoTask]: [specialColumns[ColumnKey.PromoCode]],
    [Task.SendLinkAndPromoTask]: [specialColumns[ColumnKey.AffiliateLink], specialColumns[ColumnKey.PromoCode]],
  };

  let additionalColumns = [];
  if (task && !isEmpty(taskSpecialColumns[task.taskId])) {
    additionalColumns = taskSpecialColumns[task.taskId];
  }

  const filteredStartColumns = filter(
    startDefaultColumns, (col: FlexMemberTableRow) =>
    columnsToDisplay.includes(col.id),
  );
  const filteredEndColumns = filter(endDefaultColumns, (col: FlexMemberTableRow) => columnsToDisplay.includes(col.id));
  const columns = [...filteredStartColumns, ...additionalColumns, ...filteredEndColumns];
  return columns;
};

interface ColumnHeaderProps {
  title: string;
  className?: string;
  tooltip?: string;
  icon?: React.ReactNode;
}

export const ColumnHeader: React.FC<ColumnHeaderProps> = ({
  title,
  className,
  tooltip,
  icon,
}) => (
  <div className={cx('font-semibold text-[#1F1F21]', className)} title={tooltip}>
    {icon && <span className="mr-2">{icon}</span>}
    {title}
  </div>
);
