import React from 'react';
import { Alert, AlertDescription } from '@frontend/shadcn/components/ui/alert';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import {
 ChevronDown, ImageIcon, Info, Plus, X,
} from 'lucide-react';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import {
  useLandingPageContext,
  FeaturedContentTypeEnum,
} from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import {
 FacebookEmbed, YouTubeEmbed, TikTokEmbed, InstagramEmbed, PinterestEmbed,
} from 'react-social-media-embed';
import { detectContentType } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/contentUtils';
import { ContentSelectionDialog, ContentTypeEnum } from './FeaturedContentModal';

const FeaturedContentProduct: React.FC = () => {
  const {
 landingPageData, setLandingPageData, clearFieldError, fieldErrors,
} = useLandingPageContext();
  const [isContentEnabled, setIsContentEnabled] = React.useState(landingPageData.featuredContent?.isFeaturedContentEnabled || false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(true);

  const savedContent = landingPageData.featuredContent?.urls || [];
  const maxContent = 6;
  const remainingSlots = maxContent - savedContent.length;

  const handleDeleteContent = (index: number) => {
    setLandingPageData((prevData) => ({
      ...prevData,
      featuredContent: {
        ...prevData.featuredContent,
        urls: prevData.featuredContent?.urls.filter((_, i) => i !== index),
      },
    }));
  };

  const handleContentEnabled = (value: boolean) => {
    setIsContentEnabled(value);
    setLandingPageData((prevData) => ({
      ...prevData,
      featuredContent: {
        ...prevData.featuredContent,
        isFeaturedContentEnabled: value,
      },
    }));
    if (!value) {
      clearFieldError('featuredContent');
    }
  };

  const renderContentGrid = () => {
    const creatorContentLimit = landingPageData.featuredContent?.settings?.noOfContentForCreator || 0;
    const reservedSlotsForCreators = landingPageData.featuredContent?.settings?.allowCreatorToUpload
      ? creatorContentLimit
      : 0;

    return (
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 w-full">
        {savedContent.map((content, index) => (
          <div
            key={index}
            className="relative aspect-[3/5] rounded-lg overflow-hidden border border-neutralgrey-3 bg-gray-900"
          >
            {content.type === FeaturedContentTypeEnum.IMAGE ? (
              content.src.includes('drive.google.com') ? (
                (() => {
                  const fileId = content.src.match(/\/d\/([a-zA-Z0-9_-]+)/)?.[1];
                  const googleDriveUrl = `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
                  return (
                    <img
                      src={googleDriveUrl}
                      alt="Featured content"
                      className="w-full h-full object-cover"
                    />
                  );
                })()
              ) : content.src.includes('instagram.com') && (content.src.includes('/p/') || content.src.includes('/post/')) ? (
                <div className="w-full h-full flex items-center justify-center overflow-hidden">
                  <InstagramEmbed
                    url={content.src}
                    width="100%"
                    height="100%"
                    captioned={false}
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </div>
              ) : (
                <img src={content.src} alt="Featured content" className="w-full h-full object-cover" />
              )
            ) : (
              <div className="w-full h-full flex items-center justify-center overflow-hidden">
                {(() => {
                  const contentType = detectContentType(content.src);
                  const embedProps = {
                    url: content.src,
                    width: '100%',
                    height: '100%',
                    style: {
                      transformOrigin: 'center center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                    },
                  };

                  switch (contentType) {
                    case ContentTypeEnum.INSTAGRAM:
                      return (
                        <div className="flex items-center justify-center w-full h-full">
                          <InstagramEmbed {...embedProps} captioned={false} />
                        </div>
                      );
                    case ContentTypeEnum.YOUTUBE:
                      return (
                        <div className="flex items-center justify-center w-full h-full">
                          <YouTubeEmbed {...embedProps} />
                        </div>
                      );
                    case ContentTypeEnum.TIKTOK:
                      return (
                        <div className="flex items-center justify-center w-full h-full">
                          <TikTokEmbed {...embedProps} />
                        </div>
                      );
                    case ContentTypeEnum.FACEBOOK:
                      return (
                        <div className="flex items-center justify-center w-full h-full">
                          <FacebookEmbed {...embedProps} />
                        </div>
                      );
                    case ContentTypeEnum.PINTEREST:
                      return (
                        <div className="flex items-center justify-center w-full h-full">
                          <PinterestEmbed {...embedProps} />
                        </div>
                      );
                    case ContentTypeEnum.GOOGLE_DRIVE:
                      return (
                        <div className="flex items-center justify-center w-full h-full">
                          <iframe
                            src={content.src.replace('/view', '/preview')}
                            className="w-full h-full"
                            style={{
                              transformOrigin: 'center center',
                            }}
                            allow="autoplay"
                          />
                        </div>
                      );
                    default:
                      return (
                        <div className="flex items-center justify-center w-full h-full">
                          <img
                            src={content.src}
                            className="w-full h-full object-contain"
                            style={{
                              transformOrigin: 'center center',
                            }}
                          />
                        </div>
                      );
                  }
                })()}
              </div>
            )}

            {/* Overlay controls */}
            <div className="absolute top-2 right-2 flex gap-2">
              <button
                className="px-1 rounded-full bg-black/50 hover:bg-black/70"
                onClick={() => handleDeleteContent(index)}
              >
                <X className="h-3 w-3 text-white" />
              </button>
            </div>
          </div>
        ))}

        {/* Empty slots */}
        {Array.from({ length: remainingSlots }).map((_, index) => {
          const isDisabled = index >= (remainingSlots - reservedSlotsForCreators);

          return (
            <button
              key={`empty-${index}`}
              className={`aspect-[3/5] rounded-lg border-2 border-dashed border-neutralgrey-3 flex flex-col items-center justify-center gap-2 transition-colors ${
                isDisabled
                  ? 'opacity-50 cursor-not-allowed bg-neutralgrey-1'
                  : 'hover:bg-neutralgrey-1 cursor-pointer'
              }`}
              onClick={() => !isDisabled && setIsDialogOpen(true)}
              disabled={isDisabled}
            >
              <Plus className="h-4 w-4 text-neutralgrey-4" />
              <span className="text-sm text-neutralgrey-4">
                {isDisabled ? 'Reserved for Creators' : 'Add Content'}
              </span>
            </button>
          );
        })}
      </div>
    );
  };

  const alertText = {
    description:
      'Content uploaded below will apply to all creators. For more details, refer to the ',
    link: 'instructions',
  };

  return (
    <div className="flex flex-col items-start gap-4 px-4 rounded-xl">
      <div className="flex gap-2 w-full">
        <span className="text-sm font-medium">Enable Featured Content</span>
        <Switch checked={isContentEnabled} onCheckedChange={handleContentEnabled} />
      </div>

      {isContentEnabled && (
        <>
          {fieldErrors?.featuredContent && savedContent.length === 0 && (
            <Alert variant="destructive">
              <AlertDescription>
                You are required to upload at least one content when featured content is enabled
              </AlertDescription>
            </Alert>
          )}

          {showAlert && (
            <Alert variant="default" className="bg-[#f9f9fa] border-neutralgrey-3">
              <div className="flex items-center gap-2">
                <Info className="h-4 w-4" />
                <AlertDescription className="flex-1 text-neutralgrey-6 text-[14px] leading-6 font-medium">
                  {alertText.description}
                  {' '}
                  <span className="underline">{alertText.link}</span>
                </AlertDescription>
                <X
                  className="h-3 w-3 text-neutralgrey-6 cursor-pointer stroke-[3]"
                  onClick={() => setShowAlert(false)}
                />
              </div>
            </Alert>
          )}

          {savedContent.length > 0 ? (
            <>
              {renderContentGrid()}
              <p className="text-sm text-neutralgrey-5">
                Note: You have uploaded
                {' '}
                {savedContent.length}
                {' '}
                out of
                {' '}
                {maxContent}
                {' '}
                pieces of content. Only
                {' '}
                {remainingSlots}
                {' '}
                additional pieces can be featured for creators.
              </p>
            </>
          ) : (
            <Card className="w-full border-dashed border-[#cbd1d7] bg-neutralgrey-1">
              <CardContent className="flex flex-col items-center justify-center gap-4 py-6">
                <div className="w-10 h-10 bg-[#ffffff99] rounded-lg flex items-center justify-center">
                  <ImageIcon className="h-12 w-12 text-[#cbd1d7]" />
                </div>
                <p className="w-[80%] text-center text-neutralgrey-5 text-[14px] leading-6">
                  We recommend adding at least one piece of content to ensure visibility if creators have no featured
                  content.
                </p>
                <Button variant="primary" className="text-white gap-2 h-8" onClick={() => setIsDialogOpen(true)}>
                  <Plus className="h-3 w-3" />
                  {' '}
                  Add Content
                  <ChevronDown className="h-3 w-3" />
                </Button>
              </CardContent>
            </Card>
          )}

          <ContentSelectionDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
        </>
      )}
    </div>
  );
};

export default FeaturedContentProduct;
