import React from 'react';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { useGetOfferByIdQuery } from '@affiliates/hooks';
import { useParams } from 'react-router-dom';
import { LeftFrame } from './components/LeftFrame';
import { LandingPageProvider } from './context/LandingPageContext';
import APIActions from './api/apiActions';
import RightFrame from './components/RightFrame';

interface IParams {
  offerId: string;
}

const LandingPageSetup = () => {
  const { offerId } = useParams<IParams>();

  const offerQuery = useGetOfferByIdQuery({
    variables: {
      id: Number(offerId),
    },
    skip: !offerId,
  });

  function onBackButtonClick(): void {
    // Navigate back to the previous page
    window.history.back();
  }
  return (
    <LandingPageProvider>
      <AppHeader
        title="Setup CreatorStore"
        backButtonFunction={onBackButtonClick}
        subTitle={offerQuery?.data?.offer?.name}
        actions={[
          <APIActions key="api-actions" offerId={offerId} />,
        ]}
      />
      <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
        <div className="w-full lg:w-2/5 p-4">
          <LeftFrame />
        </div>

        <div className="w-full lg:w-3/5 p-4">
          {/* Right Frame */}
          <RightFrame offerId={offerId} />
        </div>
      </div>
    </LandingPageProvider>
  );
};

export default LandingPageSetup;
