import * as React from 'react';
import { CalendarIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import { formatDisplayDate } from './utils';
import type { DateRangeFormData } from './date-range-form.types';

interface DateRangeFormCalendarTriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dateRange?: DateRangeFormData['dateRange'];
  open?: boolean;
}

const DateRangeFormCalendarTrigger = React.forwardRef<HTMLButtonElement, DateRangeFormCalendarTriggerProps>(
  ({
 className, dateRange, open, ...props
}, ref) => (
  <button
    ref={ref}
    type="button"
    className={cn(
        'w-full flex items-center gap-2 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background mx-3 my-2',
        open && 'ring-2 ring-ring ring-offset-2',
        className,
      )}
    aria-label="Select custom date range"
    {...props}
  >
    <span className={cn('flex-1 text-left', !dateRange?.from && 'text-muted-foreground')}>
      {dateRange?.from && dateRange?.to
          ? `${formatDisplayDate(dateRange.from)} — ${formatDisplayDate(dateRange.to)}`
          : 'Start — End'}
    </span>
    <CalendarIcon className="h-4 w-4 opacity-50 flex-shrink-0" />
  </button>
  ),
);

DateRangeFormCalendarTrigger.displayName = 'DateRangeFormCalendarTrigger';

export { DateRangeFormCalendarTrigger };
