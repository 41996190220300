import gql from 'graphql-tag';

export const GET_LANDING_PAGE_DATA = gql`
  query GetOfferLandingPage($offerId: Int!) {
    getOfferLandingPage(offerId: $offerId) {
      id
    status
    landingPageUrl
    config {
      heroSectionType
      basic {
        title
        description
        creatorsTag {
          showCreatorTag
          title
        }
        featuredProductDiscount {
          addHeroFeatureProduct
          showProductRating
          showTag
        }
        discountOfferButton {
          title
        }
      }
      featureProduct {
        id
        title
        description
        imageUrl
        handle
        price
        reviewCount
        rating
        discountedPrice
        productUrl
      }
      featuredContent {
        isFeaturedContentEnabled
        urls {
          type
          src
        }
        settings {
          allowCreatorToUpload
          noOfContentForCreator
        }
      }
      products {
        isFeaturedProductEnable
        featuredProductHeading
        showProductRating
        showTag
        allProducts {
          products {
            id
            title
            description
            imageUrl
            price
            discountedPrice
            handle
            productUrl
            reviewCount
            rating
          }
          style {
            showProductRating
            showProductPrice
          }
        }
        setting {
          allowCreatorToAddProducts
          noOfProductsCreatorCanAdd
          creatorProductSelectionType
          sectionHeading
        }
      }
      style {
        buttons {
          outlineButton {
            backgroundColor
            cornerRadius
            labelSize
            labelColor
          }
          solidButton {
            backgroundColor
            cornerRadius
            labelSize
            labelColor
          }
        }
        backgroundSetting {
          heroSectionBackgroundColor
          pageBackgroundColor
        }
        productCard {
          textAlignment
          cornerRadius
        }
        typography {
          body {
            fontColor
            fontSize
            fontStyle
          }
          headings {
            fontColor
            fontSize
            fontStyle
          }
        }
      }
      offerDiscount
      discountType
      currency
    }
  }
  }
`;
