import React, { Suspense } from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { GetSocialAnalyticsQuery } from '@frontend/gql/social/graphql';
import { GraphQLQueryVariables } from '../types';

const ReactJson = React.lazy(() => import('react-json-view'));

interface DebugViewProps {
  queryVariables: GraphQLQueryVariables;
  data: GetSocialAnalyticsQuery;
}

export const DebugView: React.FC<DebugViewProps> = ({ queryVariables, data }) => (
  <div className="mt-4 p-4 bg-gray-50 rounded-lg">
    <H3 className="mb-4">Debug View</H3>
    <Suspense fallback={<div>Loading...</div>}>
      <ReactJson
        src={queryVariables || {}}
        theme="monokai"
        collapsed={1}
        displayDataTypes={false}
        enableClipboard={false}
      />
      <ReactJson src={data || {}} theme="monokai" collapsed={1} displayDataTypes={false} enableClipboard={false} />
    </Suspense>
  </div>
);
