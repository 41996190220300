import {
 Row, Col, Switch, Typography, Input, Form, Tooltip, Button, Alert,
} from '@revfluence/fresh';
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { ExportOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
 Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from '@frontend/shadcn/components/ui/accordion';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import {
 FormAsyncAction, IShopifyPromoCodeFormValues, OfferFormAsyncActions, OfferFormModes,
} from '../../../types';
import styles from '../../../OfferForm.scss';
import { UtmFields } from '../../AffiliateLinkSection/FormElements/UtmFields';
import { UtmCustomFields } from '../../AffiliateLinkSection/FormElements/UtmCustomFields';

// import { UtmCustomFields } from '../../AffiliateLinkSection/FormElements/UtmCustomFields';

const { Title, Text } = Typography;
interface IProps {
  formValues: IShopifyPromoCodeFormValues;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  shopifyStoreName: string;
  redirectError?: boolean;
  setRedirectError?: (value: boolean) => void;
  secureCodes?: boolean;
  hasMembers?: boolean;
  isOfferPromoLink?: boolean;
  mode?: OfferFormModes;
  isUtmSupport?: boolean;
  isLandingPageEnabled?: boolean;
}
export const LinksForPromos: React.FC<Readonly<IProps>> = ({
  formValues: values,
  handleFormAsyncActions,
  shopifyStoreName,
  setRedirectError,
  secureCodes,
  mode,
  isOfferPromoLink,
  hasMembers,
  isUtmSupport,
  isLandingPageEnabled,
}) => {
  const history = useHistory();
  const { shopifyLandingPage } = useClientFeatures();
  const [isRedirectUrlVisible, setIsRedirectUrlVisible] = useState(!isEmpty(values.shopifyRedirectUrl));

  const handleSwitch = () => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isPromoLink', value: !values.isPromoLink },
    });
  };

  const handleLandingPageSwitch = () => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isLandingPageEnabled', value: !values.isLandingPageEnabled },
    });
};

  const handleUtmFieldChange = (key: string, value: string) => {
    // For standard UTM fields, use the UpdateFieldAsyncActionPayload
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: {
        key,
        value,
      },
    });
  };

  useEffect(() => {
    if (!values.shopifyRedirectUrl || isEmpty(values.shopifyRedirectUrl)) {
      setRedirectError(false);
    }
  }, [values, setRedirectError]);

  // const isProjectNameExistsInUtm = useCallback(() => {
  //   const keysToCheck = ['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm'];
  //   return keysToCheck.some((key) => values[key] === '{project_name}');
  // }, [values])();

  const handleSecureCodes = useCallback(
    (checked: boolean) => {
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: 'isSecureCodes', value: checked },
      });
    },
    [handleFormAsyncActions],
  );

  const handleRedirectUrl = useCallback(
    (event) => {
      try {
        const url = new URL(event.target.value);
        if (url.hostname !== shopifyStoreName) {
          setRedirectError(true);
        } else {
          setRedirectError(false);
        }
      } catch {
        setRedirectError(true);
      }
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: 'shopifyRedirectUrl', value: event.target.value },
      });
    },
    [shopifyStoreName, setRedirectError, handleFormAsyncActions],
  );

  // Generate UTM events that match the expected type
  const generateUtmEvents = (key: string) => ({
    onBlur: () => {},
    onFocus: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onChange: (value: string) => handleUtmFieldChange(key, value),
  });

  const handleLandingPageNavigation = () => {
    const location = history.location;
    const newPathname = location.pathname.replace('/edit', '/setupLandingPage');
    history.push({ ...location, pathname: newPathname });
  };

  const tooltipSecureCode = (
    <div className="bg-gray-800 p-4 rounded-lg shadow-lg max-w-md text-white">
      <span>
        Secure Codes are single-use affiliate promo codes generated in real-time. Install the
        {' '}
        <a
          href="https://apps.shopify.com/aspire-2-0"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-300 hover:text-blue-200 underline transition-colors duration-200"
        >
          Aspire Secure Codes app
        </a>
        {' '}
        in Shopify before enabling them.
        {' '}
        <a
          href="https://help.aspireiq.com/en/articles/10352213-beta-secure-codes-overview"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-300 hover:text-blue-200 underline transition-colors duration-200"
        >
          Learn more
        </a>
      </span>
    </div>
  );

  const tooltipLandingPage = (
    <div className="bg-gray-800 p-4 rounded-lg shadow-lg max-w-md text-white">
      <span>
        Aspire CreatorStores is a co-branded micro shopping experience for your offers, promoting creator's favorite products and content. Install the
        {' '}
        <a
          href="https://apps.shopify.com/aspire-2-0"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-300 hover:text-blue-200 underline transition-colors duration-200"
        >
          Aspire CreatorStores app
        </a>
        {' '}
        in Shopify to use these.
        {' '}
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-300 hover:text-blue-200 underline transition-colors duration-200"
        >
          Learn more
        </a>
      </span>
    </div>
  );

  const toggleRedirectUrl = () => {
    setIsRedirectUrlVisible(!isRedirectUrlVisible);
  };

  return (
    <>
      <div>
        <Row gutter={8}>
          <Col>
            {/* change logic for disable again to hasMembers instead of edit. */}
            <Switch size="default" checked={values.isPromoLink} onChange={handleSwitch} disabled={isOfferPromoLink} />
          </Col>
          <Col>
            <Title level={5}>Generate shareable links for promo codes</Title>
          </Col>
        </Row>
      </div>
      <div>
        <Text className={`${styles.descTextColor} mb-2`}>
          Enabling this will generate unique links for each promo code assigned to each member, allowing them to use
          these links to promote your offer more effectively.
        </Text>
      </div>
      {values.isPromoLink && (
        <Col style={{ padding: 0, paddingTop: '8px' }}>
          <Button type="link" onClick={toggleRedirectUrl} className="text-sm">
            {isRedirectUrlVisible ? '- Hide redirect URL' : '+ Enter redirect URL'}
          </Button>

          {isRedirectUrlVisible && (
            <>
              <Row style={{ marginBottom: '5px' }}>
                Enter the redirect URL
                {' '}
                <Text style={{ color: 'grey' }}> (optional)</Text>
                {' '}
              </Row>
              <Row style={{ paddingLeft: 0, marginBottom: '5px' }}>
                <Input
                  size="large"
                  placeholder="Enter the redirect URL for links"
                  value={values.shopifyRedirectUrl}
                  onChange={handleRedirectUrl}
                />
              </Row>
              <Row style={{ marginBottom: '5px' }}>
                <Text style={{ color: 'grey' }}>By default, user will be redirected to your website homepage.</Text>
              </Row>
              {shopifyLandingPage && (
                <Row style={{ marginBottom: '16px' }}>
                  <Alert
                    message="Note: Redirect URL will not work in case of enabling CreatorStore"
                    type="warning"
                    showIcon
                  />
                </Row>
              )}
            </>
          )}
          {shopifyLandingPage && (
            <>
              <Row className="pt-4 pb-2">
                <Col>
                  <Switch
                    size="default"
                    checked={values.isLandingPageEnabled}
                    onChange={handleLandingPageSwitch}
                    disabled={values.isMultipleShopifySyncEnabled || (hasMembers && isLandingPageEnabled)}
                  />
                </Col>
                <Col>
                  <Text strong className="px-1">
                    Enable CreatorStore for Creators
                  </Text>
                </Col>
                <Col>
                  <Tooltip
                    title={tooltipLandingPage}
                    overlayInnerStyle={{
                      padding: 0,
                      backgroundColor: 'transparent',
                    }}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
              {values.isMultipleShopifySyncEnabled && (
                <Row className="mt-2">
                  <Alert
                    message="CreatorStore is not available with Multiple Shopify"
                    description="We currently don't support CreatorStore when multiple Shopify stores are synced. This feature will be available soon."
                    type="info"
                    showIcon
                  />
                </Row>
              )}

              <Row align="middle" gutter={8} style={{ marginTop: 8 }} className="pb-2">
                <Col>
                  <Tooltip
                    title={
                      mode === OfferFormModes.Create
                        ? 'Save your offer first to continue setup of CreatorStore'
                        : values.isMultipleShopifySyncEnabled
                        ? 'CreatorStore is not available with Multiple Shopify Sync'
                        : ''
                    }
                  >
                    <Button
                      type="default"
                      size="middle"
                      icon={<ExportOutlined />}
                      disabled={!values.isLandingPageEnabled || values.isMultipleShopifySyncEnabled || !values.landingPageUrl}
                      onClick={handleLandingPageNavigation}
                    >
                      Setup CreatorStore
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </>
          )}
          {secureCodes && (
            <Row className="py-4">
              <Col>
                <Switch
                  size="default"
                  checked={values.isSecureCodes}
                  onChange={handleSecureCodes}
                  disabled={hasMembers}
                />
              </Col>
              <Col>
                <Text strong className="px-1">
                  Enable One Time Use Secure Codes Generation
                </Text>
              </Col>
              <Col>
                <Tooltip
                  title={tooltipSecureCode}
                  overlayInnerStyle={{
                    padding: 0,
                    backgroundColor: 'transparent',
                  }}
                >
                  <InfoCircleOutlined className="ml-2" />
                </Tooltip>
              </Col>
            </Row>
          )}
          {isUtmSupport && (
            <div>
              <Accordion
                type="single"
                collapsible
                onValueChange={(value) => {
                  // Toggle isAdvancedUrlEnabled when accordion is clicked
                  handleFormAsyncActions({
                    action: OfferFormAsyncActions.UPDATE_FIELD,
                    payload: {
                      key: 'isAdvancedUrlEnabled',
                      value: value === 'utm-settings',
                    },
                  });
                }}
                value={values.isAdvancedUrlEnabled ? 'utm-settings' : undefined}
              >
                <AccordionItem value="utm-settings">
                  <AccordionTrigger className="text-decoration-none py-2">
                    UTM Parameters Settings
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className={styles.urlwrapper}>
                      <UtmFields
                        name="advanceUrlSettingType"
                        label="UTM Source"
                        keyName="utmSource"
                        events={generateUtmEvents('utmSource')}
                      />
                      <UtmFields
                        name="advanceUrlSettingType"
                        label="UTM Medium"
                        keyName="utmMedium"
                        events={generateUtmEvents('utmMedium')}
                      />
                      <UtmFields
                        name="advanceUrlSettingType"
                        label="UTM Campaign"
                        keyName="utmCampaign"
                        events={generateUtmEvents('utmCampaign')}
                      />
                      <UtmFields
                        name="advanceUrlSettingType"
                        label="UTM Content"
                        keyName="utmContent"
                        events={generateUtmEvents('utmContent')}
                      />
                      <UtmFields
                        name="advanceUrlSettingType"
                        label="UTM Term"
                        keyName="utmTerm"
                        events={generateUtmEvents('utmTerm')}
                      />
                      <div className="ant-form-item-label">
                        <label>Custom Parameters</label>
                      </div>
                      <Form.List name="customUTMParameters">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name }, index) => (
                              <UtmCustomFields
                                name="advanceUrlSettingType"
                                key={key}
                                customName={name}
                                disabled={false}
                                events={generateUtmEvents('utmTerm')}
                                index={index}
                                remove={remove}
                              />
                              ))}
                            {fields.length < 3 ? (
                              <Form.Item name="button">
                                <Button
                                  size="large"
                                  onClick={() => add({ key: '', value: '' })}
                                  disabled={values.isSecureCodes}
                                >
                                  <PlusOutlined />
                                  Add Parameter
                                </Button>
                              </Form.Item>
                              ) : (
                                <div className={styles.customText}>You can add up to 3 custom parameters only</div>
                              )}
                          </>
                          )}
                      </Form.List>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          )}
        </Col>
      )}
    </>
  );
};
