import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { UsersIcon } from '@revfluence/fresh-icons/regular/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
} from '../../../components/highlight-cards';

export const membersCardFragment = graphql(`
  fragment MembersCard_TotalInsights on TotalInsights {
    activeMembers
  }
`);

interface MembersCardProps {
  data: FragmentType<typeof membersCardFragment> | null | undefined;
}

const MembersCard: React.FC<MembersCardProps> = ({ data }) => {
  const totalInsights = useFragment(membersCardFragment, data);
  const value = totalInsights?.activeMembers ?? 0;

  return (
    <Card>
      <HighlightCardHeader className="bg-blue-6">
        <HighlightCardTitle>
          <UsersIcon className="mr-2" />
          <span>Members</span>
        </HighlightCardTitle>
      </HighlightCardHeader>
      <HighlightCardContent>
        <HighlightCardContentRow>
          <HighlightCardContentColumn>
            <HighlightCardStatRow>
              <HighlightCardStat className="text-3xl leading-none">{formatNumberDefault(value)}</HighlightCardStat>
            </HighlightCardStatRow>
            <HighlightCardStatLabel>Total Active Members</HighlightCardStatLabel>
          </HighlightCardContentColumn>
        </HighlightCardContentRow>
      </HighlightCardContent>
    </Card>
  );
};

export default MembersCard;
