import { FilterDrawer } from '@frontend/app/containers/ProductFulfillmentDashboard/FilterDrawer';
import { CommunitySwitcherProvider, useCommunitySwitcherContext } from '@frontend/app/hooks';
import { useMemberFieldsWithSources } from '@frontend/app/hooks/memberList';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import {
 Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { PlusIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import {
 chain, isArray, isEqual, isString, map, upperFirst,
} from 'lodash';
import React, {
 useEffect, useMemo, useRef, useState,
} from 'react';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { FieldType } from '@frontend/app/types/Fields';
import { Operator } from '@frontend/app/types/MemberSearch';
import { v4 as uuidv4 } from 'uuid';
import { SpecialFilters } from '@frontend/app/constants/specialFilters';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import { FieldSource, IFilter } from '../../FilterList';
import {
 AnnualOperatorOptions, ArrayOperatorOptions, BooleanOperatorOptions, CommunitiesStatusOperatorOptions, DateOperatorOptions, DynamicOperatorOptions, ImagesOperatorOptions, IOperator, NumberOperatorOptions, ProgramsStatusOperatorOptions, ProgramStatus, SpecialOperatorOptions, TextOperatorOptions,
} from '../../FilterList/FilterFormPopover';
import {
 IImagesEqualHandles, INumberBetweenHandles, INumberEqualHandles, ITextEqualHandles,
} from '../../FilterList/fieldTypes';
import {
 ActivationInclusion, AnnualBetween, AnnualEqual, AnnualRelative, ArrayInclusion, BooleanEqual, CommunityInclusion, DateBetween, DateEqual, DaysRelative, HighlightInclusion, NumberBetween, NumberEqual, OwnerInclusion, ProgramInclusion, TagInclusion, TextEqual,
} from '.';
import { useMembersTableFiltersContext } from './MembersTableFiltersDrawerContext';
import { useFilterItemRenderValue } from '../../FilterList/useFilterItemRenderValue';

interface OperatorValuesProps {
  filterId: string;
  selectedOperator: Operator;
  selectedField: IField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
}
const OperatorValues = ({
 filterId, selectedOperator, selectedField, defaultValue,
}: OperatorValuesProps) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const { filters, setValue } = useMembersTableFiltersContext();

  const value = filters.find((filter) => filter.id === filterId)?.value || defaultValue;

  /**
   * Focus on input after selecting an operator
   */
  const textEqualRef = useRef<ITextEqualHandles>();
  const emailEqualRef = useRef<ITextEqualHandles>();
  const numberEqualRef = useRef<INumberEqualHandles>();
  const numberBetweenRef = useRef<INumberBetweenHandles>();
  const imagesEqualRef = useRef<IImagesEqualHandles>();

  useEffect(() => {
    if (textEqualRef.current) {
      textEqualRef.current.focus();
    } else if (numberEqualRef.current) {
      numberEqualRef.current.focus();
    } else if (numberBetweenRef.current) {
      numberBetweenRef.current.focus();
    } else if (imagesEqualRef.current) {
      imagesEqualRef.current.focus();
    } else if (emailEqualRef.current) {
      emailEqualRef.current.focus();
    }
  }, [selectedOperator]);

  const isPercentage = useMemo(() => selectedField?.type === FieldType.PERCENTAGE, [selectedField?.type]);

  const showTextEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS)
    && (
      selectedField?.type === FieldType.TEXT
    )
    && !isArray(selectedField?.choices);

  const showEmailEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS)
    && selectedField?.type === FieldType.EMAIL
    && !isArray(selectedField?.choices);

  const showArrayEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS
  ) && isArray(selectedField?.choices) && (selectedField.type !== FieldType.DYNAMIC_SELECT);

  const showDynamicArray = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS
  ) && isArray(selectedField?.choices) && (selectedField.type === FieldType.DYNAMIC_SELECT);

  const showNumberEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.GREATER_THAN
    || selectedOperator === Operator.LESS_THAN
  ) && (selectedField?.type === FieldType.NUMBER || selectedField?.type === FieldType.CURRENCY || isPercentage);

  const showNumberBetween = (
    selectedOperator === Operator.BETWEEN
    && (selectedField?.type === FieldType.NUMBER || selectedField?.type === FieldType.CURRENCY || isPercentage)
  );

  const showBooleanEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
  ) && selectedField?.type === FieldType.BOOLEAN;

  const showImagesEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS
  ) && selectedField?.type === FieldType.IMAGES;

  const showAnnualEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.GREATER_THAN
    || selectedOperator === Operator.LESS_THAN
  ) && selectedField?.type === FieldType.ANNUAL;

  const showDateEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.GREATER_THAN
    || selectedOperator === Operator.LESS_THAN
  ) && selectedField?.type === FieldType.DATE;

  const showInTheLastNextMore = (
    selectedOperator === Operator.IN_THE_LAST
    || selectedOperator === Operator.IN_THE_NEXT
    || selectedOperator === Operator.MORE_THAN_AGO
  ) && selectedField?.type === FieldType.DATE;

  const showAnnualInTheLastNextMore = (
    selectedOperator === Operator.IN_THE_LAST
    || selectedOperator === Operator.IN_THE_NEXT
    || selectedOperator === Operator.MORE_THAN_AGO
  ) && selectedField?.type === FieldType.ANNUAL;

  const showDateBetween = (
    selectedOperator === Operator.BETWEEN
    && selectedField?.type === FieldType.DATE
  );

  const showAnnualBetween = (
    selectedOperator === Operator.BETWEEN
    && selectedField?.type === FieldType.ANNUAL
  );

  const showHasValue = (
    selectedOperator === Operator.NOT_NULL
  );

  const showHasNoValue = (
    selectedOperator === Operator.IS_NULL
  );

  const showProgramInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.PROGRAM
  );

  const showCommunityInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.COMMUNITY
  );

  const showActivationInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.ACTIVATION
  );

  const showTagInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.TAG
  );

  const showOwnerInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.OWNERS
  );

  const showHighlightInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.HIGHLIGHT
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValueChange = (value: any) => {
    setValue(filterId, value);
  };

  if (showTextEqual) {
    return (
      <TextEqual
        value={value}
        onChange={handleValueChange}
        ref={textEqualRef}
      />
    );
  }

  if (showEmailEqual) {
    return (
      <TextEqual
        value={value}
        onChange={handleValueChange}
        ref={emailEqualRef}
      />
    );
  }

  if (showArrayEqual || showDynamicArray) {
    return (
      <ArrayInclusion
        field={selectedField}
        onChange={handleValueChange}
        defaultValue={value}
        isDynamic={showDynamicArray}
      />
    );
  }

  if (showImagesEqual) {
    return (
      <TextEqual
        value={value}
        onChange={handleValueChange}
        ref={imagesEqualRef}
      />
    );
  }

  if (showBooleanEqual) {
    return (
      <BooleanEqual
        value={value}
        onChange={handleValueChange}
      />
    );
  }

  if (showHasValue || showHasNoValue) {
    return null;
  }

  if (showNumberEqual) {
    return (
      <NumberEqual
        value={value}
        isPercentage={isPercentage}
        onChange={handleValueChange}
        ref={numberEqualRef}
      />
    );
  }

  if (showNumberBetween) {
    return (
      <NumberBetween
        value={value}
        isPercentage={isPercentage}
        onChange={handleValueChange}
        ref={numberBetweenRef}
      />
    );
  }
  if (showDateEqual) {
    return (
      <DateEqual
        value={value}
        onChange={handleValueChange}
      />
    );
  }

  if (showAnnualEqual) {
    return (
      <AnnualEqual
        value={value}
        onChange={handleValueChange}
      />
    );
  }

  if (showInTheLastNextMore) {
    return (
      <DaysRelative
        value={value}
        onChange={handleValueChange}
      />
    );
  }

  if (showDateBetween) {
    return (
      <DateBetween
        value={value}
        onChange={handleValueChange}
      />
    );
  }

  if (showAnnualInTheLastNextMore) {
    return (
      <AnnualRelative
        value={value}
        onChange={handleValueChange}
      />
    );
  }

  if (showAnnualBetween) {
    return (
      <AnnualBetween
        value={value}
        onChange={handleValueChange}
      />
    );
  }

  if (showProgramInclusion) {
    return (
      <ProgramInclusion
        onChange={handleValueChange}
        defaultValue={value}
        visible
      />
    );
  }

  if (showCommunityInclusion) {
    return (
      <CommunityInclusion
        onChange={handleValueChange}
        defaultValue={value}
      />
    );
  }

  if (showActivationInclusion) {
    return (
      <ActivationInclusion
        onChange={handleValueChange}
        defaultValue={value}
      />
    );
  }

  if (showTagInclusion) {
    return (
      <TagInclusion
        onChange={handleValueChange}
        defaultValue={value}
        visible
      />
    );
  }

  if (showOwnerInclusion) {
    return (
      <OwnerInclusion
        onChange={handleValueChange}
        defaultValue={value}
        visible
      />
    );
  }

  if (showHighlightInclusion) {
    return (
      <HighlightInclusion
        onChange={handleValueChange}
        defaultValue={value}
      />
    );
  }

  return <></>;
};

interface OperatorsProps {
  filterId: string;
  selectedField: IField;
}
const Operators = ({
  filterId,
  selectedField,
}: OperatorsProps) => {
  const { filters, setOperator: setSelectedOperator } = useMembersTableFiltersContext();

  const selectedOperator = filters.find((filter) => filter.id === filterId)?.operator;

  const operatorOptions: IOperator[] = useMemo(() => {
    switch (selectedField?.type) {
      case FieldType.TEXT:
      case FieldType.EMAIL:
        return TextOperatorOptions;

      case FieldType.ARRAY:
        return ArrayOperatorOptions;

      case FieldType.BOOLEAN:
        return BooleanOperatorOptions;

      case FieldType.PERCENTAGE:
      case FieldType.NUMBER:
      case FieldType.CURRENCY:
        return NumberOperatorOptions;

      case FieldType.DATE:
        return DateOperatorOptions;

      case FieldType.ANNUAL:
        return AnnualOperatorOptions;

      case FieldType.IMAGES:
        return ImagesOperatorOptions;

      case FieldType.COMMUNITY:
        return CommunitiesStatusOperatorOptions;

      case FieldType.TALENT_AGENTS:
        return TextOperatorOptions;

      case FieldType.DYNAMIC_SELECT:
        return DynamicOperatorOptions;

      case FieldType.ACTIVATION:
      case FieldType.TAG:
      case FieldType.PROGRAM:
      case FieldType.OWNERS:
      case FieldType.HIGHLIGHT:
        const { field } = selectedField;
        switch (field) {
          case ProgramStatus.INVITED:
          case ProgramStatus.SUBMITTED:
          case ProgramStatus.REJECTED:
            return ProgramsStatusOperatorOptions;
          default:
            return SpecialOperatorOptions;
        }
      default:
        return [];
    }
  }, [selectedField]);

  return (
    <>
      <Select value={selectedOperator?.value} onValueChange={(value) => setSelectedOperator(filterId, operatorOptions.find((op) => op.value === value))}>
        <SelectTrigger className="mx-3 w-auto">
          <SelectValue placeholder="Select Operator" />
        </SelectTrigger>
        <SelectContent className="z-[99999]">
          {
          operatorOptions.map((operator) => (
            <SelectItem key={operator.value} value={operator.value}>
              {operator.label}
            </SelectItem>
          ))
        }
        </SelectContent>
      </Select>
      {selectedField && <div className="mx-3"><OperatorValues filterId={filterId} selectedOperator={selectedOperator?.value} selectedField={selectedField} /></div>}
    </>
  );
};

interface FilterProps {
  filterId: string;
}
const Filter = ({ filterId }: FilterProps) => {
  const {
 filters, setSource, setField, setValue, setIsEditing, removeFilter, setIsSelected,
} = useMembersTableFiltersContext();
  const source = filters.find((filter) => filter.id === filterId)?.source;
  const field = filters.find((filter) => filter.id === filterId)?.field;
  const operator = filters.find((filter) => filter.id === filterId)?.operator;
  const value = filters.find((filter) => filter.id === filterId)?.value;

  const canAddFilter = !!(field && operator && ([Operator.IS_NULL, Operator.NOT_NULL].includes(operator.value) || value));

  const {
    selectedCommunityId,
  } = useCommunitySwitcherContext();

  const { fields: fieldsWithSource, appsWithFields } = useMemberFieldsWithSources(selectedCommunityId);

  const fields = useMemo(() => (
    chain(fieldsWithSource)
      .reject({ showInFilters: false })
      .filter((field) => !field.metaData?.superColumnId)
      .sortBy((field) => field.headerName.toLocaleLowerCase())
      .value()
  ), [fieldsWithSource]);

  const items = useMemo(() => (
    chain(fields)
      .map((col) => ({
        label: upperFirst(col.headerName),
        value: col,
        source: {
          label: isString(col.source) ? col.source : col.source?.name,
          value: isString(col.source) ? col.source : col.source?.id,
        },
      }))
      .value()
  ), [fields]);

  const additionalSources = useMemo(() => map(appsWithFields, (app) => ({
    label: app.name,
    value: app.id,
  })), [appsWithFields]);

  const sources = useMemo(() => [
    ...map(FieldSource, (s) => ({
      label: s,
      value: s === FieldSource.All ? null : s,
      isSelectAll: true,
    })),
    ...additionalSources,
  ], [additionalSources]);

  const dataSource = useMemo(() => (
    chain(items)
      // Filter by source (if available) but keep the "Select All" item (if available)
      .filter((item) => (
        source?.value
          ? item.source?.value === source.value
          : true // no source, no filter
      ))
      .value()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [items, source]);

  const handleSourceChange = (value: string) => {
    setSource(filterId, sources.find((source) => source.value === value));
  };

  const handleFieldChange = (value: string) => {
    setField(filterId, dataSource.find((item) => item.value.field === value)?.value);
  };

  const handleAddFilter = () => {
    const isPercentage = field?.type === FieldType.PERCENTAGE;
    if (isPercentage) {
      if (operator.value !== Operator.IS_NULL && operator.value !== Operator.NOT_NULL) {
        setValue(
          filterId,
          isArray(value)
            ? map(value, (v) => v / 100)
            : value / 100,
        );
      }
    }
    setIsEditing(filterId, false);
    setIsSelected(filterId, true);
  };

  const handleRemoveFilter = () => {
    removeFilter(filterId);
  };

  return (
    <Card className="rounded-lg">
      <CardContent className="p-0 pt-3 pb-3 flex flex-col gap-2">
        <Select value={source?.value} onValueChange={handleSourceChange}>
          <SelectTrigger className="mx-3 w-auto">
            <SelectValue placeholder="Select Source" />
          </SelectTrigger>
          <SelectContent className="z-[99999]">
            {
                sources.map((source) => (
                  <SelectItem key={source.value} value={source.value}>
                    {source.label}
                  </SelectItem>
                ))
              }
          </SelectContent>
        </Select>
        <Separator />
        <Select value={field?.field} onValueChange={handleFieldChange}>
          <SelectTrigger className="mx-3 w-auto">
            <SelectValue placeholder="Select Field" />
          </SelectTrigger>
          <SelectContent className="z-[99999]">
            {!dataSource.length && (
              <SelectItem disabled value="no-fields">
                No fields available
              </SelectItem>
            )}
            {
                dataSource.map((item) => (
                  <SelectItem key={item.value.field} value={item.value.field}>
                    {item.label}
                  </SelectItem>
                ))
              }
          </SelectContent>
        </Select>
        {field && <Operators filterId={filterId} selectedField={field} />}
        <div className="mx-3 flex items-center gap-2 justify-end">
          <Button variant="outline" size="xs" onClick={handleRemoveFilter}>
            Cancel
          </Button>
          <Button size="xs" disabled={!canAddFilter} onClick={handleAddFilter}>
            Add
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const AddedFilter = ({ filterId }: FilterProps) => {
  const { filters, removeFilter, setIsSelected } = useMembersTableFiltersContext();

  const filter = filters.find((f) => f.id === filterId);

  const {
    selectedCommunityId,
  } = useCommunitySwitcherContext();

  const { fields: fieldsWithSource } = useMemberFieldsWithSources(selectedCommunityId);

  const fields = useMemo(() => (
    chain(fieldsWithSource)
      .reject({ showInFilters: false })
      .sortBy((field) => field.headerName.toLocaleLowerCase())
      .value()
  ), [fieldsWithSource]);

  const memberFilter: IFilter = {
    id: filter.id,
    [filter.operator.value]: filter.value,
  };
  const memberFieldSchemaId = filter.field?.schemaId;
  if (memberFieldSchemaId) {
    memberFilter.memberFieldSchemaId = memberFieldSchemaId;
  } else if (filter.field?.type !== FieldType.TALENT_AGENTS) {
    memberFilter.column = filter.field?.field;
  } else {
    memberFilter.relation = SpecialFilters.TALENT_AGENTS;
  }

  const filterText = useFilterItemRenderValue(memberFilter, fields);

  const handleRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    removeFilter(filterId);
  };

  const handleSelectFilter = () => {
    setIsSelected(filterId, !filter.isSelected);
  };

  return (
    <Button
      variant="outline"
      className={filter.isSelected
        ? 'flex justify-between h-auto text-left whitespace-pre-wrap border-primary'
        : 'flex justify-between h-auto text-left whitespace-pre-wrap'}
      onClick={handleSelectFilter}
    >
      <span>{filterText}</span>
      <Button size="sm" variant="ghost" onClick={handleRemoveFilter}>
        <XmarkIcon />
      </Button>
    </Button>
  );
};

const FilterContent = () => {
  const {
 filters, logicalOperator, addFilter, setLogicalOperator,
} = useMembersTableFiltersContext();

  const isEditing = filters.some((filter) => filter.isEditing);

  const handleAddFilter = () => {
    addFilter({
      id: uuidv4(),
      source: null,
      field: null,
      operator: null,
      value: null,
      isEditing: true,
    });
  };

  return (
    <div className="flex flex-col pt-4 px-6 pb-6 gap-3">
      <div className="flex justify-between items-center">
        <span>
          Join filters using
        </span>
        <ToggleGroup
          className="border border-gray-200 bg-gray-200 rounded-lg overflow-hidden inline-flex"
          type="single"
          value={logicalOperator}
          onValueChange={setLogicalOperator}
          size="sm"
        >
          <ToggleGroupItem
            value={Operator.AND}
            className="h-6 text-xs bg-gray-200 text-black data-[state=on]:bg-white data-[state=on]:text-black data-[state=off]:text-gray-400"
          >
            And
          </ToggleGroupItem>
          <ToggleGroupItem
            value={Operator.OR}
            className="h-6 text-xs bg-gray-200 text-black data-[state=on]:bg-white data-[state=on]:text-black data-[state=off]:text-gray-400"
          >
            Or
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      <Separator />
      <div className="flex flex-col gap-4">
        {filters.map((filter) => (
          filter.isEditing
            ? <Filter key={filter.id} filterId={filter.id} />
            : <AddedFilter key={filter.id} filterId={filter.id} />
      ))}
        {!isEditing && (
          <Button variant="outline" size="xs" className="w-full" onClick={handleAddFilter}>
            <PlusIcon />
            <span>Add Filter</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export const MembersTableFiltersDrawer = () => {
  const {
 filters, isDrawerOpen, logicalOperator, setIsDrawerOpen, clearAllFilters,
} = useMembersTableFiltersContext();

  const { updateFilters, updateLogicalOperator } = useMemberListContext();

  const [previousFilters, setPreviousFilters] = useState<string[]>([]);

  const handleApplyFilters = () => {
    const transformedFilters = filters.filter((filter) => filter.isSelected)
    .map((filter) => {
      const memberFilter: IFilter = {
        id: filter.id,
        [filter.operator.value]: filter.value,
      };
      const memberFieldSchemaId = filter.field?.schemaId;
      if (memberFieldSchemaId) {
        memberFilter.memberFieldSchemaId = memberFieldSchemaId;
      } else if (filter.field?.type !== FieldType.TALENT_AGENTS) {
        memberFilter.column = filter.field?.field;
      } else {
        memberFilter.relation = SpecialFilters.TALENT_AGENTS;
      }
      return memberFilter;
    });
    updateLogicalOperator(logicalOperator);
    updateFilters(transformedFilters);
    setIsDrawerOpen(false);
    setPreviousFilters(transformedFilters.map((filter) => filter.id));
  };

  const handleClearAll = () => {
    updateFilters([]);
    clearAllFilters();
    setPreviousFilters([]);
    setIsDrawerOpen(false);
  };

  const hasSelectionChanged = !isEqual(
    filters.filter((filter) => filter.isSelected).map((filter) => filter.id).slice().sort(),
    previousFilters.slice().sort(),
  );

  return (
    <CommunitySwitcherProvider useQueryParams={false}>
      <div className="shadcn">
        <FilterDrawer
          open={isDrawerOpen}
          onOpenChange={setIsDrawerOpen}
          headerProps={{
              title: 'Filters',
            }}
          footerButtons={[
              {
                name: 'Apply Filters',
                onClick: handleApplyFilters,
                variant: 'primary',
                disabled: !hasSelectionChanged,
              },
              {
                name: 'Clear All',
                onClick: handleClearAll,
                variant: 'outline',
                disabled: !previousFilters.length,
              },
            ]}
        >
          <FilterContent />
        </FilterDrawer>
      </div>
    </CommunitySwitcherProvider>
  );
};
