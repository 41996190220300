import * as React from 'react';
import {
  map, forEach, isEmpty, sortBy, toLower,
  find,
  first,
  isUndefined,
  trim,
  cloneDeep,
} from 'lodash';

import {
  Button, Row, Space, Typography,
} from '@revfluence/fresh';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { MemberFieldSchemasBySectionsQuery_sections_memberData } from '@frontend/app/queries/types/MemberFieldSchemasBySectionsQuery';

import { SelectOffers } from '@frontend/app/containers/Projects/ProjectsPage/SelectOffer/SelectOffer';
import { useState } from 'react';

import {
 getMemberStatus, getStatusForAffiliateLinkMember, getStatusForPromoCodeMember, useApolloClient,
} from '@frontend/applications/AffiliatesApp/hooks';
import { GET_OFFER_BY_ID_QUERY } from '@frontend/applications/AffiliatesApp/queries';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { logger } from '@common';
import { useClientFeatureEnabled, useGetProfile } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { DeeplinkDrawer } from '@frontend/applications/AffiliatesApp/components/DeeplinkDrawer/DeeplinkDrawer';
import {
 IAffiliateLinkMember, IPromoCodeMember, MemberStatus, OfferMemberStatus, TMemberTableRow, TOfferMember,
} from '@frontend/applications/AffiliatesApp/components/MemberTable/types';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { abbreviateAffiliateLink } from '@frontend/applications/AffiliatesApp/utils';
import { ApolloProvider } from '@apollo/client';
import { OfferDetailsContextProvider } from '@frontend/context/OfferDetailsContext';
import { composePromoCodeStatus } from '@frontend/applications/AffiliatesApp/components/MemberTable/components/composePromoCodeStatus';
import { composeLinkStatus } from '@frontend/applications/AffiliatesApp/components/MemberTable/components/composeLinkStatus';
import { Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FieldRow } from '../FieldRow';
import styles from './SalesTracking.scss';

const { useMemo, useEffect } = React;
const { Text } = Typography;

type MemberFieldSchema = MemberFieldSchemasBySectionsQuery_sections_memberData;

interface Props {
  member?: MemberInput;
  schemas?: MemberFieldSchema[];
  viewMore?: boolean;
  onViewMoreVisibilityChange?: (visible: boolean) => void;
  memberId?: number;
}

interface SalesTrackingOffer {
  name: string | null;
  promoCode?: string | null;
  link?: string | null;
  activeDates?: string | null;
  memberPageUrl: string | null;
  isSecureCodes?: boolean;
}

const SalesTracking: React.FunctionComponent<Props> = ({
  member,
  schemas,
  viewMore,
  onViewMoreVisibilityChange,
  memberId,
}) => {
  const [showSelectOffer, setShowSelectOffer] = useState(false);
  const manageDeepLinksAllowed = useClientFeatureEnabled(ClientFeature.IS_MANAGE_DEEPLINKS_ALLOWED);
  const staApolloClient = useApolloClient();
  const { profile } = useGetProfile();
  const [offersDetail, setOffersDetail] = useState<GetOfferById_offer[]>([]);
  const [isDeeplinkDrawerVisibile, setDeeplinkDrawerVisible] = useState(false);
  const [currentOffer, setCurrentOffer] = useState<GetOfferById_offer>(null);
  const migrateToGraphQL = useClientFeatureEnabled(ClientFeature.MIGRATE_TO_GRAPHQL);
  const [affiliateOfferMember, setAffiliateOfferMember] = useState<TOfferMember>(null);
  const [offersToShow, setOffersToShow] = useState<(SalesTrackingOffer & { id: string })[]>([]);
  const offers = useMemo(() => {
    if (!member || !schemas || !member.fields[schemas[0].id]) {
      return [];
    }

    try {
      const data = JSON.parse(member.fields[schemas[0].id]) as Record<string, SalesTrackingOffer>;
      const results: Array<SalesTrackingOffer & { id: string }> = [];
      forEach(data, (value, key) => {
        results.push({
          id: key,
          ...value,
        });
      });
      return sortBy(results, (item) => toLower(item.name));
    } catch {
      return [];
    }
  }, [member, schemas]);

  useEffect(() => {
    if (offersDetail.length && offers.length) {
      const offersTemp = cloneDeep(offers);
      map(offersTemp, (result) => {
        const offer = find(offersDetail, (offerDetail) => offerDetail.id === parseInt(result.id, 10));
        if (offer) {
          result.isSecureCodes = !!offer.promos[0]?.isSecureCodes;
        }
      });
      setOffersToShow(offersTemp);
    }
  }, [offersDetail, offers]);
  useEffect(() => {
    if (currentOffer) {
      //
      let offerMember;
      if (currentOffer.promos.length) {
        const promo = first(currentOffer.promos);
        const promoMember = find(promo.affiliates, (member) => member.affiliate.memberId === memberId);
        const memberStat = find(promo.affiliateStats, (member) => member.aspirexMemberId === memberId);
        offerMember = {
          affiliateId: promoMember.affiliateId,
          affiliateOfferId: promoMember.id,
          avgSale: memberStat.avgSale,
          code: memberStat.affiliateCode,
          payoutId: promoMember.offerPayoutId,
          codeEndDate: promoMember.endDate,
          codeStartDate: promoMember.startDate,
          conversions: memberStat.conversions,
          email: memberStat.affiliateEmail,
          imageUrl: memberStat.affiliateImageUrl,
          memberId: memberStat.aspirexMemberId,
          name: memberStat.affiliateName,
          payoutEarned: memberStat.payoutEarned,
          payoutMade: memberStat.payoutMade,
          sales: memberStat.sales,
          salesBase: memberStat.salesBase,
          avgSaleBase: memberStat.avgSaleBase,
          payoutEarnedBase: memberStat.payoutEarnedBase,
          currencies: promo.stats.baseCurrencies,
          source: OFFER_SOURCE.SHOPIFY,
          status: promoMember ? getStatusForPromoCodeMember(promoMember) : OfferMemberStatus.UNKNOWN,
          memberStatus: promoMember ? getMemberStatus(promoMember.affiliate.status) : MemberStatus.ACTIVE, // Default to ACTIVE
          promoCodeStatus: promoMember.status,
          externalDiscountCodeGid: promoMember.externalDiscountCodeGId,
          providerMetadata: promoMember.providerMetadata,
        };
      }
      if (currentOffer.links.length) {
        const link = first(currentOffer.links);
        const linkMember = find(link.affiliates, (affiliate) => affiliate.affiliate.memberId === memberId);
        const memberStat = find(link.affiliateStats, (affiliate) => affiliate.aspirexMemberId === memberId);
        if (!isUndefined(linkMember) && linkMember.deletedDate === null) {
          offerMember = {
            affiliateId: linkMember.affiliateId,
            affiliateOfferId: linkMember.id,
            memberAffiliateOfferId: memberStat.affiliateOfferId,
            affiliateLink: memberStat.affiliateLink,
            affiliateShortLink: memberStat.affiliateShortLink,
            avgSale: memberStat.avgSale,
            payoutId: linkMember.offerPayoutId,
            clicks: memberStat.clicks,
            conversions: memberStat.conversions,
            email: memberStat.affiliateEmail,
            imageUrl: memberStat.affiliateImageUrl,
            linkCreationDate: memberStat.linkCreationDate,
            memberId: memberStat.aspirexMemberId,
            name: memberStat.affiliateName,
            payoutEarned: memberStat.payoutEarned,
            payoutMade: memberStat.payoutMade,
            sales: memberStat.sales,
            source: OFFER_SOURCE.TUNE,
            status: getStatusForAffiliateLinkMember(memberStat),
            memberStatus: linkMember ? getMemberStatus(linkMember.affiliate.status) : MemberStatus.ACTIVE, // Default to ACTIVE
            linkStatusError: linkMember.updateLog,
            additionalDeepLinks: linkMember.affiliateDeeplinks,
            defaultDeeplink: linkMember.deeplinkUrl,
          };
        }
      }
      setAffiliateOfferMember(offerMember);
    }
  }, [currentOffer, manageDeepLinksAllowed, memberId]);
  const memberData = useMemo(() => {
    if (affiliateOfferMember) {
      const linkStatus = affiliateOfferMember?.status;
      const commonFields: TMemberTableRow = {
        _sortableFields: {
          affiliateName: trim(affiliateOfferMember.name).toLowerCase(),
        },
        key: affiliateOfferMember.memberId.toLocaleString(),
        affiliateName: {
          name: affiliateOfferMember.name,
          imageUrl: affiliateOfferMember.imageUrl,
          memberId: affiliateOfferMember.memberId,
          disabled: affiliateOfferMember.memberStatus === MemberStatus.DELETED,
          disabledReason:
            affiliateOfferMember.memberStatus === MemberStatus.DELETED
              ? 'This member has been deleted in the Members app'
              : null,
        },
        payoutId: affiliateOfferMember.payoutId,
        avgSale: affiliateOfferMember.avgSale,
        conversions: affiliateOfferMember.conversions,
        email: affiliateOfferMember.email,
        endDate: null,
        id: String(affiliateOfferMember.memberId),
        linkStatus,
        payoutEarned: affiliateOfferMember.payoutEarned,
        payoutMade: affiliateOfferMember.payoutMade,
        sales: affiliateOfferMember.sales,
        startDate: null,
        disableSelection: affiliateOfferMember.memberStatus === MemberStatus.DELETED,
        disabledReason:
          affiliateOfferMember.memberStatus === MemberStatus.DELETED
            ? 'This member has been deleted in the Members app'
            : null,
        name: affiliateOfferMember.name,
        affiliateOffer: {
          id: affiliateOfferMember.affiliateOfferId,
          affiliateId: affiliateOfferMember.affiliateId,
        },
      };
      if (currentOffer.promos.length) {
        const promoMember = affiliateOfferMember as IPromoCodeMember;
        return {
          ...commonFields,
          externalCode: promoMember.code,
          selectedAffliate: {
            affiliateOfferId: promoMember.affiliateOfferId,
            memberAffiliateOfferId: promoMember.affiliateOfferId,
            name: promoMember.name,
            source: promoMember.source,
            imageUrl: promoMember.imageUrl,
            isMultipleShopifyOffer: !!currentOffer.promos[0]?.connectedClientMetadata?.length,
            status: composePromoCodeStatus(
              promoMember.status,
              promoMember.codeStartDate,
              promoMember.codeEndDate,
              currentOffer.promos[0],
              currentOffer.isNewFlow,
              currentOffer.archivedDate,
            ),
            payout: promoMember?.payoutId ? currentOffer.payouts.find((p) => p.id === promoMember.payoutId) : null,
            code: promoMember.code,
            affliateLevelStats: {
              conversions: promoMember.conversions,
              sales: promoMember.sales,
              salesBase: promoMember.salesBase,
              avgSale: promoMember.avgSale,
              avgSaleBase: promoMember.avgSaleBase,
              payoutEarned: promoMember.payoutEarned,
              payoutEarnedBase: promoMember.payoutEarnedBase,
              payoutMade: promoMember.payoutMade,
              payOutDue: promoMember.payoutEarned - promoMember.payoutMade,
              clicks: null,
            },
            externalDiscountCodeGid: promoMember.externalDiscountCodeGid,
            providerMetadata: promoMember.providerMetadata,
          },
          endDate: promoMember.codeEndDate,
          startDate: promoMember.codeStartDate,
          linkStatus: composePromoCodeStatus(
            promoMember.status,
            promoMember.codeStartDate,
            promoMember.codeEndDate,
            currentOffer.promos[0],
            currentOffer.isNewFlow,
            currentOffer.archivedDate,
          ),
          linkCreationDate: null,
        };
      }
      if (currentOffer.links.length) {
        const linkMember = affiliateOfferMember as IAffiliateLinkMember;
        return {
          ...commonFields,
          affiliateLink: linkMember.affiliateLink,
          affiliateShortLink: linkMember.affiliateShortLink,
          affiliateLinkAbbrev: {
            shortAffiliateLinkAbbrev:
              linkMember.affiliateShortLink || abbreviateAffiliateLink(linkMember.affiliateLink),
            shortLink: linkMember.affiliateShortLink,
            defaultDeeplink: linkMember.defaultDeeplink,
          },
          clicks: linkMember.clicks,
          linkStatus: composeLinkStatus(currentOffer, linkMember.status, linkMember.deletedDate),
          linkStatusError: linkMember.linkStatusError,
          linkCreationDate: linkMember.linkCreationDate,
          additionalDeepLinks: linkMember.additionalDeepLinks,
          selectedAffliate: {
            affiliateOfferId: linkMember.affiliateOfferId,
            memberAffiliateOfferId: linkMember.memberAffiliateOfferId,
            name: linkMember.name,
            source: linkMember.source,
            imageUrl: linkMember.imageUrl,
            isMultipleShopifyOffer: !!currentOffer.promos[0]?.connectedClientMetadata?.length,
            status: linkMember.status,
            payout: linkMember?.payoutId ? currentOffer.payouts.find((p) => p.id === linkMember.payoutId) : null,
            uniqueLink: linkMember.affiliateShortLink,
            affliateLevelStats: {
              conversions: linkMember.conversions,
              sales: linkMember.sales,
              avgSale: linkMember.avgSale,
              payoutEarned: linkMember.payoutEarned,
              payoutMade: linkMember.payoutMade,
              payOutDue: linkMember.payoutEarned - linkMember.payoutMade,
              clicks: linkMember.clicks,
            },
          },
        };
      }
    }
    return null;
  }, [currentOffer, affiliateOfferMember]);

  const visibleOffers = useMemo(
    () => (viewMore ? offersToShow : offersToShow.slice(0, 1)),
    [offersToShow, viewMore],
  );
  useEffect(() => {
    onViewMoreVisibilityChange(offers.length > 1);

    const fetchOffersDetails = async () => {
      try {
        const offerDetailPromises = await Promise.all(offers.map(async (offer) => {
          const { data } = await staApolloClient.query({
            query: GET_OFFER_BY_ID_QUERY,
            variables: {
              id: Number(offer.id),
            },
          });
          return data.offer;
        }));

        setOffersDetail((prevOffersDetail) => [
          ...(prevOffersDetail || []),
          ...offerDetailPromises,
        ]);
      } catch (error) {
        logger.error(error.message);
      }
    };
    fetchOffersDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers]);
  if (!member) {
    return null;
  }
  const closeSelectedOffer = () => {
    setShowSelectOffer(false);
  };

  const secureCodeTag = () => (
    <Row style={{ display: 'flex' }}>
      <Text strong>Promo codes will be generated in real time</Text>
      <Tooltip title="This will generate secure codes that can only be used once.">
        <InfoCircleOutlined style={{ marginLeft: '8px', marginTop: '2px' }} />
      </Tooltip>
    </Row>
  );

    const secureCodeOfferTag = (offerName: string) => (
      <Row style={{ display: 'flex', gap: '4px' }}>
        <Text strong>{offerName}</Text>
        <Tag color="yellow">
          Secure Code
        </Tag>
      </Row>
      );
  // set deeplink offer data and member
  return (
    <>
      {migrateToGraphQL && !isEmpty(offers) && (
        <Button style={{ width: '100%', marginBottom: '8px' }} onClick={() => setShowSelectOffer(true)}>
          Manage Offer
        </Button>
      )}
      <Space direction="vertical" className="grid grid-cols-1 divide-y">
        {map(visibleOffers, (offer) => (
          <Space key={offer.id} className={styles.offer} direction="vertical">
            {!offer.isSecureCodes && <FieldRow label="Offer Name" value={offer.name} />}
            {offer.isSecureCodes && <FieldRow label="Offer Name" renderValue={() => secureCodeOfferTag(offer.name)} />}
            {offer.isSecureCodes && <FieldRow label="Promo Code" renderValue={secureCodeTag} />}
            {offer.promoCode && !offer.isSecureCodes && <FieldRow label="Promo Code" value={offer.promoCode} copyable />}
            {offer.link && (
              <>
                <FieldRow label="Link" value={offer.link} copyable />
                {manageDeepLinksAllowed && !offer.promoCode && (
                  <FieldRow
                    label="Show Additional Links"
                    type="LINK"
                    renderLabel={(label) => (
                      <>
                        <Button
                          style={{ padding: 0 }}
                          type="link"
                          onClick={() => {
                            setCurrentOffer(
                              find(offersDetail, (offerDetail) => offerDetail.id === parseInt(offer.id, 10)),
                            );
                            setDeeplinkDrawerVisible(true);
                          }}
                          ghost
                        >
                          {label}
                        </Button>
                      </>
                    )}
                    copyable
                  />
                )}
              </>
            )}
            {offer.promoCode && <FieldRow label="Active Dates" value={offer.activeDates} />}
          </Space>
        ))}
        {isEmpty(offers) && (
          <div className={styles.noOffers}>
            <Text type="secondary">No sales tracking offers.</Text>
          </div>
        )}

        {!isEmpty(offersDetail) && (
          <SelectOffers
            offers={offersDetail}
            openSelectedOffer={showSelectOffer}
            closeSelectedOffer={closeSelectedOffer}
            selectedMemberIds={[memberId]}
            setIsVisibleSelectOffer={setShowSelectOffer}
          />
        )}
        {manageDeepLinksAllowed && memberData && currentOffer && (
          <ApolloProvider client={staApolloClient}>
            <OfferDetailsContextProvider profile={profile}>
              <DeeplinkDrawer
                offer={currentOffer}
                memberData={memberData}
                setDeeplinkDrawerVisible={setDeeplinkDrawerVisible}
                isDeeplinkDrawerVisibile={isDeeplinkDrawerVisibile}
              />
            </OfferDetailsContextProvider>
          </ApolloProvider>
        )}
      </Space>
    </>
  );
};

export default SalesTracking;
