import { TrashIcon } from '@revfluence/fresh-icons/solid/esm';
import React, { useState, useEffect, ReactNode } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

export interface SubListItem<T = {}> {
  id: string;
  content: ReactNode;
  isFixed?: boolean;
  isRemovable?: boolean;
  data: T;
}

export interface ListItem<T = {}> {
  id: string;
  content: ReactNode;
  isFixed?: boolean;
  isRemovable?: boolean;
  subItems?: SubListItem<T>[];
  data: T;
}

interface DraggableListProps<T = {}> {
  initialItems: ListItem<T>[];
  onItemsChange?: (items: ListItem<T>[]) => void;
  onItemRemoved?: (item: ListItem<T> | SubListItem<T>) => void;
}

const DraggableList: React.FC<DraggableListProps> = ({ initialItems, onItemsChange, onItemRemoved }) => {
  const [items, setItems] = useState<ListItem[]>(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const onDragEnd = (result: DropResult) => {
    const {
      source,
      destination,
      type,
      draggableId,
    } = result;
    if (!destination) return;

    const draggedItem = type === 'item'
        ? items.find((item) => item.id === draggableId)
        : items.flatMap((item) => item.subItems || []).find((subItem) => subItem.id === draggableId);

    if (draggedItem?.isFixed) return;

    const updatedItems = [...items];

    if (type === 'item') {
      const destinationItem = items[destination.index];
      if (destinationItem?.isFixed) return;

      const [removed] = updatedItems.splice(source.index, 1);
      updatedItems.splice(destination.index, 0, removed);
      setItems(updatedItems);
      onItemsChange?.(updatedItems);
      return;
    }

    const sourceItemIndex = updatedItems.findIndex((item) => item.id === source.droppableId);
    const destItemIndex = updatedItems.findIndex((item) => item.id === destination.droppableId);

    if (sourceItemIndex === -1 || destItemIndex === -1) return;

    const sourceItem = updatedItems[sourceItemIndex];
    const destItem = updatedItems[destItemIndex];

    if (!sourceItem.subItems || !destItem.subItems) return;

    const destinationSubItem = destItem.subItems[destination.index];
    if (destinationSubItem?.isFixed) return;

    const sourceSubItems = [...sourceItem.subItems];
    const destSubItems = sourceItemIndex === destItemIndex ? sourceSubItems : [...destItem.subItems];

    const [movedItem] = sourceSubItems.splice(source.index, 1);
    destSubItems.splice(destination.index, 0, movedItem);

    updatedItems[sourceItemIndex] = {
      ...sourceItem,
      subItems: sourceSubItems,
    };

    if (sourceItemIndex !== destItemIndex) {
      updatedItems[destItemIndex] = {
        ...destItem,
        subItems: destSubItems,
      };
    }

    setItems(updatedItems);
    onItemsChange?.(updatedItems);
  };

  const removeItem = (id: string) => {
    const removedItem = items.find((item) => item.id === id);
    if (!removedItem) return;

    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
    onItemsChange?.(updatedItems);
    onItemRemoved?.(removedItem);
  };

  const removeSubItem = (itemId: string, subItemId: string) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        const removedSubItem = item.subItems?.find((sub) => sub.id === subItemId);
        item.subItems = item.subItems?.filter((sub) => sub.id !== subItemId);

        if (removedSubItem) {
          onItemRemoved?.(removedSubItem);
        }
      }
      return item;
    });
    setItems(updatedItems);
    onItemsChange?.(updatedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="items" type="item" direction="vertical">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="space-y-2"
            style={{ overflow: 'visible' }}
          >
            {items.map((item, index) => (
              <div key={item.id} className="space-y-2">
                {item.isFixed ? (
                  <div className="group bg-grey-1 px-3 py-2 rounded opacity-75 relative">
                    <div>
                      {item.content}
                    </div>
                    {item.isRemovable && (
                      <TrashIcon
                        onClick={() => removeItem(item.id)}
                        className="absolute top-2 right-2 text-gray-600 hover:text-red-700 hidden group-hover:block cursor-pointer"
                      />
                    )}
                  </div>
                ) : (
                  <Draggable draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`group bg-grey-1 px-3 py-2 rounded cursor-move relative ${
                          snapshot.isDragging ? 'opacity-50' : ''
                        }`}
                        style={{
                          ...provided.draggableProps.style,
                          overflow: 'visible',
                        }}
                      >
                        <div>
                          {item.content}
                        </div>
                        {item.isRemovable && (
                          <TrashIcon
                            onClick={() => removeItem(item.id)}
                            className="absolute top-2 right-2 text-gray-600 hover:text-red-700 hidden group-hover:block cursor-pointer"
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                )}

                {item.subItems && (
                  <Droppable droppableId={item.id} type={`subItems-${item.id}`} direction="vertical">
                    {(subProvided, _) => (
                      <div
                        ref={subProvided.innerRef}
                        {...subProvided.droppableProps}
                        className="ml-6 space-y-2"
                        style={{ overflow: 'visible' }}
                      >
                        {item.subItems.map((sub, subIndex) => (
                          <div key={sub.id} className="space-y-2">
                            {sub.isFixed ? (
                              <div className="group bg-grey-1 px-3 py-2 rounded opacity-75 relative">
                                <div>
                                  {sub.content}
                                </div>
                                {sub.isRemovable && (
                                  <TrashIcon
                                    onClick={() => removeSubItem(item.id, sub.id)}
                                    className="absolute top-2 right-2 text-gray-600 hover:text-red-700 hidden group-hover:block cursor-pointer"
                                  />
                                )}
                              </div>
                            ) : (
                              <Draggable draggableId={sub.id} index={subIndex}>
                                {(subDragProvided, subDragSnapshot) => (
                                  <div
                                    ref={subDragProvided.innerRef}
                                    {...subDragProvided.draggableProps}
                                    {...subDragProvided.dragHandleProps}
                                    className={`group bg-grey-1 px-3 py-2 rounded cursor-move relative ${
                                      subDragSnapshot.isDragging ? 'shadow-lg opacity-50' : ''
                                    }`}
                                    style={{
                                      ...subDragProvided.draggableProps.style,
                                      overflow: 'visible',
                                    }}
                                  >
                                    <div>
                                      {sub.content}
                                    </div>
                                    {sub.isRemovable && (
                                      <TrashIcon
                                        onClick={() => removeSubItem(item.id, sub.id)}
                                        className="absolute top-2 right-2 text-gray-600 hover:text-red-700 hidden group-hover:block cursor-pointer"
                                      />
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            )}
                          </div>
                        ))}
                        {subProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
