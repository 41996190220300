import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { HandsClappingIcon } from '@revfluence/fresh-icons/regular/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
} from '../../../components/highlight-cards';

export const engagementCardFragment = graphql(`
  fragment EngagementCard_TotalInsights on TotalInsights {
    engagements
  }
`);

interface EngagementCardProps {
  data: FragmentType<typeof engagementCardFragment> | null | undefined;
}

const EngagementCard: React.FC<EngagementCardProps> = ({ data }) => {
  const totalInsights = useFragment(engagementCardFragment, data);
  const value = totalInsights?.engagements ?? 0;

  return (
    <Card>
      <HighlightCardHeader className="bg-orange-6">
        <HighlightCardTitle>
          <HandsClappingIcon className="mr-2" />
          <span>Engagement</span>
        </HighlightCardTitle>
      </HighlightCardHeader>
      <HighlightCardContent>
        <HighlightCardContentRow>
          <HighlightCardContentColumn>
            <HighlightCardStatRow>
              <HighlightCardStat className="text-3xl leading-none">{formatNumberDefault(value)}</HighlightCardStat>
            </HighlightCardStatRow>
            <HighlightCardStatLabel>Total Engagement</HighlightCardStatLabel>
          </HighlightCardContentColumn>
        </HighlightCardContentRow>
      </HighlightCardContent>
    </Card>
  );
};

export default EngagementCard;
