import React, {
    CSSProperties,
    useState,
    useEffect,
    useCallback,
    useMemo,
  } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
  PaginationOptions,
  ColumnPinningState,
  Column,
} from '@tanstack/react-table';

import ReactDragListView from 'react-drag-listview';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@frontend/shadcn/components/ui/table';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DownLeftAndUpRightToCenterIcon,
  UpRightAndDownLeftFromCenterIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';

const getColumnPinningStyles = <T extends {} = {}>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px hsl(var(--border)) inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px hsl(var(--border)) inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    zIndex: isPinned ? 3 : 2,
    width: column.getSize(),
  };
};

const getCellPinningStyles = <T extends {} = {}>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px hsl(var(--border)) inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px hsl(var(--border)) inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    backgroundColor: isPinned ? 'hsl(var(--primary-foreground))' : undefined,
  };
};

export interface HeaderGroupBorder {
  borderColor?: string;
}

export interface ColumnMetaType {
  headerGroupBorder?: HeaderGroupBorder;
  onToggleClick?: (column: string) => void;
  isColumnExpanded?: boolean;
  greyBackground?: boolean;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  sortable?: boolean;
  paginated?: boolean;
  paginationState?: PaginationState;
  paginationOptions?: PaginationOptions;
  wrapperClassName?: string;
  columnPinning?: ColumnPinningState;
  draggable?: boolean;
  selectable?: boolean;
  rowPinning?: boolean;
  bordered?: boolean;
  onColumnDragEnd?: (fromIndex: number, toIndex: number) => void;
  onSelectionChange?: (selectedRows: TData[]) => void;
  clearSelection?: boolean;
}

export default function DataTable<TData, TValue>({
  columns: initialColumns,
  data,
  paginated,
  paginationState,
  paginationOptions,
  wrapperClassName,
  sortable = true,
  columnPinning: initialColumnPinning,
  draggable = false,
  selectable = false,
  rowPinning = false,
  bordered = false,
  onColumnDragEnd,
  onSelectionChange,
  clearSelection,
}: DataTableProps<TData, TValue>) {
  const [columns, setColumns] = useState(initialColumns);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);
  useEffect(() => {
    if (clearSelection) {
      setRowSelection({});
    }
  }, [clearSelection]);
  const handleColumnDragEnd = useCallback(
    (fromIndex: number, toIndex: number) => {
      const newColumns = [...columns];
      const draggedColumn = newColumns.splice(fromIndex, 1)[0];
      newColumns.splice(toIndex, 0, draggedColumn);
      setColumns(newColumns);

      if (onColumnDragEnd) {
        onColumnDragEnd(fromIndex, toIndex);
      }
    },
    [columns, onColumnDragEnd],
  );

  const columnPinning = useMemo(() => initialColumnPinning || {}, [initialColumnPinning]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: sortable && getSortedRowModel(),
    getPaginationRowModel: paginated && getPaginationRowModel(),
    state: {
      rowSelection,
      columnPinning,
    },
    onRowSelectionChange: setRowSelection,
    ...paginationState,
    ...paginationOptions,
  });
  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);
  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().rows.map((row) => row.original);
    onSelectionChange?.(selectedRows);
  }, [rowSelection, onSelectionChange, table]);

  const tableContent = (
    <Table style={{ minWidth: table.getTotalSize() }} className="font-mono tabular-nums">
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {selectable && (
              <TableHead
                className={cn('w-[40px]', bordered ? 'border border-gray-200' : '')}
              >
                <Checkbox
                  checked={table.getIsAllRowsSelected()}
                  aria-label="Select all"
                  onCheckedChange={(checked) => {
                      table.toggleAllRowsSelected(!!checked);
                    }}
                />
              </TableHead>
            )}
            {headerGroup.headers.map((header) => {
              const isGroupedHeader = header.subHeaders?.length > 0;
              const meta = header.column.columnDef.meta as ColumnMetaType | undefined;

              return (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                  width: isGroupedHeader ? undefined : header.getSize(),
                  ...getColumnPinningStyles(header.column),
                  position: rowPinning ? 'sticky' : undefined,
                  top: rowPinning ? 0 : undefined,
                  zIndex: 10, // Keep the header above rows
                  borderBottom: `2px solid ${meta?.headerGroupBorder?.borderColor ?? 'transparent'}`,
                }}
                  className={cn(
                  sortable && header.column.getCanSort() ? 'cursor-pointer select-none pr-6' : '',
                  bordered ? 'border border-gray-200' : '',
                  meta?.greyBackground && 'bg-[#F9FAFB]',
                )}
                  onClick={sortable && !isGroupedHeader && header.column.getToggleSortingHandler()}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-2">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {!isGroupedHeader && header.column.getIsSorted() && (
                      <div className="h-4 w-4">
                        {{
                          asc: <ChevronUpIcon />,
                          desc: <ChevronDownIcon />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                    </div>

                    {meta?.onToggleClick && (
                    <div
                      className="cursor-pointer transition-transform duration-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        meta.onToggleClick?.(header.id);
                      }}
                      title={meta?.isColumnExpanded ? 'Collapse columns' : 'Expand columns'}
                    >
                      {meta?.isColumnExpanded ? (
                        <DownLeftAndUpRightToCenterIcon className="text-sm text-grey-5 mt-2" />
                      ) : (
                        <UpRightAndDownLeftFromCenterIcon className="text-sm text-grey-5 mt-2" />
                      )}
                    </div>
                  )}
                  </div>
                </TableHead>

              );
            })}
          </TableRow>
        ))}
      </TableHeader>

      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
              {selectable && (
                <TableCell className={cn(bordered ? 'border border-gray-200' : '')}>
                  <Checkbox
                    checked={row.getIsSelected()}
                    aria-label="Select row"
                    onCheckedChange={row.getToggleSelectedHandler()}
                  />
                </TableCell>
              )}
              {row.getVisibleCells().map((cell) => {
                // @ts-ignore
                // eslint-disable-next-line
                const isGreyBackground = cell.column.columnDef.meta?.greyBackground;

                return (
                  <TableCell
                    key={cell.id}
                    style={getCellPinningStyles(cell.column)}
                    className={cn(
                      bordered ? 'border border-gray-200 p-2' : 'p-2',
                      isGreyBackground && 'bg-[#F9FAFB]',
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  if (draggable) {
    return (
      <ReactDragListView.DragColumn nodeSelector="th" onDragEnd={handleColumnDragEnd} lineClassName="drag-line">
        {tableContent}
      </ReactDragListView.DragColumn>
    );
  }

  return (
    <div className={wrapperClassName}>
      {tableContent}
    </div>
  );
}
