import * as React from 'react';

import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled, useGetClientFeatureByName } from '@frontend/app/hooks';
import Text from '@frontend/app/refresh-components/Text';
import { H1 } from '@frontend/shadcn/components/typography/h1';
import { P } from '@frontend/shadcn/components/typography/p';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { SwitchProps } from '@radix-ui/react-switch';
import { useUpdateClientFF } from '@frontend/app/hooks/useUpdateClientFF';
import { ClientFFState } from '@frontend/app/types/globalTypes';
import { message } from 'antd';
import Spinner from '@frontend/app/refresh-components/Spinner';
import { cn } from '@frontend/shadcn/lib/utils';
import { isUndefined } from 'lodash';
import { OverlaySpinner } from '@components';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Tag } from '@frontend/app/refresh-components';

export interface NewFeatureSettingsProps {
  clientId: string;
}

const newFeatureSVG = require('@frontend/app/assets/svgs/newFeature.svg');

const NewFeatureSettings: React.FC<NewFeatureSettingsProps> = ({ clientId }) => {
  const { data: refreshUiFF, loading: fetchingRefreshUiFF } = useGetClientFeatureByName(ClientFeature.REFRESH_UI);

  const isRefreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI, { fetchPolicy: 'no-cache' });
  const [successToastVisible, setSuccessToastVisible] = React.useState(false);

  const [updateClientFF, { loading: saving }] = useUpdateClientFF();

  const [checked, setChecked] = React.useState<boolean>(false);
  const [loader, setLoader] = React.useState<boolean>(false);

  const loading = fetchingRefreshUiFF || saving || loader;

  React.useEffect(() => {
    setChecked(isRefreshUi);
  }, [isRefreshUi]);

  const handleToggle: SwitchProps['onCheckedChange'] = async (isChecked) => {
    setLoader(true);
    setChecked(isChecked);
    await updateClientFF({
      variables: {
        data: {
          id: clientId,
          featuresUpdate: [
            { id: refreshUiFF.clientFeature.id, state: isChecked ? ClientFFState.ADD : ClientFFState.REMOVE },
          ],
        },
      },
      onCompleted: () => {
        setSuccessToastVisible(true);
        setLoader(false);
        message.success('Setting updated successfully! Page will automatically reload now.', 2, () => {
          setSuccessToastVisible(false);
          window.location.reload();
        });
      },
      onError: (e) => {
        setLoader(false);
        message.error('Something went wrong!', 2);
        console.error('Failed to save refresh ui setting. Error: ', e.message);
        setChecked(isRefreshUi);
      },
    });
  };
  if (isUndefined(isRefreshUi)) return <OverlaySpinner />;

  // Adding shadcn class here as it must work even without FF
  return (
    <div className={cn('shadcn w-[70%] h-full font-inter relative', isRefreshUi ? 'bg-background' : '')}>
      {
        loader
        && <Spinner placement="global" />
      }
      <H1 className="text-lg font-medium">
        Explore New Features
        <img src={newFeatureSVG} className="ml-2" />
      </H1>
      <P className="text-primary">
        Explore the latest updates designed to improve the experience for your entire team. Activate features that everyone would like to try and share your valuable feedback with us. You can revert to the previous settings whenever you wish.
      </P>
      <Card className="p-2xl w-full flex flex-col gap-base mt-4 ">
        <div className="w-full flex justify-between items-center">
          <Label htmlFor="toggle-refresh-switch">
            <Text visualVariant="h4" className="font-semibold">Explore Our New Look </Text>
            <Tag label="Beta" bgColor="bg-green-3" className="ml-2" />
          </Label>
          <Switch id="toggle-refresh-switch" checked={checked} onCheckedChange={handleToggle} disabled={loading || successToastVisible} />
        </div>
        <div className="text-textColor-secondary">
          <P>Try our refreshed User Interface (Beta) and experience a more streamlined and refined interaction.</P>
        </div>
        <Button className="w-[140px] h-8 bg-primary-foreground" variant="outline" size="lg">Share Feedback</Button>
      </Card>
    </div>
  );
};

export default NewFeatureSettings;
