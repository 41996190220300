import React, { useState } from 'react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@frontend/shadcn/components/ui/dialog';
import { useMutation, useQuery } from '@apollo/client';
import { SAVE_LANDING_PAGE_DATA } from '@frontend/applications/AffiliatesApp/queries/saveLandingPageMutation';
import { GET_LANDING_PAGE_DATA } from '@frontend/applications/AffiliatesApp/queries/getLandingPageData';
import { message } from 'antd';
import { P } from '@frontend/shadcn/components/typography/p';
import { LaptopCodeIcon, MobileIcon, EyeIcon } from '@revfluence/fresh-icons/regular';
import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { Loader2 } from 'lucide-react';
import { validateLandingPageData } from '../../validations/validators';
import { useLandingPageContext } from '../../context/LandingPageContext';
import { LandingPagePreview } from './LandingPagePreview';
import { DEVICE_TYPE } from '../../utils/LandingPagePreviewUtils';
import { hasContentChanged } from '../../utils/contentChangeUtils';

interface RightFrameProps {
  offerId: string;
}

export default function RightFrame({ offerId }: RightFrameProps): JSX.Element {
  const {
 landingPageData, setFieldErrors, clearFieldErrors, initialData,
} = useLandingPageContext();
  const [createOfferLandingPage] = useMutation(SAVE_LANDING_PAGE_DATA);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<DEVICE_TYPE>(DEVICE_TYPE.DESKTOP);

  const { refetch: refetchLandingPage } = useQuery(GET_LANDING_PAGE_DATA, {
    variables: { offerId: parseInt(offerId, 10) },
    skip: true,
  });

  const handleViewClick = async () => {
    setIsChecking(true);
    try {
      const hasChanges = hasContentChanged(landingPageData, initialData);

      if (!hasChanges) {
        // If no changes, directly open the preview URL
        const { data: pageData } = await refetchLandingPage();
        if (pageData?.getOfferLandingPage?.landingPageUrl) {
          window.open(pageData.getOfferLandingPage.landingPageUrl, '_blank');
          return;
        }
      }

      // If there are changes, show the confirmation dialog
      setShowConfirmDialog(true);
    } finally {
      setIsChecking(false);
    }
  };

  const handleSaveAndPreview = async () => {
    clearFieldErrors();
    const validationErrors = validateLandingPageData(landingPageData);

    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      message.error('Please fix all highlighted errors before previewing');
      return;
    }

    setIsSaving(true);
    try {
      const variables = {
        config: {
          ...landingPageData,
        },
        status: 'ACTIVE',
        offerId: parseInt(offerId, 10),
      };

      const createResponse = await createOfferLandingPage({
        variables,
      });

      if (createResponse.data?.createOfferLandingPage?.id) {
        message.success('CreatorStore saved successfully!');

        const { data: landingPageData } = await refetchLandingPage();

        if (landingPageData?.getOfferLandingPage?.landingPageUrl) {
          const url = new URL(landingPageData.getOfferLandingPage.landingPageUrl);
          window.open(url.href, '_blank');
        } else {
          message.error('Preview URL not found');
        }
      } else {
        message.error('Failed to save CreatorStore');
      }
    } catch (error) {
      message.error('Failed to save CreatorStore');
    } finally {
      setIsSaving(false);
      setShowConfirmDialog(false);
    }
  };

  return (
    <>
      <Card className="border border-neutralgrey-2 rounded-xl bg-white">
        <CardContent className="p-0">
          <div className="flex items-center justify-between gap-4 p-6">
            <div className="flex flex-1 items-center gap-2">
              <P className="text-grey-5 font-semi-bold">Hero Section</P>
              <ToggleGroup
                type="single"
                className="flex gap-2"
                value={selectedDevice}
                onValueChange={(value) => value && setSelectedDevice(value as DEVICE_TYPE)}
              >
                <ToggleGroupItem
                  value={DEVICE_TYPE.DESKTOP}
                  className="h-10 min-w-10 flex items-center justify-center rounded-xl border border-grey-5 text-grey-5 data-[state=on]:bg-primary data-[state=on]:text-primary-foreground data-[state=on]:border-primary-foreground"
                >
                  <LaptopCodeIcon width={14} height={14} />
                </ToggleGroupItem>

                <ToggleGroupItem
                  value={DEVICE_TYPE.MOBILE}
                  className="h-10 min-w-10 flex items-center justify-center rounded-xl border border-grey-5 text-grey-5 data-[state=on]:bg-primary data-[state=on]:text-primary-foreground data-[state=on]:border-primary-foreground"
                >
                  <MobileIcon width={14} height={14} />
                </ToggleGroupItem>
              </ToggleGroup>
            </div>
            <div className="flex items-center gap-4">
              <Button
                variant="ghost"
                size="icon"
                className="h-9 w-9"
                onClick={handleViewClick}
                disabled={isChecking || isSaving}
              >
                {isChecking ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <EyeIcon className="h-4 w-4" />
                )}
              </Button>
            </div>
          </div>

          {/* Landing page preview */}
          <LandingPagePreview DeviceType={selectedDevice} />
        </CardContent>
      </Card>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Save Changes</DialogTitle>
            <DialogDescription className="pt-2">
              You have unsaved changes. Would you like to publish before viewing the CreatorStore?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex gap-2 mt-4">
            <Button
              variant="outline"
              onClick={() => setShowConfirmDialog(false)}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={handleSaveAndPreview}
              disabled={isSaving}
            >
              {isSaving ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Saving...
                </>
              ) : (
                'Save & View'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
