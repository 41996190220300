import { GetOfferLandingPage_getOfferLandingPage_config_basic_creatorsTag, GetOfferLandingPage_getOfferLandingPage_config_products_allProducts_products, GetOfferLandingPage_getOfferLandingPage_config_style } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferLandingPage';
import { first, last, get } from 'lodash';

export interface CarouselItem {
    src: string;
    type: string;
}
export enum DEVICE_TYPE {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

const currencySymbols = {
    USD: '$',
    CAD: 'CA$',
    EUR: '€',
    GBP: '£',
    INR: '₹',
    JPY: '¥',
    AUD: 'A$',
};

export const defaultImageUrl: string = 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png';
export interface HeroSectionObject {
    creatorTag?: GetOfferLandingPage_getOfferLandingPage_config_basic_creatorsTag;
    offerTitle: string;
    offerDescription: string;
    heroImage?: string;
    heroProductName: string;
    heroProductDesc: string;
    showProductRating: boolean;
    heroProductRating: string;
    heroProductReviews: string;
    heroProductPrice: string;
    heroProductDiscountedPrice: string;
    discountButtonText?: string;
    discountButtonLink?: string;
    carouselData?: CarouselItem[];
    offerDiscount?: string;
    creatorName?: string;
    isFeaturedProductEnable?: boolean;
    isFeaturedContentEnabled?: boolean;
    backgroundColor?: string;
    landingPageStyles?: GetOfferLandingPage_getOfferLandingPage_config_style;
    currency: string;
    heroProductUrl?: string;
}

export interface FeaturedProductObject {
    featuredProductTitle: string;
    showProductRating: boolean;
    featureProductName: string;
    featureProductDesc: string;
    featureProductImage: string;
    featureProductRating: string;
    featureProductReviews: string;
    featureProductPrice: string;
    heroProductDiscountedPrice: string;
    discountButtonLink?: string;
    discountButtonText?: string;
    offerDiscount?: string;
    creatorName?: string;
    landingPageStyles?: GetOfferLandingPage_getOfferLandingPage_config_style;
    currency: string;
    heroProductUrl?: string;
}

export interface AllProductObject {
    title: string;
    products: GetOfferLandingPage_getOfferLandingPage_config_products_allProducts_products[];
    landingPageStyles?: GetOfferLandingPage_getOfferLandingPage_config_style;
    showProductRating: boolean;
    showProductPrice: boolean;
    currency: string;
}

export const getInitials = (name: string) => {
    if (!name || typeof name !== 'string') {
        return '';
    }
    const names = name.split(' ');
    const firstInitial = first(names)?.[0]?.toUpperCase();
    const lastInitial = names.length > 1 ? last(names)?.[0]?.toUpperCase() : '';
    return `${firstInitial || ''}${lastInitial || ''}`;
};

export const getMappedText = (text: string, replacement: string): string => {
    if (!text || !replacement) {
        return text || '';
    }
    const [firstName, _lastName] = replacement.split(' ');
    return text
        .replace(/{{FIRST_NAME}}/g, firstName)
        .replace(/{{FULL_NAME}}/g, replacement)
        .replace(/{{\w+}}/g, replacement);
};

export const getCurrencySymbol = (currencyCode: string): string => {
    if (!currencyCode || typeof currencyCode !== 'string') {
        return '$';
    }
    return get(currencySymbols, currencyCode, currencyCode);
  };
