import * as React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import { dateTypeLabels, dateTypes } from './date-range-form.types';

interface DateRangeFormPresetItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dateType: typeof dateTypes[number];
  selected?: boolean;
}

const DateRangeFormPresetItem = React.forwardRef<HTMLButtonElement, DateRangeFormPresetItemProps>(
  ({
 className, dateType, selected, ...props
}, ref) => (
  <button
    ref={ref}
    type="button"
    className={cn(
        'px-4 py-2 text-left hover:bg-gray-100 transition-colors text-sm',
        selected && 'bg-gray-100',
        className,
      )}
    {...props}
  >
    {dateTypeLabels[dateType]}
  </button>
  ),
);

DateRangeFormPresetItem.displayName = 'DateRangeFormPresetItem';

export { DateRangeFormPresetItem };
