import * as React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import type { DateRangeFormPresetsProps } from './date-range-form.types';

const DateRangeFormPresets = React.forwardRef<HTMLDivElement, DateRangeFormPresetsProps>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn('flex flex-col py-1', className)} {...props}>
      {children}
    </div>
  ),
);

DateRangeFormPresets.displayName = 'DateRangeFormPresets';

export { DateRangeFormPresets };
