import { AppstoreFilled } from '@ant-design/icons';
import { RefreshIcon } from '@components';
import { Button } from 'antd';
import cx from 'classnames';
import { isFunction } from 'lodash';
import * as React from 'react';

import { useAuth } from '@frontend/context/authContext';
import {
  EmailComposerModal,
} from '@frontend/app/components';
import { AddTags } from '@frontend/app/containers/Members/ListHeader/AddTags';
import { DeleteMemberButton } from '@frontend/app/containers/Members/ListHeader/DeleteMemberButton';
import { EditColumns } from '@frontend/app/containers/Members/ListHeader/EditColumns';
import {
  TCondition,
  TProject,
  TTask,
  TWorkItem,
  TWorklet,
} from '@frontend/app/containers/Projects/types';
import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { IMemberSearchQuery, MemberApplicantOperation } from '@services/members';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import { ClientFeature } from '@frontend/app/constants';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { ApplicantOperationButtons } from './ApplicantOperationButtons';
import { CTAButton } from './CTAButton';

import styles from './TableHeader.scss';
import { ModalType } from '../AddMembersToProgramModal/AddMembersToCollectionModal';
import { ProjectsPageState } from '../../constants';

const { useMemo } = React;

interface IProps {
  buttons?: IHeaderButtons;
  className?: string;
  count?: number;
  countSuffix?: string;
  disabledButtons?: IHeaderButtons;
  onCheckIn?: () => void;
  onDelete?: () => void;
  onOpenApplication?: (workItem: TWorkItem, applicationId?: string, task?: TTask) => void;
  pageState?: ProjectsPageState;
  project?: TProject;
  refetchData?: () => Promise<unknown>;
  selectedMembers?: IMember[];
  selectedMemberIds?: IMember['id'][];
  selectedWorkItems?: TWorkItem[];
  selectedWorkItemIds?: TWorkItem['id'][];
  task?: TTask;
  worklets?: TWorklet[];
  conditions?: TCondition[];
  openAddToCollectionModal(modalType: ModalType): void;
  memberApplicantOperation?: MemberApplicantOperation;
  onMemberApplicantOperationError?: () => void;
  onButtonClicked?: (operation?: MemberApplicantOperation) => void;
  onMemberApplicantOperationSuccess?: (memberIds?: IMember['id'][], operation?: MemberApplicantOperation) => void;
  showSelectOffer?: (value: boolean) => void;
  migrateToGraphQL?: boolean;
  offers?: GetOfferById_offer[];
}

interface IHeaderButtons {
  applications?: boolean;
  approve?: boolean;
  columns?: boolean;
  compose?: boolean;
  cta?: boolean;
  ctaBulkAction?: boolean;
  refresh?: boolean;
  reject?: boolean;
  tags?: boolean;
  delete?: boolean;
}

export const TableHeader: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    buttons = {},
    className,
    count = 0,
    countSuffix = 'people',
    disabledButtons = {},
    onCheckIn,
    onDelete,
    onOpenApplication,
    pageState,
    project,
    refetchData,
    selectedMembers,
    selectedMemberIds,
    selectedWorkItems,
    selectedWorkItemIds,
    task,
    worklets,
    conditions,
    openAddToCollectionModal,
    memberApplicantOperation,
    onButtonClicked,
    onMemberApplicantOperationSuccess,
    onMemberApplicantOperationError,
    showSelectOffer,
    migrateToGraphQL,
    offers,
  } = props;

  const handleDelete = async () => {
    if (isFunction(refetchData)) {
      await refetchData();
    }
    if (isFunction(onDelete)) {
      onDelete();
    }
  };

  const searchQuery: IMemberSearchQuery = { programIds: [project?.id] };
  const isWorkflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);
  const { clientInfo } = useAuth();
  const {
    segment,
    columnVisibility,
    updateColumnVisibility,
  } = useMemberListContext();

  const subject = useMemo(() => {
    if (project && clientInfo) {
      const clientName = clientInfo.name;
      return `${clientName} | ${project.title}`;
    }
    return null;
  }, [project, clientInfo]);

  return (
    <div className={cx(styles.TableHeader, className)}>
      <div className={styles.count}>
        {count}
        {' '}
        {countSuffix}
      </div>
      <div className={styles.buttonRow}>
        {buttons.columns && (
          <EditColumns
            className={styles.buttonTooltip}
            isWorkflowEnabled={isWorkflowEnabled}
            segment={segment}
            visibility={columnVisibility}
            onChange={updateColumnVisibility}
            programId={project.id}
          />
        )}
        {buttons.tags && (
          <AddTags
            className={styles.buttonTooltip}
            disabled={!selectedMemberIds?.length}
            memberCount={selectedMemberIds?.length}
            memberIds={selectedMemberIds}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            searchQuery={searchQuery as any}
          />
        )}

        {buttons.compose && (
          <EmailComposerModal
            allowSendingAsSeparate
            className={styles.buttonTooltip}
            disabled={!selectedMembers?.length}
            isWorkflowEnabled
            memberCount={selectedMembers?.length}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            memberQuery={searchQuery as any}
            members={selectedMembers}
            subject={subject}
          />
        )}

        {
          buttons.delete && (
            <DeleteMemberButton
              className={styles.buttonTooltip}
              memberIds={selectedMemberIds}
              onDelete={handleDelete}
            />
          )
        }

        {buttons.applications && (
          <Button
            className={cx(
              styles.button,
              { [styles.disabled]: disabledButtons.applications },
            )}
            disabled={disabledButtons.applications}
            icon={<AppstoreFilled size={16} />}
          />
        )}
        {(buttons.approve || buttons.reject) && (
          <ApplicantOperationButtons
            onButtonClicked={onButtonClicked}
            memberApplicantOperation={memberApplicantOperation}
            onError={onMemberApplicantOperationError}
            disabled={disabledButtons?.approve || disabledButtons?.reject}
            hideApproveButton={!buttons.approve}
            hideRejectButton={!buttons.reject}
            memberIds={selectedMemberIds}
            onMemberApplicantOperationSuccess={onMemberApplicantOperationSuccess}
            project={project}
          />
        )}
        {buttons.cta && (
          <CTAButton
            disabled={disabledButtons?.cta}
            showBulkCTA={buttons.ctaBulkAction}
            onCheckIn={onCheckIn}
            onOpenApplication={onOpenApplication}
            onSkip={refetchData}
            openAddToCollectionModal={openAddToCollectionModal}
            onMemberApplicantOperationSuccess={onMemberApplicantOperationSuccess}
            pageState={pageState}
            project={project}
            refetchData={refetchData}
            selectedMemberIds={selectedMemberIds}
            selectedWorkItems={selectedWorkItems}
            selectedWorkItemIds={selectedWorkItemIds}
            task={task}
            worklets={worklets}
            conditions={conditions}
            showSelectOffer={showSelectOffer}
            migrateToGraphQL={migrateToGraphQL}
            offers={offers}
          />
        )}
        {buttons.refresh && (
          <Button
            className={styles.button}
            disabled={disabledButtons.refresh}
            icon={<RefreshIcon size={16} />}
            onClick={refetchData}
          />
        )}
      </div>
    </div>
  );
});

TableHeader.displayName = 'TableHeader';
