import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { z } from 'zod';
import { dateRangeSchema } from '../components/date-range-form';
import { filtersFormSchema } from '../components/filters-form';
import { QueryVariables, GraphQLQueryVariables, DateType } from '../types';
import { SocialNetwork, SocialPostType } from '../../../../gql/social/graphql';

interface UseAnalyticsParamsProps {
  clientId?: string;
  defaultVariables: Omit<QueryVariables, 'clientId'>;
}

const isValidSocialPostType = (value: string): value is SocialPostType =>
  [
    'INSTAGRAM_POST',
    'INSTAGRAM_REEL',
    'INSTAGRAM_STORY',
    'TIKTOK_VIDEO',
    'YOUTUBE_DEDICATED',
    'YOUTUBE_SHORT',
    'PINTEREST_PIN',
  ].includes(value as SocialPostType);

const isValidSocialNetwork = (value: string): value is SocialNetwork =>
  ['INSTAGRAM', 'TIKTOK', 'PINTEREST', 'YOUTUBE'].includes(value as SocialNetwork);

const isValidDateType = (value: string): value is DateType =>
  [
    'thisWeek',
    'lastWeek',
    'lastMonth',
    'monthToDate',
    'lastQuarter',
    'quarterToDate',
    'last6Months',
    'yearToDate',
    'allTime',
    'custom',
  ].includes(value as DateType);

export const calculateDatesFromType = (dateType: DateType): { startDate: string; endDate: string } => {
  const now = new Date();
  const startDate = new Date();
  const endDate = new Date();

  switch (dateType) {
    case 'thisWeek':
      startDate.setDate(now.getDate() - now.getDay());
      break;
    case 'lastWeek':
      startDate.setDate(now.getDate() - now.getDay() - 7);
      endDate.setDate(now.getDate() - now.getDay() - 1);
      break;
    case 'lastMonth':
      startDate.setMonth(now.getMonth() - 1, 1);
      endDate.setDate(0);
      break;
    case 'monthToDate':
      startDate.setDate(1);
      break;
    case 'lastQuarter':
      startDate.setMonth(Math.floor(now.getMonth() / 3) * 3 - 3, 1);
      endDate.setMonth(Math.floor(now.getMonth() / 3) * 3, 0);
      break;
    case 'quarterToDate':
      startDate.setMonth(Math.floor(now.getMonth() / 3) * 3, 1);
      break;
    case 'last6Months':
      startDate.setMonth(now.getMonth() - 6);
      break;
    case 'yearToDate':
      startDate.setMonth(0, 1);
      break;
    case 'allTime':
      startDate.setFullYear(2000);
      break;
    default:
      break;
  }

  return {
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
  };
};

const parseArrayParam = <T extends string>(
  value: string | null,
  isValid: (v: string) => v is T,
  defaultValue: T[],
): T[] => {
  if (!value) return defaultValue;
  const values = value.split(',');
  return values.filter(isValid);
};

export const useAnalyticsParams = ({ clientId, defaultVariables }: UseAnalyticsParamsProps) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const updateUrlParams = useCallback(
    (variables: QueryVariables) => {
      const params = new URLSearchParams();
      params.set('dateType', variables.dateType);
      if (variables.dateType === 'custom') {
        if (variables.startDate) params.set('startDate', variables.startDate);
        if (variables.endDate) params.set('endDate', variables.endDate);
      }
      if (variables.postTypes.length) params.set('postTypes', variables.postTypes.join(','));
      if (variables.includeEstimates) params.set('includeEstimates', variables.includeEstimates.toString());
      if (variables.networks.length) params.set('networks', variables.networks.join(','));
      history.replace({ pathname: location.pathname, search: params.toString() });
    },
    [history, location.pathname],
  );

  const handleApplyFilters = useCallback(
    (formData: z.infer<typeof filtersFormSchema>) => {
      if (!clientId) return;

      const currentParams = Object.fromEntries(searchParams.entries());
      const dateType = isValidDateType(currentParams.dateType) ? currentParams.dateType : defaultVariables.dateType;

      const variables: QueryVariables = {
        clientId,
        dateType,
        postTypes: formData.postTypes.filter(isValidSocialPostType),
        includeEstimates: formData.includeEstimates,
        networks: formData.network.filter(isValidSocialNetwork),
        ...(dateType === 'custom'
          ? {
              startDate: currentParams.startDate,
              endDate: currentParams.endDate,
            }
          : {}),
      };

      updateUrlParams(variables);
    },
    [clientId, updateUrlParams, searchParams, defaultVariables],
  );

  const handleDateRangeSubmit = useCallback(
    (data: z.infer<typeof dateRangeSchema>) => {
      if (!clientId) return;

      const variables: QueryVariables = {
        clientId,
        dateType: data.dateType as DateType,
        postTypes: parseArrayParam(searchParams.get('postTypes'), isValidSocialPostType, defaultVariables.postTypes),
        includeEstimates: searchParams.get('includeEstimates') === 'true',
        networks: parseArrayParam(searchParams.get('networks'), isValidSocialNetwork, defaultVariables.networks),
      };

      if (data.dateType === 'custom' && data.dateRange?.from && data.dateRange?.to) {
        variables.startDate = data.dateRange.from;
        variables.endDate = data.dateRange.to;
      }

      updateUrlParams(variables);
    },
    [clientId, searchParams, updateUrlParams, defaultVariables],
  );

  const currentParams = Object.fromEntries(searchParams.entries());
  const dateType = isValidDateType(currentParams.dateType) ? currentParams.dateType : defaultVariables.dateType;

  const urlVariables = useMemo<QueryVariables>(
    // eslint-disable-next-line no-confusing-arrow
    () =>
      clientId
        ? {
            clientId,
            dateType,
            postTypes: parseArrayParam(currentParams.postTypes, isValidSocialPostType, defaultVariables.postTypes),
            includeEstimates: currentParams.includeEstimates === 'true',
            networks: parseArrayParam(currentParams.networks, isValidSocialNetwork, defaultVariables.networks),
            ...(dateType === 'custom'
              ? {
                  startDate: currentParams.startDate,
                  endDate: currentParams.endDate,
                }
              : {}),
          }
        : {
            clientId: '',
            ...defaultVariables,
          },
    [clientId, dateType, currentParams, defaultVariables],
  );

  // Convert URL variables to GraphQL variables by ensuring dates are set
  const queryVariables = useMemo<GraphQLQueryVariables>(
    () => ({
      ...urlVariables,
      ...(urlVariables.dateType === 'custom' && urlVariables.startDate && urlVariables.endDate
        ? {
            startDate: urlVariables.startDate,
            endDate: urlVariables.endDate,
          }
        : calculateDatesFromType(urlVariables.dateType)),
    }),
    [urlVariables],
  );

  return {
    queryVariables,
    handleApplyFilters,
    handleDateRangeSubmit,
    updateUrlParams,
  };
};
