import * as React from 'react';
import {
  upperFirst, map, isNil, size,
} from 'lodash';
import { FieldType } from '@frontend/app/types/Fields';
import { ITableColumn } from '@frontend/app/types/Columns';
import {
  useFeatureFlagVerbiage,
  useMemberFieldSchemasQuery,
  useGetAllTableColumns,
} from '@frontend/app/hooks';
import { useMembershipSources } from '@frontend/app/containers/Projects/hooks';
import { ColumnKey } from '@frontend/app/containers/Projects/constants';

const { useMemo } = React;

export const COLUMN_PX_RATIO = 10;
export const COLUMN_ADDED_WIDTH = 50;
export const COLUMN_REMOVED_MIN_WIDTH = 100;
export const COLUMN_ADDED_MAX_WIDTH = 100;

const isEditableType = (type: string) => {
  switch (type) {
    case FieldType.ANNUAL:
    case FieldType.BOOLEAN:
    case FieldType.DATE:
    case FieldType.NUMBER:
    case FieldType.TEXT:
    case FieldType.EMAIL:
      return true;

    default:
      return false;
  }
};
const ageGroupOrder = [
  'All Ages',
  'Ages 13-17',
  'Ages 18-24',
  'Ages 25-34',
  'Ages 35-44',
  'Ages 45-64',
  'Ages 65+',
];

export const getColumnWidth = (columnTitle) => {
  const charCount = size(columnTitle?.split(''));
  return {
    width: (charCount * COLUMN_PX_RATIO) + COLUMN_ADDED_WIDTH,
    minWidth: (charCount * COLUMN_PX_RATIO) - COLUMN_REMOVED_MIN_WIDTH,
    maxWidth: (charCount * COLUMN_PX_RATIO) + COLUMN_ADDED_MAX_WIDTH,
  };
};

export const useMemberColumns = (communityId?: number, programId?: number): ITableColumn[] => {
  const {
    data: {
      schemas: memberFieldSchemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();

  const {
    data: {
      columns: tableColumns = [],
    } = {},
  } = useGetAllTableColumns();

  const verbiage = useFeatureFlagVerbiage();

  const {
    sourcesFilterChoices,
  } = useMembershipSources();

  return useMemo(() => {
    // TODO (rl) implement a more scalable approach to editable columns, showInEditColumns, showInFilters, etc.
    const editableColumns = [
      'email',
      'notes',
    ];

    const columnData = Array.isArray(memberFieldSchemas)
      ? memberFieldSchemas
        .flatMap((schema) => {
          if (!schema?.metaData?.subColumns || !Array.isArray(schema.metaData.subColumns)) {
            return []; // Skip schemas without valid columns
          }
          return schema.metaData.subColumns
            .filter((subColumn) => subColumn && subColumn.id != schema.id)
            .map((subColumn) => ({
              field: String(subColumn.id),
              headerName: upperFirst(subColumn.name), // Ensure the header name is capitalized
              dbName: subColumn.dbName,
              type: subColumn.type,
              editable: (!subColumn.applicationId || subColumn.metaData?.editable) && isEditableType(subColumn.type),
              choices: subColumn.choices,
              schemaId: subColumn.id,
              hidden: true,
              ...getColumnWidth(subColumn.name), // Add column width using the name
          }));
        })
        .filter(Boolean) // Remove any falsy values
      : [];
    const allColumns = [
      ...map(memberFieldSchemas, (schema) => ({
        schemaId: schema.id,
        headerName: upperFirst(schema.name),
        field: String(schema.id),
        type: schema.type,
        editable: (!schema.applicationId || schema.metaData?.editable) && isEditableType(schema.type),
        choices: schema.choices,
        subColumns: schema?.metaData?.subColumns
          ?.slice()
          ?.sort((a, b) =>
            // Custom sort for age groups
            ageGroupOrder.indexOf(a.name) - ageGroupOrder.indexOf(b.name))?.map((subColumn) => ({
            ...subColumn,
            field: String(subColumn.id),
            headerName: upperFirst(subColumn.name),
            type: subColumn.type,
            editable: (!schema.applicationId || subColumn.metaData?.editable) && isEditableType(subColumn.type),
            choices: subColumn.choices,
            schemaId: subColumn.id,
            ...getColumnWidth(subColumn.name),
          })),
        ...getColumnWidth(schema.name),
      } as ITableColumn)),
      ...columnData,
      ...map(tableColumns, (column) => ({
        headerName: column.title,
        field: column.field,
        type: column.type,
        source: column.source,
        width: column.width || getColumnWidth(column.title).width,
        minWidth: column.minWidth || getColumnWidth(column.title).minWidth,
        maxWidth: column.maxWidth || getColumnWidth(column.title).maxWidth,
        isDefault: column.isDefault,
        fixed: column.fixed,
        dataIndex: column.dataIndex,
        readonly: column.readonly,
        itemProp: column.itemProp,
        editable: editableColumns.includes(column.field),
      })),
      {
        headerName: verbiage.Programs,
        field: ColumnKey.Programs,
        type: FieldType.PROGRAM,
        itemProp: 'title',
        width: 350,
        minWidth: 300,
        maxWidth: 600,
      },
      {
        headerName: verbiage.Communities,
        field: ColumnKey.Communities,
        type: FieldType.COMMUNITY,
        itemProp: 'title',
        showInFilters: isNil(communityId),
        width: 350,
        minWidth: 300,
        maxWidth: 600,
      },
      ...isNil(programId) ? [] : [{
        headerName: 'Date Applied',
        field: 'appliedDate',
        type: FieldType.DATE,
        showInFilters: true,
        showInEditColumns: true,
        editable: false,
        sortable: true,
        width: 200,
        minWidth: 150,
        maxWidth: 250,
      }],
      ...isNil(programId) ? [] : [{
        headerName: 'Date Rejected',
        field: 'rejectedDate',
        type: FieldType.DATE,
        showInFilters: true,
        showInEditColumns: true,
        editable: false,
        sortable: true,
        width: 200,
        minWidth: 150,
        maxWidth: 250,
      }],
      {
        headerName: 'Open Requirements',
        field: 'incompleteRequirements',
        type: FieldType.BOOLEAN,
        showInEditColumns: false,
        width: 200,
        minWidth: 150,
        maxWidth: 250,
      },
      {
        headerName: 'Overdue Requirements',
        field: 'overdueRequirements',
        type: FieldType.BOOLEAN,
        showInEditColumns: false,
        width: 200,
        minWidth: 150,
        maxWidth: 250,
      },
    ];

    // Refactor this file to have one typing for all columns
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const ApplicantSource: any = allColumns.find((column) => column.field === 'applicantSource');
    if (ApplicantSource) {
      ApplicantSource.showInFilters = true;
      ApplicantSource.showInEditColumns = true;
      ApplicantSource.editable = false;
      ApplicantSource.sortable = false;
      ApplicantSource.choices = sourcesFilterChoices;
    }

    return allColumns;
  }, [
    tableColumns,
    memberFieldSchemas,
    communityId,
    verbiage,
    sourcesFilterChoices,
    programId,
  ]);
};
