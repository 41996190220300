import React from 'react';
import { useForm, Controller, FieldErrors } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@frontend/shadcn/components/ui/collapsible';
import { ChevronUpIcon } from '@radix-ui/react-icons';
import { cn } from '@frontend/shadcn/lib/utils';
import {
 Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { FormMessage } from '@frontend/shadcn/components/ui/form';

export const filtersFormSchema = z
  .object({
    filterType: z.enum(['network', 'posts']).default('network'),
    network: z.array(z.enum(['INSTAGRAM', 'TIKTOK', 'PINTEREST', 'YOUTUBE'])).default([]),
    projects: z.string().min(1, 'Project selection is required'),
    groups: z.string().min(1, 'Group selection is required'),
    postTypes: z
      .array(
        z.enum([
          'INSTAGRAM_POST',
          'INSTAGRAM_REEL',
          'INSTAGRAM_STORY',
          'TIKTOK_VIDEO',
          'YOUTUBE_VIDEO',
          'YOUTUBE_SHORT',
          'PINTEREST_PIN',
        ]),
      )
      .default([]),
    includeEstimates: z.boolean().default(false),
  })
  .refine((data) => !(data.network.length > 0 && data.postTypes.length > 0), {
    message: 'Please select either Networks or Post Types, but not both',
    path: ['network'],
  });

type FiltersFormData = z.infer<typeof filtersFormSchema>;

type NetworkValue = z.infer<typeof filtersFormSchema>['network'][number];
type PostTypeValue = z.infer<typeof filtersFormSchema>['postTypes'][number];

const networkOptions: Array<{ value: NetworkValue; label: string }> = [
  { value: 'INSTAGRAM', label: 'Instagram' },
  { value: 'TIKTOK', label: 'TikTok' },
  { value: 'PINTEREST', label: 'Pinterest' },
  { value: 'YOUTUBE', label: 'YouTube' },
];

const postTypeOptions: Array<{ value: PostTypeValue; label: string }> = [
  { value: 'INSTAGRAM_POST', label: 'Instagram Post' },
  { value: 'INSTAGRAM_REEL', label: 'Instagram Reel' },
  { value: 'INSTAGRAM_STORY', label: 'Instagram Story' },
  { value: 'TIKTOK_VIDEO', label: 'TikTok Video' },
  { value: 'YOUTUBE_VIDEO', label: 'YouTube Video' },
  { value: 'YOUTUBE_SHORT', label: 'YouTube Short' },
  { value: 'PINTEREST_PIN', label: 'Pinterest Pin' },
];

type FiltersFormProps = {
  onApply: (data: FiltersFormData) => void;
  onCancel: () => void;
  defaultValues?: Partial<FiltersFormData>;
};

export const FiltersForm = ({ onApply, onCancel, defaultValues }: FiltersFormProps) => {
  const form = useForm<z.infer<typeof filtersFormSchema>>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      network: defaultValues?.network || [],
      postTypes: defaultValues?.postTypes || [],
      includeEstimates: defaultValues?.includeEstimates || false,
      filterType: defaultValues?.postTypes?.length ? 'posts' : 'network',
      projects: 'all',
      groups: 'all',
    },
  });

  const handleSubmit = (data: z.infer<typeof filtersFormSchema>) => {
    onApply(data);
  };

  const handleError = (errors: FieldErrors<z.infer<typeof filtersFormSchema>>) => {
    console.error('Form validation errors:', errors);
  };

  const network = form.watch('network');
  const postTypes = form.watch('postTypes');

  // Clear the other field when one is selected
  React.useEffect(() => {
    if (network.length > 0) {
      form.setValue('postTypes', []);
    }
  }, [network, form]);

  React.useEffect(() => {
    if (postTypes.length > 0) {
      form.setValue('network', []);
    }
  }, [postTypes, form]);

  return (
    <form onSubmit={form.handleSubmit(handleSubmit, handleError)} className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto">
        <Collapsible defaultOpen className="border-b">
          <CollapsibleTrigger className="flex w-full items-center justify-between py-6">
            <span className="font-medium">Assignment</span>
            <ChevronUpIcon className="h-4 w-4" />
          </CollapsibleTrigger>
          <CollapsibleContent className="pb-6 space-y-4">
            <div className="space-y-2">
              <Label>Projects</Label>
              <Controller
                name="projects"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger className={cn(form.formState.errors.projects && 'border-red-500')}>
                        <SelectValue placeholder="Select projects" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All projects</SelectItem>
                      </SelectContent>
                    </Select>
                    {form.formState.errors.projects && (
                      <FormMessage>{form.formState.errors.projects.message}</FormMessage>
                    )}
                  </>
                )}
              />
            </div>
            <div className="space-y-2">
              <Label>Groups</Label>
              <Controller
                name="groups"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger className={cn(form.formState.errors.groups && 'border-red-500')}>
                        <SelectValue placeholder="Select groups" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All groups</SelectItem>
                      </SelectContent>
                    </Select>
                    {form.formState.errors.groups && <FormMessage>{form.formState.errors.groups.message}</FormMessage>}
                  </>
                )}
              />
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox id="unassigned" />
              <label
                htmlFor="unassigned"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Include unassigned posts
              </label>
            </div>
          </CollapsibleContent>
        </Collapsible>

        <Collapsible defaultOpen className="border-b">
          <CollapsibleTrigger className="flex w-full items-center justify-between py-6">
            <span className="font-medium">Social Metrics</span>
            <ChevronUpIcon className="h-4 w-4" />
          </CollapsibleTrigger>
          <CollapsibleContent className="pb-6 space-y-6">
            <Controller
              name="filterType"
              control={form.control}
              render={({ field }) => (
                <RadioGroup
                  className="space-y-4"
                  value={field.value}
                  onValueChange={(value: 'network' | 'posts') => {
                    field.onChange(value);
                    if (value === 'network') {
                      form.setValue('postTypes', []);
                    } else {
                      form.setValue('network', []);
                    }
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="network" id="network" />
                    <Label htmlFor="network">Network</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="posts" id="posts" />
                    <Label htmlFor="posts">Posts</Label>
                  </div>
                </RadioGroup>
              )}
            />

            <div className="space-y-4">
              <Controller
                name="network"
                control={form.control}
                render={({ field }) => (
                  <div className={cn('space-y-2', form.watch('filterType') === 'posts' && 'hidden')}>
                    <div className="pl-2 space-y-2">
                      {networkOptions.map((option) => (
                        <div key={option.value} className="flex items-center space-x-2">
                          <Checkbox
                            id={option.value}
                            checked={field.value.includes(option.value)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                field.onChange([...field.value, option.value]);
                              } else {
                                field.onChange(field.value.filter((v) => v !== option.value));
                              }
                            }}
                          />
                          <label htmlFor={option.value} className="text-sm">
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    {form.formState.errors.network && (
                      <FormMessage>{form.formState.errors.network.message}</FormMessage>
                    )}
                  </div>
                )}
              />

              <Controller
                name="postTypes"
                control={form.control}
                render={({ field }) => (
                  <div className={cn('space-y-2', form.watch('filterType') === 'network' && 'hidden')}>
                    <div className="pl-2 space-y-2">
                      {postTypeOptions.map((option) => (
                        <div key={option.value} className="flex items-center space-x-2">
                          <Checkbox
                            id={option.value}
                            checked={field.value.includes(option.value)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                field.onChange([...field.value, option.value]);
                              } else {
                                field.onChange(field.value.filter((v) => v !== option.value));
                              }
                            }}
                          />
                          <label htmlFor={option.value} className="text-sm">
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    {form.formState.errors.postTypes && (
                      <FormMessage>{form.formState.errors.postTypes.message}</FormMessage>
                    )}
                  </div>
                )}
              />
            </div>
          </CollapsibleContent>
        </Collapsible>

        <Collapsible defaultOpen className="border-b">
          <CollapsibleTrigger className="flex w-full items-center justify-between py-6">
            <span className="font-medium">Data Source</span>
            <ChevronUpIcon className="h-4 w-4" />
          </CollapsibleTrigger>
          <CollapsibleContent className=" pb-6">
            <Controller
              name="includeEstimates"
              control={form.control}
              render={({ field }) => (
                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="includeEstimates"
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                      }}
                    />
                    <label htmlFor="includeEstimates" className="text-sm">
                      Include estimated data
                    </label>
                  </div>
                </div>
              )}
            />
          </CollapsibleContent>
        </Collapsible>
      </div>

      <div className="flex justify-start gap-2 p-4 mt-auto border-t bg-background mx-[-1.5rem]">
        <Button type="submit" variant="primary" size="sm">
          Apply
        </Button>
        <Button type="button" variant="ghost" size="sm" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
