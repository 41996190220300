// SettingsContent.tsx
import React from 'react';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Label } from '@frontend/shadcn/components/ui/label';
// import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import {
 Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { cn } from '@frontend/shadcn/lib/utils';

// const productSelectionOptions = [
//   { id: 'ordered', label: 'The products they have ordered' },
//   { id: 'collection', label: 'Selected Collection' },
// ];
const MAX_PRODUCTS_LIMIT = 12;

const SettingsContent: React.FC = () => {
  const { landingPageData, setLandingPageData } = useLandingPageContext();

  const selectedProductsCount = landingPageData.products.allProducts?.products?.length || 0;
  const availableLimit = MAX_PRODUCTS_LIMIT - selectedProductsCount;

  const handleSectionHeadingChange = (value: string) => {
    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        setting: {
          ...prev.products.setting,
          sectionHeading: value,
        },
      },
    }));
  };

  const handleAllowCreatorsChange = (checked: boolean) => {
    if (availableLimit === 0) return;

    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        setting: {
          ...prev.products.setting,
          allowCreatorToAddProducts: checked,
        },
      },
    }));
  };

  const handleNoOfProductsChange = (value: string) => {
    // Convert to number and validate
    const numValue = parseInt(value, 10);
    if (isNaN(numValue)) return;

    // Ensure the value doesn't exceed available limit
    const finalValue = Math.min(numValue, availableLimit);

    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        setting: {
          ...prev.products.setting,
          noOfProductsCreatorCanAdd: finalValue,
        },
      },
    }));
  };

  const checkboxContent = (
    <div className={cn(
      'flex items-center gap-2 w-full',
      availableLimit === 0 && 'opacity-70',
    )}
    >
      <Checkbox
        id="allowCreatorToAddProducts"
        checked={landingPageData.products.setting.allowCreatorToAddProducts}
        onCheckedChange={handleAllowCreatorsChange}
        disabled={availableLimit === 0}
        className="h-[18px] w-[18px] border-neutralgrey-6 bg-neutralgrey-6"
      />
      <Label
        htmlFor="allowCreatorToAddProducts"
        className={cn(
          'font-medium text-neutralgrey-6 text-sm',
          availableLimit === 0 && 'cursor-not-allowed',
        )}
      >
        Allow creators to add products
      </Label>
    </div>
  );

  return (
    <div className="flex flex-col items-start gap-2 px-4">
      <section className="flex flex-col items-start w-full gap-2 mb-2">
        <Label htmlFor="campaign-name" className="text-neutralgrey-5 text-xs font-medium leading-5">
          Section Heading
        </Label>
        <Input
          id="campaign-name"
          value={landingPageData.products.setting.sectionHeading}
          onChange={(e) => handleSectionHeadingChange(e.target.value)}
          className="w-full bg-neutralgrey-0 border-neutralgrey-2 text-neutralgrey-6 text-sm leading-[22px]"
          placeholder="Enter section heading"
        />
      </section>

      {availableLimit === 0 ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              {checkboxContent}
            </TooltipTrigger>
            <TooltipContent>
              <p>Maximum product limit reached. Remove some products to enable creator additions.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        checkboxContent
      )}

      {/* Radio Group Section Uncomment in Phase 2 */}
      {/* <div className="flex flex-col gap-2.5 px-6">

        <Label className="text-xs font-medium text-neutralgrey-5"> Creator can select products from </Label>
        <RadioGroup defaultValue="ordered" className="flex gap-4">

          {productSelectionOptions.map((option) => (
            <div key={option.id} className="flex items-center space-x-2">

              <RadioGroupItem
                value={option.id}
                id={option.id}
                className="h-[18px] w-[18px] border-neutralgrey-6"
              />
              <Label htmlFor={option.id} className="text-sm text-neutralgrey-6">

                {option.label}
              </Label>
            </div>
          ))}
        </RadioGroup>
      </div> */}
      {landingPageData.products.setting.allowCreatorToAddProducts && (
        <div className="flex flex-col gap-2.5 px-6">
          <Label className="text-xs font-medium text-neutralgrey-5">
            Numbers of products creators can add (max available:
            {' '}
            {availableLimit}
            )
          </Label>
          <Input
            type="number"
            value={landingPageData.products.setting.noOfProductsCreatorCanAdd || ''}
            onChange={(e) => handleNoOfProductsChange(e.target.value)}
            min={1}
            max={availableLimit}
            className="w-[255px] h-8 bg-neutralgrey-0 border-neutralgrey-2 text-neutralgrey-6"
            placeholder="Enter Product Limit"
          />
        </div>
      )}
    </div>
  );
};

export default SettingsContent;
