import * as React from 'react';
import classNames from 'classnames';

interface IProps {
  align?: 'left' | 'center' | 'right';
  className?: string;
  width?: string | number;
}

export const EllipsisLabel: React.FC<React.PropsWithChildren<IProps>> = ({
  align = 'left',
  className = '',
  width = 100,
  children,
}) => (
  <div
    className={classNames(
      'overflow-hidden whitespace-nowrap text-ellipsis',
      className,
      {
        'text-left': align === 'left',
        'text-center': align === 'center',
        'text-right': align === 'right',
      },
      'text-grey-6',
    )}
    style={{ width: typeof width === 'number' ? `${width}px` : width }}
  >
    {children}
  </div>
);
