const env = process.env.NODE_ENV || 'development';

/** General Configurations Like PORT, HOST names and etc... */
const config = {
  env,
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 8080,
  apiProto: process.env.API_PROTO || 'http',
  apiHost: process.env.API_HOST || process.env.HOST || 'localhost',
  apiPort: process.env.API_PORT || process.env.PORT || 3030,
  adminProto: process.env.ADMIN_PROTO || 'http',
  adminHost: process.env.ADMIN_HOST || 'localhost',
  adminPort: process.env.ADMIN_PORT || 8081,
  karmaPort: 9876,
  datadogRUMEnabled: process.env.DD_RUM_ENABLED,
  datadogENV: process.env.DD_ENV,
  datadogService: process.env.DD_SERVICE || 'aspirex-app',
  datadogVersion: process.env.DD_VERSION,
  platform: process.env.PLATFORM,

  datadogRUMClientToken: process.env.DD_RUM_CLIENT_TOKEN,
  datadogRUMApplicationId: process.env.DD_RUM_APP_ID,

  trayMasterToken: 'e0d3b35d735c4f6abab1fecc65319e4e9d4cefa002b345fa9d58a0c38c073e30',

  posthogApiKey: 'phc_SkfDEcfBZVp5Sy93FX809RK2Dghy9GDUnICrG3okYEY',
  posthogHost: 'https://us.i.posthog.com',
};

export {
  config,
};
