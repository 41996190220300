import {
  Badge,
  Button, Col, Dropdown, Menu, Modal, Popover, Row, Tag, Tooltip, Typography,
} from '@revfluence/fresh';
import * as React from 'react';
import { useState } from 'react';
import { InfoCircleFilled, SettingOutlined } from '@ant-design/icons';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Link from 'antd/lib/typography/Link';
import {
  first, isEmpty, isNull, trimEnd, capitalize,
} from 'lodash';
import { format } from 'date-fns';
import { STAPaymentApp } from '@frontend/applications/AffiliatesApp/containers';
import { DateFilter, IDateRangeSettings } from '@frontend/app/components';
import { CLIENT_CONNECTION_STATUS, OFFER_PRICE_RULE_TYPE, OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import moment from 'moment';
import { ShopifyIcon } from '@revfluence/fresh-icons/brands/esm';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import {
  BoxArchiveIcon, ChartBarIcon, ClockRotateLeftIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useApolloClientForAspirex } from '@frontend/applications/AffiliatesApp/hooks/useApolloClientForAspirex';
import { GET_ALL_PROGRAMS } from '@frontend/app/queries';
import { GetAllPrograms_programs } from '@frontend/app/queries/types/GetAllPrograms';
import _ from 'lodash';
import { EllipsisIcon } from '@revfluence/fresh-icons/solid';
import { useArchiveOfferMutation } from '@frontend/applications/AffiliatesApp/hooks/useArchiveOfferMutation';
import { message } from 'antd';
import HeaderButton from '@frontend/app/refresh-components/HeaderButton';
import { ArrowLeft } from 'lucide-react';
import { Button as ShadCnBtn } from '@frontend/shadcn/components/ui/button';
import { Small } from '@frontend/shadcn/components/typography/small';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { usePayoutLabels } from '../../OfferSummaryCard/hooks';
import styles from './OfferDetailCard.scss';
import { ConversionTrackingModal } from '../../ConversionTrackingModal';
import { OfferImage } from '../../OfferImage';
import { OfferLogDrawer } from '../../OfferLogDrawer';
import { logger } from '../../../../../../../common/Logger';
import { OfferConversionHistoryDrawer } from '../../OfferConversionHistoryDrawer';

interface IProps {
  clientId?: string;
  clientName?: string;
  dateRangeSettings?: IDateRangeSettings;
  showCta?: boolean;
  isWorkFlow?: boolean;
  infoButtonClassName?: boolean; // Define the class name for the 'info' button and specify its position relative to the 'offer detail.
  offerData?: GetOfferById_offer;
  archiveOffer?: boolean;
  refreshUi?: boolean;
  goBack?: () => void;
  hideOfferName?: boolean
}

enum OFFER_STATUS {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  PAUSED = 'PAUSED',
  EXPIRED = 'Expired',
}

const { Title, Text, Paragraph } = Typography;

const getOfferStatusColor = (status): string => {
  switch (status) {
    case OFFER_STATUS.ACTIVE:
      return '#4eb468';
    case OFFER_STATUS.PAUSED:
      return '#eacd60';
    case OFFER_STATUS.EXPIRED:
      return '#f1515f';
    default:
      return '#4eb468';
  }
};
// Offer details nav bar UI with offer details info button and show button(payment overview and Date filter)
export const OfferDetailCard: React.FC<Readonly<IProps>> = (props) => {
  const {
    clientId, clientName, dateRangeSettings, showCta, infoButtonClassName, isWorkFlow, offerData, refreshUi, goBack,
    hideOfferName,
  } = props;
  {
    const [visible, setVisible] = useState(false);
    const [isOfferLogDrawerVisible, setIsOfferLogDrawerVisible] = useState(false);
    const [instructionsVisible, updateInstructionsVisibility] = useState(false);
    const [archiveOfferModal, setArchiveOfferModal] = React.useState(false);
    const [archiveOfferLoading, setArchiveOfferLoading] = React.useState(false);
    const [isOfferConversionHistoryDrawerVisible, setIsOfferConversionHistoryDrawerVisible] = useState(false);
    const [programs, setPrograms] = useState<GetAllPrograms_programs[]>();
    const history = useHistory();
    const match = useRouteMatch();
    const baseUri = match.url;
    const aspirexApolloClient = useApolloClientForAspirex();
    const [archiveOfferMutation] = useArchiveOfferMutation();
    const { earliestDate } = dateRangeSettings || {};
    const { migrateToGraphQL, archiveOffer } = useClientFeatures();
    const {
      offer: contextOffer, setShowMigrationModal, disableOfferEdit, profile,
    } = useOfferDetailsContext() || {}; // if calling from Affiliates app then fetch the offer from context otherwise pass the offer detail as prop
    let offer = contextOffer;
    if (isWorkFlow) {
      offer = offerData;
    }
    const mPayoutLabels = usePayoutLabels(
      offer ? offer.payouts : [],
      migrateToGraphQL,
    );

    React.useEffect(() => {
      aspirexApolloClient.query({
        query: GET_ALL_PROGRAMS,
      }).then((data) => {
        setPrograms(data.data.programs);
      }).catch((error) => {
        logger.error(error);
      });
    }, [aspirexApolloClient]);
    const programName = React.useMemo(() => {
      if (isNull(offer)) {
        return;
      }
      const program = _.find(programs, (program) => program.id === offer.programId);
      return program ? program?.title : null;
    }, [programs, offer]);

    if (!offer) {
      return null;
    }

  const linkOffer = !offer.isPromoLink && offer.links.length ? first(offer.links) : null;
  const isPromoLink = offer.isPromoLink;
    const promoOffer = first(offer.promos);
    const isMultipleShopifySyncEnabled = promoOffer?.connectedClientMetadata?.some((client) => client.status === CLIENT_CONNECTION_STATUS.ACTIVE) || false;
    const showInstructions = () => {
      updateInstructionsVisibility(true);
    };
    const hideInstructions = () => {
      updateInstructionsVisibility(false);
    };
    const OfferContent = () => {
      const formattedExpirationDate = offer && !offer.isPromoLink && offer.expirationDate ? format(new Date(offer.expirationDate), 'MMM d, yyyy h:mm a') : '';
      const formattedStartDate = promoOffer && promoOffer.startDate ? format(new Date(promoOffer.startDate), 'MMM d, yyyy h:mm a') : '';
      const formattedEndDate = promoOffer && promoOffer.endDate ? ` - ${format(new Date(promoOffer.endDate), 'MMM d, yyyy h:mm a')}` : '';

      const linkOfferUrlWithUtm = linkOffer && linkOffer.utmFields
        ? `${linkOffer.url}?${_.chain(linkOffer.utmFields)
          .map((value, key) => `${key}=${value}`)
          .join('&')
          .value()}`
        : `${linkOffer?.url}`;
      return (
        <div className={styles.offerDetailContainer}>
          <Row justify="space-between">
            <Text strong>{offer.name}</Text>
            {!isPromoLink && (offer.promos.length > 0 || linkOffer) && (
              <Link href="#" onClick={showInstructions}>
                Instructions
              </Link>
            )}
            {isMultipleShopifySyncEnabled && (
              <Tag color="#006462" icon={<ShopifyIcon />}>
                Promo Code Sync Enabled
              </Tag>
            )}
          </Row>
          {promoOffer && (
            <Paragraph ellipsis={{ rows: 2 }} className={styles.ellipsisLableMo}>
              <Title level={4}>
                {promoOffer.priceRuleType === OFFER_PRICE_RULE_TYPE.AMOUNT ? '$' : ''}
                {promoOffer.priceRuleAmount}
                {promoOffer.priceRuleType === OFFER_PRICE_RULE_TYPE.PERCENTAGE ? '%' : ''}
                {' '}
                Discount
              </Title>
            </Paragraph>
          )}
          <Paragraph ellipsis={{ rows: 2 }} className={styles.ellipsisLable}>
            <Text type="secondary">{offer.description}</Text>
          </Paragraph>
          {linkOffer && (
            <Tooltip title={linkOfferUrlWithUtm}>
              <Paragraph ellipsis={{ rows: 2 }} className={styles.ellipsisLable}>
                <Link href={linkOfferUrlWithUtm || '#'} rel="noreferrer" target="_blank">
                  {trimEnd(linkOfferUrlWithUtm, '/') || 'https://brand.com/shop'}
                </Link>
              </Paragraph>
            </Tooltip>
          )}
          {mPayoutLabels.length > 0 && (
            <Row className={styles.payoutsContainer}>
              <Text className={styles.payoutsLabel}>Payouts</Text>
              <div className={styles.payoutsValueContainer}>
                {mPayoutLabels.map((payoutLabel, index) => (
                  <Text className={styles.payoutsValue} key={index}>
                    {payoutLabel}
                  </Text>
                ))}
              </div>
            </Row>
          )}
          {linkOffer && (
            <Text>
              <strong>Expiry Date: </strong>
              {' '}
              {formattedExpirationDate}
            </Text>
          )}
          {promoOffer && (
            <Text>
              <strong>Active From: </strong>
              {' '}
              {formattedStartDate}
              {formattedEndDate}
            </Text>
          )}
          {programName && (
            <div className={styles.projectNameContainer}>
              <Text>
                <strong>Connected Project: </strong>
                {' '}
                <Text type="secondary" className={styles.projectName} strong>
                  {programName}
                </Text>
              </Text>
            </div>
          )}
        </div>
      );
    };

    const handleClickEditOffer = () => {
      if (offer.links.length && !offer.isPromoLink) {
        if (migrateToGraphQL && isNull(linkOffer.defaultPayoutId)) {
          setShowMigrationModal(true);
        } else {
          history.push({
            ...location,
            pathname: `${baseUri}/offers/${offer.id}/edit`,
            state: {
              isNewFlow: offer.isNewFlow,
            },
          });
        }
      }
      if (offer.promos.length) {
        if (migrateToGraphQL) {
          if (offer.isNewFlow && promoOffer.defaultPayoutId) {
            history.push({
              ...location,
              pathname: `${baseUri}/offers/${offer.id}/edit`,
              state: { isNewFlow: true, isMigrationEnabled: false },
            });
          } else {
            setShowMigrationModal(true);
          }
        } else {
          history.push({
            ...location,
            pathname: `${baseUri}/offers/${offer.id}/edit`,
            state: {
              isNewFlow: offer.isNewFlow,
            },
          });
        }
      }
    };

    const getOfferStatus = () => {
      if (!isEmpty(promoOffer)) {
        const endDate = moment(promoOffer.endDate);
        return endDate < moment() ? OFFER_STATUS.EXPIRED : promoOffer.status;
      } else if (!isEmpty(linkOffer)) {
        const expirationDate = moment(offer.expirationDate);
        return expirationDate < moment() ? OFFER_STATUS.EXPIRED : linkOffer.status;
      }
      return null;
    };

    const OfferTag = () => {
      if (offer.isReadOnly) {
        return (
          <Tag color="#4eb468" size="small">
            Readonly
          </Tag>
        );
      }
      if (!isNull(offer.archivedDate)) {
        if (refreshUi) {
          return (
            <Small className="text-grey-4">Your offer is archived</Small>
          );
        }
        return (
          <Badge status="default" text="Your offer is archived" />
        );
      }
      const status = getOfferStatus();
      if (!status) {
        return null;
      }
      const color = getOfferStatusColor(status);
      const discountText = promoOffer ? `${status} - ${promoOffer.priceRuleType === OFFER_PRICE_RULE_TYPE.AMOUNT ? '$' : ''} ${promoOffer.priceRuleAmount} ${promoOffer.priceRuleType === OFFER_PRICE_RULE_TYPE.PERCENTAGE ? '%' : ''} Discount` : capitalize(status);
      return (
        <Tag color={color} size="small">
          {discountText}
        </Tag>
      );
    };
    const handleArchiveOffer = async () => {
      setArchiveOfferLoading(true);
      try {
        await archiveOfferMutation({
          variables: {
            archiveOfferInput: {
              offerId: offer.id,
              userInfo: {
                email: profile.email,
                name: profile.name,
                clientId,

              },
            },
          },
        });
      } catch (error) {
        message.error(error.message || 'Failed to archive offer');
      } finally {
        setArchiveOfferLoading(false);
        setArchiveOfferModal(false);
      }
    };
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={() => setIsOfferConversionHistoryDrawerVisible(true)} icon={<ChartBarIcon />}>
          Offer Conversions Report
        </Menu.Item>
        <Menu.Item key="2" onClick={() => setIsOfferLogDrawerVisible(true)} icon={<ClockRotateLeftIcon />}>
          Offer Update History
        </Menu.Item>
        {archiveOffer && isNull(offer.archivedDate) && (
          <Menu.Item key="3" danger onClick={() => setArchiveOfferModal(true)} icon={<BoxArchiveIcon />}>
            Archive Offer
          </Menu.Item>
        )}
      </Menu>
    );
    const ActionsButtons = () => {
      if (!showCta) {
        return null;
      }
      return (
        <Row justify="end" gutter={8}>
          <Col>
            <Tooltip title={offer.isReadOnly ? 'Setting is disable for Readonly offers' : 'Offer Settings'}>
              <Button
                className={styles.offerSettingsButton}
                onClick={handleClickEditOffer}
                size="middle"
                disabled={disableOfferEdit || offer.isReadOnly}
                icon={<SettingOutlined />}
              />
            </Tooltip>
          </Col>
          <Col>
            <STAPaymentApp clientId={clientId} clientName={clientName} initialDateFilterSettings={dateRangeSettings} />
          </Col>
          {
            migrateToGraphQL && (
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button icon={<EllipsisIcon />} />
              </Dropdown>
            )
          }
        </Row>
      );
    };
    const RefreshActionsButtons = () => {
      if (!showCta) {
        return null;
      }
      return (
        <div className="flex justify-end space-x-4">
          <div>
            <DateFilter earliestDate={earliestDate} settings={dateRangeSettings} refreshUi={refreshUi} key={1} />
          </div>
          <div>
            <STAPaymentApp clientId={clientId} clientName={clientName} initialDateFilterSettings={dateRangeSettings} refreshUi={refreshUi} />
          </div>
          <div>
            <Tooltip title={offer.isReadOnly ? 'Setting is disable for Readonly offers' : 'Offer Settings'}>
              <ShadCnBtn disabled={offer.isReadOnly} size="headerIcon" variant="outlineHeader" onClick={handleClickEditOffer}><SettingOutlined className="h-4 w-4" /></ShadCnBtn>
            </Tooltip>
          </div>
          <div>
            {
              migrateToGraphQL && (
                <Dropdown overlay={menu} placement="bottomLeft">
                  <ShadCnBtn size="headerIcon" variant="outlineHeader"><EllipsisIcon className="h-4 w-4" /></ShadCnBtn>
                </Dropdown>
              )
            }
          </div>
        </div>
      );
    };

    if (refreshUi) {
      return (
        <>
          <div className="flex justify-between w-full">
            <div className="flex items-center space-x-2">
              <HeaderButton
                onClick={goBack}
                icon={<ArrowLeft className="h-4 w-4" />}
              />
              <OfferImage
                className="!w-9 !h-9"
                imageUrl={offer?.imageUrl}
                source={promoOffer ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE}
              />
              <div>
                <h1 className="text-primary-foreground m-0 text-lg font-inter font-medium">
                  {offer.name}
                </h1>
              </div>
              <div>
                {OfferTag()}
              </div>
              <Popover
                content={OfferContent}
                open={visible}
                onOpenChange={setVisible}
                trigger="hover"
                overlayStyle={{ width: '437px' }}
              >
                <button className="text-grey-4">
                  <InfoCircleFilled />
                </button>
              </Popover>
            </div>

            <RefreshActionsButtons />
          </div>

          {linkOffer && (
            <ConversionTrackingModal
              offerTrackingType={linkOffer.conversionTrackingType}
              onCancel={hideInstructions}
              pixelCode={linkOffer.pixelCode}
              postbackUrl={linkOffer.postbackUrl}
              visible={instructionsVisible}
            />
          )}
          <OfferLogDrawer
            offerId={offer.id}
            onClose={() => setIsOfferLogDrawerVisible(false)}
            open={isOfferLogDrawerVisible}
            source={promoOffer ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE}
          />

          <OfferConversionHistoryDrawer
            offerId={offer.id}
            onClose={() => setIsOfferConversionHistoryDrawerVisible(false)}
            open={isOfferConversionHistoryDrawerVisible}
            source={isPromoLink ? OFFER_SOURCE.PROMO_LINK : promoOffer ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE}
          />
          <Modal
            title="Archive Offer"
            open={archiveOfferModal}
            onOk={handleArchiveOffer}
            confirmLoading={archiveOfferLoading}
            onCancel={() => setArchiveOfferModal(false)}
            okText="Archive"
          >
            <Text strong>Are you sure you want to archive this offer?</Text>
            <br />
            <Text>
              {`Please note that all active ${promoOffer ? 'codes' : 'links'} will be`}
              {' '}
              <strong>deactivated</strong>
              {' '}
              {`and moved to the archive. You can still access the analytics for these archived ${promoOffer ? 'codes' : 'links'
                } via the "Archived Offers" tab.`}
            </Text>
            <br />
            <br />
            <Text>Note: This action cannot be undone.</Text>
          </Modal>
        </>
      );
    }

    return (
      <>
        <Row justify="space-between" style={{ width: '100%' }} className={styles.offerDetailsNav}>
          <Row align="middle" gutter={8} className={infoButtonClassName ? styles.infoIconClass : ''}>
            {!hideOfferName && (
              <Row align="middle" gutter={8} className={`${showCta ? '' : styles.customColWidth}`}>
                <Col>
                  <OfferImage
                    className={styles.img}
                    imageUrl={offer?.imageUrl}
                    source={promoOffer ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE}
                  />
                </Col>
                <Col className={`${showCta ? styles.offerDetailsCard : styles.customOfferDetailsWidth}`}>
                  <Title level={4} style={{ whiteSpace: 'normal' }}>
                    {offer.name}
                  </Title>
                </Col>
                <Col>{OfferTag()}</Col>
              </Row>
            )}
            <Col className={styles.OfferDetailCardContainer}>
              <Popover
                content={OfferContent}
                open={visible}
                onOpenChange={setVisible}
                trigger="hover"
                overlayStyle={{ width: 437 }}
              >
                <Button icon={<InfoCircleFilled />} />
              </Popover>
            </Col>
          </Row>

          <ActionsButtons />
        </Row>
        {linkOffer && (
          <ConversionTrackingModal
            offerTrackingType={linkOffer.conversionTrackingType}
            onCancel={hideInstructions}
            pixelCode={linkOffer.pixelCode}
            postbackUrl={linkOffer.postbackUrl}
            visible={instructionsVisible}
          />
        )}
        <OfferLogDrawer
          offerId={offer.id}
          onClose={() => setIsOfferLogDrawerVisible(false)}
          open={isOfferLogDrawerVisible}
          source={promoOffer ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE}
        />

        <OfferConversionHistoryDrawer
          offerId={offer.id}
          onClose={() => setIsOfferConversionHistoryDrawerVisible(false)}
          open={isOfferConversionHistoryDrawerVisible}
          source={isPromoLink ? OFFER_SOURCE.PROMO_LINK : promoOffer ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE}
        />
        <Modal
          title="Archive Offer"
          open={archiveOfferModal}
          onOk={handleArchiveOffer}
          confirmLoading={archiveOfferLoading}
          onCancel={() => setArchiveOfferModal(false)}
          okText="Archive"
        >
          <Text strong>Are you sure you want to archive this offer?</Text>
          <br />
          <Text>
            {`Please note that all active ${promoOffer ? 'codes' : 'links'} will be`}
            {' '}
            <strong>deactivated</strong>
            {' '}
            {`and moved to the archive. You can still access the analytics for these archived ${
              promoOffer ? 'codes' : 'links'
            } via the "Archived Offers" tab.`}
          </Text>
          <br />
          <br />
          <Text>Note: This action cannot be undone.</Text>
        </Modal>
      </>
    );
  }
};
