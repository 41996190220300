import { Input } from '@frontend/shadcn/components/ui/input';
import { Label } from '@frontend/shadcn/components/ui/label';
import {
 Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { ColorPicker } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/shadColorPicker';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import React, { useRef } from 'react';

const typographyData = [
  { section: 'Headings', key: 'headings' },
  { section: 'Body', key: 'body' },
];

const fontOptions = [
  // Sans-serif fonts
  { value: 'arial', label: 'Arial' },
  { value: 'arial-black', label: 'Arial Black' },
  { value: 'helvetica', label: 'Helvetica' },
  { value: 'verdana', label: 'Verdana' },
  { value: 'tahoma', label: 'Tahoma' },
  { value: 'trebuchet-ms', label: 'Trebuchet MS' },
  { value: 'impact', label: 'Impact' },
  { value: 'normal', label: 'Normal' },

  // Serif fonts
  { value: 'times-new-roman', label: 'Times New Roman' },
  { value: 'georgia', label: 'Georgia' },
  { value: 'garamond', label: 'Garamond' },

  // Monospace fonts
  { value: 'courier-new', label: 'Courier New' },
  { value: 'consolas', label: 'Consolas' },

  // System fonts
  { value: 'segoe-ui', label: 'Segoe UI' },
  { value: 'roboto', label: 'Roboto' },
  { value: 'open-sans', label: 'Open Sans' },
];

const defaultTypographySettings = {
  headings: {
    fontStyle: 'arial',
    fontSize: 24, // numeric default
    fontColor: '#000000',
  },
  body: {
    fontStyle: 'arial',
    fontSize: 16, // numeric default
    fontColor: '#000000',
  },
};

export default function Typography(): JSX.Element {
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const colorPickerRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleFontChange = (sectionKey: string, value: string) => {
    setLandingPageData((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        typography: {
          ...prev.style?.typography,
          [sectionKey]: {
            ...(prev.style?.typography?.[sectionKey] || defaultTypographySettings[sectionKey]),
            fontStyle: value,
          },
        },
      },
    }));
  };

  const handleFontSizeChange = (sectionKey: string, value: string) => {
    // Convert to number and validate
    const numericValue = parseFloat(value) || defaultTypographySettings[sectionKey].fontSize;

    setLandingPageData((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        typography: {
          ...prev.style?.typography,
          [sectionKey]: {
            ...(prev.style?.typography?.[sectionKey] || defaultTypographySettings[sectionKey]),
            fontSize: numericValue,
          },
        },
      },
    }));
  };

  const handleColorChange = (sectionKey: string, color: string) => {
    setLandingPageData((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        typography: {
          ...prev.style?.typography,
          [sectionKey]: {
            ...(prev.style?.typography?.[sectionKey] || defaultTypographySettings[sectionKey]),
            fontColor: color,
          },
        },
      },
    }));
  };

  // Safe access to typography settings with fallback
  const getTypographySetting = (sectionKey: string, settingKey: string) => landingPageData.style?.typography?.[sectionKey]?.[settingKey]
           || defaultTypographySettings[sectionKey][settingKey];

  return (
    <div className="flex flex-col items-start gap-4 px-4">
      {typographyData.map((item, index) => (
        <div key={item.section} className="items-start gap-4 flex relative self-stretch w-full">
          <div className="flex flex-col w-[290px] items-start">
            <Label className="flex items-center gap-1 pb-2 text-neutralgrey-5 text-xs font-medium">
              {item.section}
            </Label>
            <Select
              value={getTypographySetting(item.key, 'fontStyle')}
              onValueChange={(value) => handleFontChange(item.key, value)}
            >
              <SelectTrigger className="w-full bg-neutralgrey-0 border-neutralgrey-2">
                <SelectValue placeholder="Select font" />
              </SelectTrigger>
              <SelectContent>
                {fontOptions.map((font) => (
                  <SelectItem key={font.value} value={font.value}>
                    {font.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col items-start flex-1">
            <Label className="flex items-center gap-1 pb-2 text-neutralgrey-5 text-xs font-medium whitespace-nowrap">Font Size</Label>
            <div className="flex items-center gap-1 px-3 py-[5px] relative self-stretch w-full bg-neutralgrey-0 rounded-lg border border-neutralgrey-2 min-w-[90px]">
              <Input
                type="number"
                value={getTypographySetting(item.key, 'fontSize')}
                onChange={(e) => handleFontSizeChange(item.key, e.target.value)}
                className="p-0 h-auto text-neutralgrey-6 border-none shadow-none focus:border-none focus-visible:ring-0 focus:outline-none"
              />
              <span className="text-neutralgrey-4 text-xs">px</span>
            </div>
          </div>
          <div className="flex flex-col items-start flex-1">
            <Label className="flex items-center gap-1 pb-2 text-neutralgrey-5 text-xs font-medium">
              Color
            </Label>
            <ColorPicker
              ref={(el) => (colorPickerRefs.current[index] = el)}
              value={getTypographySetting(item.key, 'fontColor')}
              onChange={(color) => handleColorChange(item.key, color)}
              className="items-center gap-2.5 pl-1 pr-2 py-1 bg-neutralgrey-0 rounded-lg border border-solid border-neutralgrey-2 flex relative self-stretch w-full"
            />
          </div>
        </div>
      ))}
    </div>
  );
}
