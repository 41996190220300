import * as React from 'react';
import cx from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Dropdown,
  Menu,
  Tooltip,
} from '@revfluence/fresh';
import {
  PlusOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  filter,
  first,
  isEmpty,
  map,
  size,
  some,
  sortBy,
  toLower,
  isNil,
} from 'lodash';
import {
  useHistory,
  useLocation,
  Link,
} from 'react-router-dom';

import { LazyImage, SpinnerIcon } from '@components';
import {
  EllipsisLabel,
} from '@frontend/app/components';
import { ProjectsRouteRoot } from '@frontend/app/containers/Projects/constants';
import { useResourceContext } from '@frontend/app/context';
import {
  useFeatureFlagVerbiage,
  useGetAllProjectsQuery,
  useInviteMembersToProgramsMutation,
  useModifyProgramMembersMutation,
  useGetProjectsForMembers,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import {
  GetAllProjectsQuery_projects as IProject,
} from '@frontend/app/queries/types/GetAllProjectsQuery';
import {
  ProgramMembershipStatusType,
} from '@frontend/app/types/MemberSearch';
import {
  MemberSearchQuery,
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import {
  GetProjectsForMembers_programs as IMemberProject,
} from '@frontend/app/queries/types/GetProjectsForMembers';
import { useAuth } from '@frontend/context/authContext';
import { useMessagingContext } from '@frontend/hooks';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';

import { ResourceType } from '@frontend/app/types/globalTypes';
import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';
import { ClientFeature } from '@frontend/app/constants';
import { SearchInput, useSearchWithDebounce } from '@frontend/app/components/SearchInput';
import styles from './StartProjectButton.scss';

const { SubMenu } = Menu;
const { useCallback } = React;

export enum ButtonActions {
  AddToProject = 'addToProject',
  InviteToProject = 'inviteToProject',
}

const SCOPES_SEPARATOR = ',';
const OUTREACH_SCOPES = ['creator_marketplace', 'pages_messaging', 'instagram_manage_messages', 'pages_manage_metadata'];
const INVITE_TOOLTIP_TEXT = 'We will send an email and invite these people to apply to your project.';
const INVITE_BY_DM_MISSING_PERMISSIONS = 'All invites will be sent through email. The connected account is not eligible for Instagram Creator Marketplace';
const INVITE_BY_DM_TOOLTIP_TEXT = 'Creators that can receive direct messages will get all invites via Instagram DM. All others will receive invites via email.';
const ADD_TOOLTIP_TEXT = 'Bypass the invitation and screening process and add people directly into the project';
const INVITE_DISABLED_PROJECT_NOT_PUBLISHED = 'You cannot invite anyone to this project because the project\'s application page is not published';
const INVITE_MISSING_DM_TOOLTIP = (
  <div>
    All invites will be sent through email. To use direct messaging for sending invitations to eligible creators, please click
    {' '}
    <Link to={`/settings/${SOCIAL_POST_APP_ID}`}>
      here
    </Link>
    {' '}
    to select an Instagram account
  </div>
);
interface IProps {
  className?: string;
  disabled?: boolean;
  searchQuery: MemberSearchQuery;
  selectedMembers?: IMember[];
  memberCount?: number;
}

const ERROR_TOAST_DURATION_MS = 4000;

export const StartProjectButton: React.FC<IProps> = React.memo(({
  className,
  disabled = false,
  selectedMembers,
  searchQuery,
  memberCount,
}) => {
  const [selectedMembersCount, setselectedMembersCount] = useState(0);
  const [projectId, setProjectId] = useState(0);
  const [isInviting, setIsInviting] = useState(false);
  const [isAddingToProject, setIsAddingToProject] = useState(false);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const showInstagramMeta = useClientFeatureEnabled(ClientFeature.SHOW_INSTAGRAM_META);
  const isInstagramDMInviteEnabled = useClientFeatureEnabled(ClientFeature.INSTAGRAM_DM);
  const history = useHistory();
  const location = useLocation();
  const verbiage = useFeatureFlagVerbiage();
  const { user, clientInfo } = useAuth();
  const { activeEmailResources: emailResources, activeMessageResources: messagingResources } = useResourceContext();
  const firstResource = first(emailResources);

  const instagramDMAccount = useMemo(() => (
    first(filter(messagingResources, (account) => account.type === ResourceType.IGDM))
  ), [messagingResources]);

  const hasValidIGDMResource = useMemo(() => {
    if (isNil(instagramDMAccount)) return false;

    const scopes = new Set<string>(instagramDMAccount.scopes.split(SCOPES_SEPARATOR));
    let isValid = true;
    OUTREACH_SCOPES.forEach((importantScope) => {
      if (!scopes.has(importantScope)) {
        isValid = false;
      }
    });
    return isValid;
  }, [instagramDMAccount]);

  const selectedMemberIds = useMemo(
    () => map(selectedMembers, (member) => member.id),
    [selectedMembers],
  );

  const {
    data: {
      projects = undefined,
    } = {},
    loading: isProjectsLoading,
  } = useGetAllProjectsQuery({
    variables: {
      withSpecsOnly: true,
    },
  });

  const {
    data: {
      programs: projectsForMembers,
    } = {},
    refetch: refetchProjectsForMembers,
  } = useGetProjectsForMembers({
    variables: {
      memberIds: selectedMemberIds,
      status: ProgramMembershipStatusType.APPROVED,
    },
    skip: isEmpty(selectedMemberIds),
  });

  const {
    showMessage,
    showError,
  } = useMessagingContext();

  const addEvent = useEventContext();
  const handleCreateProjectClicked = useCallback(() => {
    addEvent(EventName.StartCreateNewProject, {
      source: 'member_list',
    });
    history.push({
      ...location,
      pathname: `${ProjectsRouteRoot}/new/templates`,
      search: undefined,
    });
  }, [addEvent, history, location]);

  const [addMembersToPrograms] = useModifyProgramMembersMutation('add', {
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${selectedMembersCount > 1 ? `${selectedMembersCount} Members are` : `${selectedMembersCount} Member is`} being added to your ${verbiage.program}, feel free to continue working`,
      });
      setIsAddingToProject(false);
      setselectedMembersCount(0);
    },
    onError(err) {
      setIsAddingToProject(false);
      setselectedMembersCount(0);
      showError(err, ERROR_TOAST_DURATION_MS);
    },
  });

  const {
    inviteMembersToPrograms,
  } = useInviteMembersToProgramsMutation({
    onCompleted() {
      const membersProjectApproval = map(
        projectsForMembers,
        (projectsForMember: IMemberProject[]) => some(
          projectsForMember,
          (project: IMemberProject) => project.id === projectId,
        ),
      );
      const invitedMembersCount = size(filter(
        membersProjectApproval,
        (memberApproval) => memberApproval === false,
      ));
      const skippedMembers = filter(
        selectedMembers,
        (_member, i) => membersProjectApproval[i] === true,
      );

      let successMessage;
      if (size(skippedMembers) === 0) {
        successMessage = `${selectedMembersCount > 1 ? `${selectedMembersCount} Members are` : `${selectedMembersCount} Member is`} being invited to apply to your ${verbiage.program}, feel free to continue working`;
      } else {
        const skippedMemberNames = map(skippedMembers, (member) => member.name).join(', ');
        if (selectedMembersCount === 1) {
          successMessage = 'invite was skipped since member is already in this project';
        } else {
          const pluralInvites = invitedMembersCount > 1;
          const pluralSkipped = size(skippedMembers) > 1;
          successMessage = [
            `${invitedMembersCount} invite${pluralInvites ? 's' : ''} ${pluralInvites ? 'were' : 'was'} sent, `,
            `member${pluralSkipped ? 's' : ''} ${skippedMemberNames} ${pluralSkipped ? 'were' : 'was'} skipped since ${pluralSkipped ? 'they' : 'member'} ${pluralSkipped ? 'are' : 'is'} already in this project`,
          ].join('');
        }
      }

      showMessage({
        type: 'success',
        content: successMessage,
        duration: 5000,
      });
      setIsInviting(false);
      setselectedMembersCount(0);
    },
    onError(err) {
      setIsInviting(false);
      setselectedMembersCount(0);
      showError(err, ERROR_TOAST_DURATION_MS);
    },
  });

  /**
   * Menu
   */
  const handleMenuClick = useCallback(async ({ key, keyPath }) => {
    const programId = parseInt(keyPath[1], 10);
    setProjectId(programId);
    switch (key) {
      case `${ButtonActions.AddToProject}_${programId}`:
        setIsAddingToProject(true);
        setselectedMembersCount(size(selectedMemberIds));
        addMembersToPrograms({
          variables: {
            query: searchQuery,
            memberIds: selectedMemberIds,
            programIds: [programId],
            status: 'approved',
            clientId: clientInfo?.id,
          },
        });
        break;
      case `${ButtonActions.InviteToProject}_${programId}`:
        setIsInviting(true);
        setselectedMembersCount(size(selectedMemberIds));
        await refetchProjectsForMembers();
        inviteMembersToPrograms({
          variables: {
            memberIds: selectedMemberIds,
            programIds: [programId],
            userId: user.sub,
            resourceId: firstResource?.id,
            query: searchQuery,
            source: 'programs_list',
          },
        });
        break;
    }
  }, [
    setProjectId,
    setIsAddingToProject,
    setselectedMembersCount,
    selectedMemberIds,
    addMembersToPrograms,
    searchQuery,
    clientInfo,
    setIsInviting,
    refetchProjectsForMembers,
    inviteMembersToPrograms,
    user.sub,
    firstResource,
  ]);

  const activeProjects = useMemo(() => {
    if (isArchiveProjectEnabled) {
      return filter(projects, (project) => project.status === ProjectStatus.Active);
    }
    return projects;
  }, [
    projects,
    isArchiveProjectEnabled,
  ]);

  const sortedProjects = sortBy(activeProjects, (project) => toLower(project.title));

  const inviteTooltipText = useCallback((project: IProject): string | JSX.Element => {
    if (!project.published) {
      return INVITE_DISABLED_PROJECT_NOT_PUBLISHED;
    }
    if (isInstagramDMInviteEnabled || showInstagramMeta) {
      if (isNil(instagramDMAccount)) {
        return INVITE_MISSING_DM_TOOLTIP;
      }
      if (!hasValidIGDMResource) {
        return INVITE_BY_DM_MISSING_PERMISSIONS;
      }

      return INVITE_BY_DM_TOOLTIP_TEXT;
    }
    return INVITE_TOOLTIP_TEXT;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstagramDMInviteEnabled, showInstagramMeta, hasValidIGDMResource]);

  const {
    searchText,
    inputValue,
    handleSearchChange,
    error,
    checkNoResults,
    isLoading: isSearchLoading,
  } = useSearchWithDebounce({
    searchAnalytics: {
      enabled: true,
      searchContext: 'projects',
      metadata: {
        source: 'collaborate_button',
      },
    },
  });

  const filteredProjects = useMemo(() => {
    if (!searchText) {
      return sortedProjects;
    }
    return sortedProjects.filter((project) =>
      project.title?.toLowerCase().includes(searchText.toLowerCase()));
  }, [sortedProjects, searchText]);

  const renderSearchSection = useCallback(() => (
    <div className={styles.searchSection}>
      <SearchInput
        value={inputValue}
        onChange={handleSearchChange}
        placeholder="Search projects..."
        aria-invalid={!!error}
        aria-errormessage={error ? 'project-search-error' : undefined}
        isLoading={isSearchLoading}
      />
    </div>
  ), [inputValue, handleSearchChange, error, isSearchLoading]);

  useEffect(() => {
    checkNoResults(filteredProjects.length > 0);
  }, [filteredProjects.length, checkNoResults]);

  const searchSection = React.useMemo(
    () => renderSearchSection(),
    [renderSearchSection],
  );

  const menuElem = React.useMemo(
    () => (
      <Menu
        className={styles.menu}
        onClick={handleMenuClick}
      >
        {searchSection}
        <div className={styles.divider} />
        {error ? (
          <div className={styles.searchError}>{error}</div>
        ) : (
          <>
            {map(filteredProjects, (project: IProject) => (
              <SubMenu
                key={project.id}
                title={(
                  <div className={styles.label}>
                    <LazyImage
                      className={styles.thumb}
                      src={project.splashImageUrl}
                    />
                    <EllipsisLabel showTooltip>
                      {project.title}
                    </EllipsisLabel>
                  </div>
                )}
              >
                <Menu.Item
                  disabled={!project.published}
                  key={`${ButtonActions.InviteToProject}_${project.id}`}
                >
                  <div
                    onClick={(event) => {
                      const { target } = event;
                      if (target instanceof HTMLElement && target.className !== 'action ant-tooltip-open') {
                        event.stopPropagation();
                      }
                  }}
                  >
                    <Tooltip
                      title={inviteTooltipText(project)}
                      placement="right"
                    >
                      <div className="action">
                        Send
                        {' '}
                        {selectedMemberIds?.length}
                        {' '}
                        Project Invitation
                        {size(selectedMemberIds) > 1 ? 's' : ''}
                      </div>
                    </Tooltip>
                  </div>
                </Menu.Item>
                <Menu.Item key={`${ButtonActions.AddToProject}_${project.id}`}>
                  <div
                    onClick={(event) => {
                      const { target } = event;
                      if (target instanceof HTMLElement && target.className !== 'action ant-tooltip-open') {
                        event.stopPropagation();
                      }
                    }}
                  >
                    <Tooltip
                      title={
                      ADD_TOOLTIP_TEXT
}
                      placement="right"
                    >
                      <div className="action">
                        Add
                        {' '}
                        {selectedMemberIds.length}
                        {' '}
                        to Project
                      </div>
                    </Tooltip>
                  </div>
                </Menu.Item>
              </SubMenu>
            ))}
          </>
        )}
        <Menu.Item
          key="CREATE_BUTTON"
          className={styles.createProjectItem}
          onClick={handleCreateProjectClicked}
        >
          <PlusOutlined className={styles.icon} />
          {' '}
          Create New Project
        </Menu.Item>
      </Menu>
    ),
    [
      filteredProjects,
      handleMenuClick,
      handleCreateProjectClicked,
      inviteTooltipText,
      selectedMemberIds,
      searchSection,
      error,
    ],
  );

  const isDisabled = React.useMemo(() => (
    disabled
      || isProjectsLoading
      || size(selectedMemberIds) === 0
      || isInviting
      || isAddingToProject
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [
      disabled,
      isProjectsLoading,
      selectedMemberIds.length,
      isInviting,
      isAddingToProject,
  ]);

  const isLoading = React.useMemo(() => (
    isInviting
    || isAddingToProject
  ), [isInviting, isAddingToProject]);

  return (
    <Dropdown
      disabled={isDisabled}
      overlay={menuElem}
      overlayClassName={styles.overlay}
      placement="bottomLeft"
      trigger={['click']}
    >
      <Button
        className={cx(styles.StartProjectButton, className)}
        disabled={isDisabled}
        type="primary"
        onClick={() => {
          addEvent(EventName.AttemptBulkAction, {
            action: 'add_to_project',
            member_count: size(selectedMemberIds) > 0 ? size(selectedMemberIds) : memberCount,
          });
        }}
      >
        {!projects?.length ? 'Start a Project' : 'Collaborate'}

        {
          !isLoading ? (
            <DownOutlined
              className={styles.icon}
              size={16}
            />
          ) : (
            <SpinnerIcon
              className={styles.icon}
              size={16}
            />
          )
        }
      </Button>
    </Dropdown>
  );
});
