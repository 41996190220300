import React, { useState } from 'react';
import { ImageIcon, Plus, Trash } from 'lucide-react';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ApolloProvider } from '@apollo/client';
import { FindAndImportProvider } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImportContext';
import { useProductsContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { useAppContext } from '@frontend/context/AppContext';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { message } from 'antd';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { ExportProductModal } from './ExportProductModal';

const MAX_PRODUCTS_LIMIT = 12;

type ProductDetails = {
  description?: string;
  id: string;
  imgUrl?: string;
  imageUrl?: string;
  title: string;
};

const ManageAllProductsContent: React.FC = () => {
  const { pfaApolloClient } = useAppContext();
  const { isFindAndImportOpen, setIsFindAndImportOpen } = useProductsContext();
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);

  // Initialize selectedProducts from landingPageData (if available)
  const [selectedProducts, setSelectedProducts] = useState<ProductDetails[]>(
    landingPageData?.products?.allProducts?.products || [],
  );

  const creatorLimit = landingPageData.products.setting.noOfProductsCreatorCanAdd || 0;
  const isCreatorAddEnabled = landingPageData.products.setting.allowCreatorToAddProducts;

  const calculateRemainingSlots = () => {
    const reservedForCreators = isCreatorAddEnabled ? creatorLimit : 0;
    const maxAdminSlots = MAX_PRODUCTS_LIMIT - reservedForCreators;
    return maxAdminSlots - selectedProducts.length;
  };

  const remainingSlots = calculateRemainingSlots();
  const isAddDisabled = remainingSlots <= 0;

  const getTooltipMessage = () => {
    if (isCreatorAddEnabled) {
      return `${creatorLimit} slots are reserved for creators. You can only add up to ${MAX_PRODUCTS_LIMIT - creatorLimit} products.`;
    }
    return `Maximum product limit of ${MAX_PRODUCTS_LIMIT} reached.`;
  };

  const handleAddProduct = () => {
    if (selectedProducts.length >= MAX_PRODUCTS_LIMIT) {
      message.error(`You can only add up to ${MAX_PRODUCTS_LIMIT} products.`);
      return;
    }
    setIsLocalModalOpen(true);
    setIsFindAndImportOpen(true);
  };

  const handleCloseModal = () => {
    setIsLocalModalOpen(false);
    setIsFindAndImportOpen(false);
  };

  const handleRemoveProduct = (productId: string) => {
    const updatedProducts = selectedProducts.filter((p) => p.id !== productId);

    // Update local state
    setSelectedProducts(updatedProducts);

    // Update context
    setLandingPageData((prev) => ({
      ...prev,
      products: {
        ...prev.products,
        allProducts: {
          ...prev.products.allProducts,
          products: updatedProducts,
        },
      },
    }));
  };

  const getImageSrc = (product: ProductDetails) => product.imgUrl || product.imageUrl || '';

  const AddProductButton = () => (
    <Button
      variant="default"
      onClick={handleAddProduct}
      disabled={isAddDisabled}
      className={isAddDisabled ? 'opacity-50 cursor-not-allowed' : ''}
    >
      <Plus className="h-3 w-3 mr-2" />
      Add Product
    </Button>
  );

  return (
    <div className="flex flex-col items-start gap-4 px-4">
      <p className="text-sm text-neutralgrey-5 font-medium">
        You can add up to
        {' '}
        {MAX_PRODUCTS_LIMIT - creatorLimit}
        {' '}
        products
        {isCreatorAddEnabled && ` (${creatorLimit} slots reserved for creators)`}
        .
      </p>
      {selectedProducts.length > 0 && (
        <div className="w-full space-y-3">
          {selectedProducts.map((product) => (
            <div
              key={product.id}
              className="flex items-center justify-between p-3 border rounded-lg"
            >
              <div className="flex items-center gap-3">
                {getImageSrc(product) && (
                  <img
                    src={getImageSrc(product)}
                    alt={product.title}
                    className="w-12 h-12 object-cover rounded"
                  />
                )}
                {!getImageSrc(product) && <ImageIcon className="w-12 h-12 text-neutralgrey-5" />}
                <div>
                  <h3 className="font-medium">{product.title}</h3>
                </div>
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleRemoveProduct(product.id)}
                className="border border-neutralgrey-6 rounded-lg"
              >
                <Trash className="h-3 w-3" />
              </Button>
            </div>
          ))}
        </div>
      )}

      {isAddDisabled ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span>
                <AddProductButton />
              </span>
            </TooltipTrigger>
            <TooltipContent>
              <p>{getTooltipMessage()}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <AddProductButton />
      )}

      {isFindAndImportOpen && isLocalModalOpen && (
        <ApolloProvider client={pfaApolloClient}>
          <FindAndImportProvider>
            <ExportProductModal
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              onClose={handleCloseModal}
            />
          </FindAndImportProvider>
        </ApolloProvider>
      )}
    </div>
  );
};

export default ManageAllProductsContent;
