import * as React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateRange } from 'react-day-picker';
import dayjs from 'dayjs';
import { calculateDatesFromType } from '../../hooks/useAnalyticsParams';
import { DateType } from '../../types';
import { dateTypes, dateRangeSchema, DateRangeFormData } from './date-range-form.types';

interface UseDateRangeFormProps {
  defaultValues?: DateRangeFormData;
  onApply: (values: DateRangeFormData) => void;
}

/**
 * Custom hook that manages the state and logic for the DateRangeForm component.
 *
 * Features:
 * - Manages dropdown and calendar open states
 * - Handles form state using react-hook-form with zod schema validation
 * - Provides handlers for preset selection and calendar date changes
 * - Automatically submits form when valid selections are made
 * - Supports both preset date ranges and custom date selections
 * - Handles partial date selections without triggering submission
 *
 * Behavior:
 * - Initializes with 'thisWeek' as default date type if no defaults provided
 * - Automatically closes dropdowns when valid selections are made
 * - Switches to 'custom' type when manual date range is selected
 * - Only triggers onApply callback when both start and end dates are valid
 * - Maintains UI state for both main dropdown and calendar popover
 *
 * @param defaultValues - Initial values for the date type and range (optional)
 * @param onApply - Callback function called when valid date selections are made
 *
 * @returns {Object} Hook state and handlers
 * @returns {boolean} open - Main dropdown open state
 * @returns {function} setOpen - Function to control main dropdown state
 * @returns {boolean} calendarOpen - Calendar popover open state
 * @returns {string} dateType - Current selected date type (preset or 'custom')
 * @returns {DateRange} dateRange - Current selected date range
 * @returns {function} handleOptionClick - Handler for preset option selection
 * @returns {function} handleDateRangeSelect - Handler for calendar date selection
 * @returns {function} handleCalendarOpenChange - Handler for calendar popover state
 */
export function useDateRangeForm({ defaultValues, onApply }: UseDateRangeFormProps) {
  // UI state for dropdowns
  const [open, setOpen] = React.useState(false);
  const [calendarOpen, setCalendarOpen] = React.useState(false);

  // Initialize form with validation
  const form = useForm<DateRangeFormData>({
    resolver: zodResolver(dateRangeSchema),
    defaultValues: {
      dateType: defaultValues?.dateType || 'thisWeek',
      dateRange: defaultValues?.dateRange,
    },
  });

  // Watch form values for UI updates
  const dateType = form.watch('dateType');
  const dateRange = form.watch('dateRange');

  // Handler for form submission
  const handleSubmit = React.useCallback(
    (data: DateRangeFormData) => {
      onApply(data);
    },
    [onApply],
  );

  // Handler for preset option selection
  const handleOptionClick = React.useCallback(
    (value: typeof dateTypes[number]) => {
      form.setValue('dateType', value);

      // Calculate date range based on selected preset
      const { startDate, endDate } = calculateDatesFromType(value as DateType);
      const newRange: DateRangeFormData['dateRange'] = {
        from: startDate,
        to: endDate,
      };
      form.setValue('dateRange', newRange);
      handleSubmit(form.getValues());
      setOpen(false);
    },
    [form, handleSubmit],
  );

  // Handler for calendar date selection
  const handleDateRangeSelect = React.useCallback(
    (range: DateRange | undefined) => {
      if (!range) {
        form.setValue('dateRange', undefined);
        return;
      }

      // Format selected dates using dayjs
      const newRange: DateRangeFormData['dateRange'] = {
        from: range.from ? dayjs(range.from).format('YYYY-MM-DD') : undefined,
        to: range.to ? dayjs(range.to).format('YYYY-MM-DD') : undefined,
      };

      // Set type to custom and update date range
      form.setValue('dateType', 'custom');
      form.setValue('dateRange', newRange);

      // Submit if both dates are selected
      if (range.from && range.to) {
        handleSubmit(form.getValues());
      }
    },
    [form, handleSubmit],
  );

  // Handler for calendar dropdown state
  const handleCalendarOpenChange = React.useCallback(
    (isOpen: boolean) => {
      setCalendarOpen(isOpen);
      // Close main dropdown when calendar is closed with valid selection
      if (!isOpen && dateRange?.from && dateRange?.to) {
        setOpen(false);
      }
    },
    [dateRange],
  );

  return {
    open,
    setOpen,
    calendarOpen,
    dateType,
    dateRange,
    handleOptionClick,
    handleDateRangeSelect,
    handleCalendarOpenChange,
  };
}
