import { IMemberOrderData } from './PFAOrder';

export interface IProgramOffer {
  programId: number;
  offerId: number;
  isPromoLink: boolean;
  offerLinkId: number | null;
  offerPromoId: number | null;
}

export interface IOfferLinkData {
  member_id: number;
  link: string;
  clicks: number;
  conversions: number;
  clickRate: number;
  cpc: number;
  cps: number;
  payout: number;
  lastPayoutDate: Date;
}

export interface IOfferPromoData {
  member_id: number;
  promoCode: string;
  conversions: number;
  cps: number;
  payout: number;
  lastPayoutDate: Date;
}

export interface IProgramMemberOfferData {
  [memberId: number]: {
    promo: IOfferPromoData[];
    link: IOfferLinkData[];
  };
}

export interface IProgramMemberOrderData {
  [memberId: number]: IMemberOrderData[];
}

export interface IMemberBrief {
  memberId: number;
  briefId: number;
  briefIterationId: number;
  programId: number;
  status: string;
  deliverables: number;
  payment: number;
  lastReviewer: string;
  usageRights: string;
  createdDate: Date;
  updatedDate: Date;
  agreedDate: Date;
}

export interface IProgramMemberBriefData {
  [memberId: number]: IMemberBrief[];
}

export interface IDeliverableContent {
  memberId: number;
  programId: number;
  briefId: number;
  briefIterationId: number;
  deliverableId: number;
  deliverableType: string;
  status: string;
  lastReviewer: string;
  nextDueDate: Date;
  lastActivityDate: Date;
  postDate: Date;
  adsCode: string;
  liveLink: string;
  socialNetwork: string;
  isGCR: boolean;
  contentReviewStatus: string;
  contentReviewInternalStatus: string;
  contentInfo: any;
}

export interface IMemberDeliverable {
  memberId: number;
  status: string;
  lastReviewer: string;
  nextDueDate: Date;
  lastActivityDate: Date;
  postDate: Date;
  liveLink: string;
  socialNetwork: string;
}

export interface IProgramMemberDeliverableData {
  [memberId: number]: IMemberDeliverable[];
}
