import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { ColorPicker } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/shadColorPicker';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import React, { useRef } from 'react';
import { Label } from '@frontend/shadcn/components/ui/label';

const colorPickers = [
  { id: 1, label: 'Page Background Color', key: 'pageBackgroundColor' },
  { id: 2, label: 'Hero Section Background', key: 'heroSectionBackgroundColor' },
];

const defaultBackgroundSettings = {
  pageBackgroundColor: '#232323',
  heroSectionBackgroundColor: '#FFFFFF',
};

const PageBackground = (): JSX.Element => {
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const colorPickerRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleColorChange = (key: string, color: string) => {
    setLandingPageData((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        backgroundSetting: {
          ...(prev.style?.backgroundSetting || defaultBackgroundSettings),
          [key]: color,
        },
      },
    }));
  };

  // Safe access to background settings with fallback
  const getBackgroundColor = (key: string) => landingPageData.style?.backgroundSetting?.[key] || defaultBackgroundSettings[key];

  return (
    <div className="flex flex-col gap-4 px-4">
      {colorPickers.map((picker, index) => (
        <Card key={picker.id} className="w-full border-none">
          <CardContent className="pt-4 p-2">
            <div className="flex flex-col gap-2">
              <Label className="text-sm font-medium">{picker.label}</Label>
              <ColorPicker
                ref={(el) => (colorPickerRefs.current[index] = el)}
                value={getBackgroundColor(picker.key)}
                onChange={(color) => handleColorChange(picker.key, color)}
                className="inline-flex items-center gap-2.5 pl-1 pr-2 py-1 bg-neutralgrey-0 rounded-lg border border-neutralgrey-2"
              />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default PageBackground;
