import { Button } from '@frontend/shadcn/components/ui/button';
import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import {
 Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle,
} from '@frontend/shadcn/components/ui/dialog';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Label } from '@frontend/shadcn/components/ui/label';
import { Separator } from '@frontend/shadcn/components/ui/separator';
import { ImageIcon, Loader2, Video } from 'lucide-react';
import {
 InstagramEmbed, YouTubeEmbed, TikTokEmbed, FacebookEmbed, PlaceholderEmbed, PinterestEmbed,
} from 'react-social-media-embed';
import React from 'react';
import {
  FeaturedContentTypeEnum,
  LandingPageData,
  useLandingPageContext,
} from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { RadioGroup, RadioGroupItem } from '@frontend/shadcn/components/ui/radio-group';
import { cn } from '@frontend/shadcn/lib/utils';
import { detectContentType } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/contentUtils';

export enum ContentTypeEnum {
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
  FACEBOOK = 'facebook',
  PINTEREST = 'pinterest',
  IMAGE = 'image',
  GOOGLE_DRIVE = 'googleDrive',
}

const errorMessages = {
  urlRequired: 'URL is required',
  invalidUrl: 'Please enter a valid URL',
  missingContentType: 'Please select a content type',
  invalidImage: 'Please enter a valid image URL',
  invalidVideo: 'Please enter a valid video URL',
} as const;

interface ContentSelectionDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

type ContentType = ContentTypeEnum | null;

type ApiContentType = 'image' | 'video' | undefined;

const urlPatterns = {
  [ContentTypeEnum.INSTAGRAM]: /instagram\.com\/(p|reel)\/[\w-]+/,
  [ContentTypeEnum.YOUTUBE]: /(?:youtube\.com\/(?:shorts\/|[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
  [ContentTypeEnum.TIKTOK]: /tiktok\.com\/@[\w.-]+\/video\/\d+/,
  [ContentTypeEnum.FACEBOOK]: /facebook\.com\/[\w.]+\/(?:posts|videos)\/[\w-]+/,
  [ContentTypeEnum.PINTEREST]: /pinterest\.com\/pin\/[\w-]+/,
  [ContentTypeEnum.IMAGE]: /\.(jpg|jpeg|png|gif|webp)(\?.*)?$/i,
  [ContentTypeEnum.GOOGLE_DRIVE]: /drive\.google\.com\/file\/d\/([\w-]+)\/view/,
};

const formData = {
  title: 'Select Featured Content',
  submitLabel: 'Submit Content Link',
  previewLabel: 'Preview',
  previewText: 'Enter content URL to see preview',
};

interface ContentSelectionDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ContentSelectionDialog({ isOpen, onClose }: ContentSelectionDialogProps): JSX.Element {
  const [url, setUrl] = React.useState('');
  const [contentType, setContentType] = React.useState<ContentType>(null);
  const [apiContentType, setApiContentType] = React.useState<ApiContentType>('image');
  const [isLoading, setIsLoading] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);
  const { setLandingPageData } = useLandingPageContext();
  const [errors, setErrors] = React.useState<{
    url?: string;
    contentType?: string;
  }>({});

  // Add useEffect to clear state when dialog is opened
  React.useEffect(() => {
    if (isOpen) {
      setUrl('');
      setContentType(null);
      setApiContentType('image'); // Set default to image
      setIsLoading(false);
      setImageError(false);
      setErrors({});
    }
  }, [isOpen]);

  const validateUrl = (url: string): boolean => {
    try {
      return Boolean(new URL(url));
    } catch {
      return false;
    }
  };

  const validateContentTypeMatch = (url: string, apiType: ApiContentType): boolean => {
    if (!url || !apiType) return true;

    const detectedType = detectContentType(url);

    if (apiType === 'image') {
      if (detectedType === 'instagram') {
        return url.includes('/p/') || url.includes('/post/');
      }
      return detectedType === 'image' || detectedType === 'googleDrive';
    }

    // For video type, don't allow Instagram posts
    if (detectedType === 'instagram') {
      return !(url.includes('/p/') || url.includes('/post/'));
    }
    return ['youtube', 'instagram', 'tiktok', 'facebook', 'pinterest'].includes(detectedType || '');
  };

  const handleAddContent = () => {
    const newErrors: typeof errors = {};

    if (!url) {
      newErrors.url = errorMessages.urlRequired;
    } else if (!validateUrl(url)) {
      newErrors.url = errorMessages.invalidUrl;
    }

    if (!apiContentType) {
      newErrors.contentType = errorMessages.missingContentType;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLandingPageData((prevData: LandingPageData) => ({
      ...prevData,
      featuredContent: {
        ...prevData.featuredContent,
        urls: [
          ...(prevData.featuredContent?.urls || []),
          {
            type: apiContentType === 'image' ? FeaturedContentTypeEnum.IMAGE : FeaturedContentTypeEnum.VIDEO,
            src: url,
          },
        ],
        settings: {
          ...prevData.featuredContent?.settings,
        },
      },
    }));
    setUrl('');
    onClose();
    setContentType(null);
    setApiContentType('image');
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = event.target.value;
    setUrl(newUrl);
    setImageError(false);
    setErrors({});

    if (newUrl) {
      setIsLoading(true);

      if (!validateUrl(newUrl)) {
        setErrors((prev) => ({ ...prev, url: errorMessages.invalidUrl }));
        setIsLoading(false);
        return;
      }

      const type = detectContentType(newUrl);
      setContentType(type);

      if (apiContentType && !validateContentTypeMatch(newUrl, apiContentType)) {
        setErrors((prev) => ({
          ...prev,
          url: apiContentType === 'image' ? errorMessages.invalidImage : errorMessages.invalidVideo,
        }));
      }

      setTimeout(() => setIsLoading(false), 1000);
    } else {
      setContentType(null);
      setIsLoading(false);
    }
  };

  const handleApiContentTypeChange = (value: string) => {
    const newApiContentType = value as ApiContentType;
    setApiContentType(newApiContentType);
    setImageError(false);
    setErrors({});

    if (url) {
      if (!validateContentTypeMatch(url, newApiContentType)) {
        setErrors({
          url: newApiContentType === 'image' ? errorMessages.invalidImage : errorMessages.invalidVideo,
        });
      }
    }
  };

  const handleImageError = () => {
    setImageError(true);
    setContentType(null);
  };

  const renderEmbed = () => {
    const embedProps = {
      url,
      width: 300,
      height: 400,
      style: {
        transform: 'scale(0.8)',
        transformOrigin: 'center center',
      },
    };

    if (isLoading) {
      return <PlaceholderEmbed {...embedProps} />;
    }

    switch (contentType) {
      case ContentTypeEnum.INSTAGRAM:
        return (
          <div className="w-[300px] h-[400px] flex items-center justify-center overflow-hidden">
            <InstagramEmbed {...embedProps} captioned={false} />
          </div>
        );
      case ContentTypeEnum.YOUTUBE:
        return (
          <div className="w-[300px] h-[400px] flex items-center justify-center overflow-hidden">
            <YouTubeEmbed {...embedProps} />
          </div>
        );
      case ContentTypeEnum.TIKTOK:
        return (
          <div className="w-[300px] h-[400px] flex items-center justify-center overflow-hidden">
            <TikTokEmbed {...embedProps} />
          </div>
        );
      case ContentTypeEnum.FACEBOOK:
        return (
          <div className="w-[300px] h-[400px] flex items-center justify-center overflow-hidden">
            <FacebookEmbed {...embedProps} />
          </div>
        );
      case ContentTypeEnum.PINTEREST:
        return (
          <div className="w-[300px] h-[400px] flex items-center justify-center overflow-hidden">
            <PinterestEmbed {...embedProps} />
          </div>
        );
      case ContentTypeEnum.IMAGE:
        return (
          <div className="w-[300px] h-[400px] flex items-center justify-center overflow-hidden">
            <img
              src={url}
              alt="Content preview"
              className="max-w-full max-h-full object-contain"
              onError={handleImageError}
            />
          </div>
        );
      case ContentTypeEnum.GOOGLE_DRIVE:
        const fileId = url.match(urlPatterns[ContentTypeEnum.GOOGLE_DRIVE])?.[1];
        const googleDriveUrl = `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
        return (
          <div className="w-[300px] h-[400px] flex items-center justify-center overflow-hidden">
            <img
              src={googleDriveUrl}
              alt="Google Drive Content preview"
              className="max-w-full max-h-full object-contain"
              onError={handleImageError}
            />
          </div>
        );
      default:
        return (
          <div className="w-[300px] h-[400px] flex flex-col items-center justify-center gap-2">
            <div className="rounded-lg bg-[#ffffff99] p-1.5">
              <Video className="w-6 h-6 text-neutralgrey-4" />
            </div>
            <span className="text-sm font-medium text-neutralgrey-4">
              {imageError ? 'Invalid image URL' : formData.previewText}
            </span>
          </div>
        );
    }
  };

  const isAddButtonDisabled = React.useMemo(() => {
    if (!url || !apiContentType || imageError) return true;
    if (Object.keys(errors).length > 0) return true;

    return !validateContentTypeMatch(url, apiContentType);
  }, [url, apiContentType, imageError, errors]);

  const handleClose = () => {
    setUrl('');
    setContentType(null);
    setApiContentType('image');
    setIsLoading(false);
    setImageError(false);
    setErrors({});
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="w-[520px] p-0">
        <DialogHeader className="px-2 pt-6">
          <DialogTitle className="text-[20px] font-medium text-neutralgrey-6 mb-0">{formData.title}</DialogTitle>
        </DialogHeader>
        <Separator className="p-0" />
        <div className="flex flex-col gap-6 px-4">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label className="text-xs font-medium text-neutralgrey-6">Select Content Type</Label>
              <RadioGroup
                value={apiContentType}
                onValueChange={handleApiContentTypeChange}
                className="flex gap-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="image" id="image" />
                  <Label htmlFor="image" className="flex items-center gap-2">
                    <ImageIcon className="h-4 w-4" />
                    <span>Image</span>
                  </Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="video" id="video" />
                  <Label htmlFor="video" className="flex items-center gap-2">
                    <Video className="h-4 w-4" />
                    <span>Video</span>
                  </Label>
                </div>
              </RadioGroup>
              {errors.contentType && (
                <p className="text-xs text-red-500 mt-1">{errors.contentType}</p>
              )}
            </div>

            <div className="space-y-2">
              <Label className="text-xs font-medium text-neutralgrey-6">{formData.submitLabel}</Label>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2 py-1">
                  <Input
                    placeholder="Enter content URL (social media or image)"
                    value={url}
                    onChange={handleUrlChange}
                    className={cn(
                      'text-sm',
                      errors.url && 'border-red-500 focus-visible:ring-red-500',
                    )}
                  />
                  {isLoading && <Loader2 className="w-4 h-4 text-gravyfa6-navy animate-spin" />}
                </div>
                {errors.url && (
                  <p className="text-xs text-red-500">{errors.url}</p>
                )}
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <Label className="text-xs font-medium text-neutralgrey-6">{formData.previewLabel}</Label>
            <Card className="bg-neutralgrey-1">
              <CardContent className="h-[350px] p-4 flex items-center justify-center">
                {renderEmbed()}
              </CardContent>
            </Card>
          </div>
        </div>
        <Separator />
        <DialogFooter className="px-6 py-4">
          <Button variant="outline" className="text-sm font-semibold text-neutralgrey-6" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="text-sm"
            disabled={isAddButtonDisabled}
            variant="primary"
            onClick={handleAddContent}
          >
            Add
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
