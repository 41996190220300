import React, { useState } from 'react';
import { useFindAndImportContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImportContext';
import { Pagination } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/Pagination';
import { useGetImportedProductsStats } from '@frontend/app/containers/Settings/ProductFulfillment/Products/hooks/useGetAllProductsStats';
import { useGetProductsFromShopify } from '@frontend/app/containers/Settings/ProductFulfillment/Products/hooks/useGetProductsFromShopify';
import { useProductsContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { pluralize } from '@frontend/app/utils/strings';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import {
 Empty, Col, Row, Image, Modal, Space, Typography,
} from '@revfluence/fresh';
import { Spin } from 'antd';
import { BoxesStackedIcon } from '@revfluence/fresh-icons/solid';
import { useEffect } from 'react';
import styles from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImport.scss';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { message } from 'antd';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { FindContainer } from './FindandImport';

const { Title, Text } = Typography;

const PAGE_SIZE = 50;

type ProductDetails = {
  id: string;
  title: string;
  description?: string;
  imgUrl?: string;
  imageUrl?: string;
  price?: string;
  totalInventory?: number;
};

const MAX_PRODUCTS_LIMIT = 12;

export const ExportProductModal = ({
  selectedProducts,
  setSelectedProducts,
  onClose,
}: {
  selectedProducts: ProductDetails[];
  setSelectedProducts: (products: ProductDetails[]) => void;
  onClose: () => void;
}) => {
  // Initialize local state with existing selections
  const [localSelectedProducts, setLocalSelectedProducts] = useState<ProductDetails[]>(selectedProducts || []);

  const {
    pageNumber,
    visited,
    setPageNumber,
    setVisited,
    resetState,
    screen,
    isFindClicked,
  } = useFindAndImportContext();

  const { isFindAndImportOpen, setIsFindAndImportOpen } = useProductsContext();

  const { landingPageData, setLandingPageData } = useLandingPageContext();

  const { clientConfig } = useGetClientConfig();

  const { data: productStats, refetch: refetchStats } = useGetImportedProductsStats();
  const {
    fetchShopifyProducts,
    isShopifyProductsLoading,
    shopifyProductsResp,
    fetchMoreShopifyProducts,
  } = useGetProductsFromShopify({
    notifyOnNetworkStatusChange: true,
  });

  const paginatedShopifyProducts = shopifyProductsResp?.shopifyProducts?.slice(
    (pageNumber - 1) * PAGE_SIZE,
    pageNumber * PAGE_SIZE,
  );

  const handleExcludeProductTemp = (product) => {
    const isProductSelected = localSelectedProducts.some(
      (selectedProduct) => selectedProduct.id === product.id,
    );

    if (isProductSelected) {
      setLocalSelectedProducts(localSelectedProducts.filter(
        (selectedProduct) => selectedProduct.id !== product.id,
      ));
    } else {
      const creatorLimit = landingPageData.products.setting.allowCreatorToAddProducts
        ? (landingPageData.products.setting.noOfProductsCreatorCanAdd || 0)
        : 0;
      const maxAllowedProducts = MAX_PRODUCTS_LIMIT - creatorLimit;

      if (localSelectedProducts.length >= maxAllowedProducts) {
        message.error(`You can only select up to ${maxAllowedProducts} products${creatorLimit ? ` (${creatorLimit} slots reserved for creators)` : ''}.`);
        return;
      }
      setLocalSelectedProducts([...localSelectedProducts, product]);
    }
  };

  const handleSave = () => {
    if (localSelectedProducts.length > 0) {
      // Update both parent state and landing page data
      setSelectedProducts(localSelectedProducts);
      setLandingPageData((prev) => ({
        ...prev,
        products: {
          ...prev.products,
          allProducts: {
            ...prev.products?.allProducts,
            products: localSelectedProducts.map((product) => ({
              id: product.id,
              imageUrl: product.imgUrl || product.imageUrl,
              title: product.title,
              price: Number(product.price),
            })),
          },
        },
      }));
    }
    setIsFindAndImportOpen(false);
    resetState();
    onClose();
  };

  useEffect(() => {
    refetchStats();
  }, [isFindAndImportOpen, refetchStats]);

  const importLimit = clientConfig?.importSyncSettings?.productsImportLimit || 50_000;

  const availableProductLimit = importLimit - (productStats?.shopifyProductsCount || 0);

  const hasNextPage = pageNumber * PAGE_SIZE < shopifyProductsResp?.totalCount;
  const hasPreviousPage = pageNumber > 1;

  const handleNextPage = async () => {
    // Check if we have a valid endCursor and more pages available
    if (hasNextPage && shopifyProductsResp?.pageInfo?.endCursor) {
      if (!visited[pageNumber + 1]) {
        await fetchMoreShopifyProducts({
          variables: {
            afterCursor: shopifyProductsResp.pageInfo.endCursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              getProductsFromShopify: {
                ...fetchMoreResult.getProductsFromShopify,
                shopifyProducts: [
                  ...prev.getProductsFromShopify.shopifyProducts,
                  ...fetchMoreResult.getProductsFromShopify.shopifyProducts,
                ],
              },
            };
          },
        });
        setVisited(pageNumber + 1);
      }
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const handleClose = () => {
    setIsFindAndImportOpen(false);
    setLocalSelectedProducts(selectedProducts || []); // Reset to original selections
    resetState();
    onClose();
  };

  let cantImportReason = '';
  if (availableProductLimit - (shopifyProductsResp?.totalCount || 0) < 0) {
    cantImportReason = 'Found products are more than available product limit. Please adjust your filters and try again.';
  } else if (!shopifyProductsResp?.totalCount) {
    cantImportReason = 'No products found';
  }

  return (
    <Modal
      title={(
        <Space className={styles.header}>
          <Title level={4}>Select All Product</Title>
        </Space>
      )}
      open={isFindAndImportOpen}
      onCancel={handleClose}
      width={699}
      className={styles.FindAndImport}
      footer={null}
    >
      {screen === 'find' && (
        <Space direction="vertical">
          <FindContainer
            fetchShopifyProducts={fetchShopifyProducts}
            isShopifyProductsLoading={isShopifyProductsLoading}
            cantImportReason={cantImportReason}
          />
          {isShopifyProductsLoading ? (
            <Row justify="center" align="middle" className={styles.loaderContainer}>
              <Col>
                <Space direction="vertical" className={styles.space}>
                  <Spin size="large" />
                  <Text>Fetching the products...</Text>
                </Space>
              </Col>
            </Row>
          ) : (
            isFindClicked
            && (shopifyProductsResp?.totalCount ? (
              <>
                <Space className={styles.paginationContainer}>
                  <Text weight="semibold">
                    {shopifyProductsResp?.totalCount}
                    {' '}
                    {pluralize(shopifyProductsResp?.totalCount || 0, 'Product')}
                    {' '}
                    (
                    {localSelectedProducts.length}
                    {' '}
                    selected)
                  </Text>
                  <Pagination
                    page={pageNumber}
                    pageSize={PAGE_SIZE}
                    itemsOnPage={paginatedShopifyProducts?.length}
                    items={shopifyProductsResp?.totalCount}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    onNext={handleNextPage}
                    onPrev={handlePrevPage}
                  />
                </Space>
                <Space direction="vertical" className={`${styles.collectionTable} max-h-[400px] overflow-y-auto pb-16`}>
                  {paginatedShopifyProducts?.map((product) => (
                    <div className={styles.collectionListItem} key={product.id}>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div>
                              <Checkbox
                                checked={localSelectedProducts.some(
                                  (selectedProduct) => selectedProduct.id === product.id,
                                )}
                                onCheckedChange={() => handleExcludeProductTemp(product)}
                                id={`product-${product.id}`}
                                disabled={product.totalInventory === 0}
                              />
                            </div>
                          </TooltipTrigger>
                          {product.totalInventory === 0 && (
                            <TooltipContent>
                              <p>Product is out of stock</p>
                            </TooltipContent>
                          )}
                        </Tooltip>
                      </TooltipProvider>
                      <Image
                        src={
                          product.imgUrl
                          || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'
                        }
                        width={40}
                        height={40}
                        preview={false}
                      />
                      <div className={styles.collectionListItemTitle}>
                        <Text weight="semibold">{product.title}</Text>
                        <Text type="secondary">
                          {product.variantCount}
                          {' '}
                          {pluralize(product.variantCount, 'Variant')}
                        </Text>
                      </div>
                    </div>
                  ))}
                </Space>
                {isFindClicked && shopifyProductsResp?.totalCount > 0 && (
                  <div className="sticky bottom-0 bg-white py-4 border-t border-gray-200 mt-auto z-10 shadow-sm">
                    <Row justify="end">
                      <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={localSelectedProducts.length === 0}
                      >
                        Save
                      </Button>
                    </Row>
                  </div>
                )}
              </>
            ) : (
              <Row justify="center" align="middle" className={styles.loaderContainer}>
                <Col>
                  <Empty
                    size="small"
                    description={(
                      <Space direction="vertical">
                        <span style={{ fontSize: '14px' }}>No products found.</span>
                        <Text type="secondary">Please adjust your filters and try again.</Text>
                      </Space>
                    )}
                    image={<BoxesStackedIcon />}
                  />
                </Col>
              </Row>
            ))
          )}
        </Space>
      )}
    </Modal>
  );
};
