import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import { cn } from '@/shadcn/lib/utils';

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  indicatorType?: 'check' | 'square';
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, indicatorType = 'check', ...props }, ref) => (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        `peer h-4 w-4 shrink-0 rounded-sm border border-grey-4 transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-900 disabled:cursor-not-allowed disabled:opacity-50 hover:border-black ${indicatorType === 'square' ? 'data-[state=checked]:bg-white' : 'data-[state=checked]:bg-gray-900'} data-[state=checked]:text-white ${indicatorType === 'square' ? 'data-[state=checked]:border-grey-4' : 'data-[state=checked]:border-gray-900'}`,
        className,
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current">
        {indicatorType === 'square' ? (
          <div className="h-4 w-4 bg-white rounded-sm flex items-center justify-center">
            <div className="h-2 w-2 bg-black rounded-sm" />
          </div>
        ) : (
          <CheckIcon className="h-3 w-3 text-white" />
        )}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  ),
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
