import { graphql } from '@frontend/gql/social';

export const socialAnalyticsFragment = graphql(`
  fragment SocialAnalytics_Query on Query {
    client(id: $clientId) {
      id
      socialAnalytics {
        totalInsights(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
          }
        ) {
          ...MembersCard_TotalInsights
          ...ReachCard_TotalInsights
          ...EngagementCard_TotalInsights
          ...ImpressionsCard_TotalInsights
        }
        members(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
          }
        ) {
          pagination {
            totalCount
            totalPages
            currentPage
            limit
            hasNextPage
            hasPreviousPage
          }
          results {
            memberId
            total {
              reach
              impressions
              likes
              comments
              engagements
            }
          }
        }
      }
    }
  }
`);

export const getSocialAnalytics = graphql(`
  query GetSocialAnalytics(
    $clientId: String!
    $startDate: Date!
    $endDate: Date!
    $postTypes: [SocialPostType!]!
    $includeEstimates: Boolean!
    $networks: [SocialNetwork!]!
  ) {
    client(id: $clientId) {
      id
      socialAnalytics {
        totalInsights(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
          }
        ) {
          ...MembersCard_TotalInsights
          ...ReachCard_TotalInsights
          ...EngagementCard_TotalInsights
          ...ImpressionsCard_TotalInsights
        }
        postsCreated(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
          }
        ) {
          network
          postType
          date
          count
        }
        members(
          filter: {
            interval: { startDate: $startDate, endDate: $endDate }
            postTypes: $postTypes
            includeEstimates: $includeEstimates
            networks: $networks
          }
        ) {
          pagination {
            totalCount
            totalPages
            currentPage
            limit
            hasNextPage
            hasPreviousPage
          }
          results {
            memberId
            total {
              reach
              impressions
              likes
              comments
              engagements
            }
          }
        }
      }
    }
  }
`);
