import * as React from 'react';
import cx from 'classnames';
import {
 Redirect, Route, RouteComponentProps, Switch, withRouter,
} from 'react-router-dom';

import { EventName } from '@common';
import { ClientFeature } from '@frontend/app/constants';
import ApplicationPage from '@frontend/app/containers/Application/ApplicationPage';
import Social from '@frontend/applications/Social';
import { GetStarted } from '@frontend/app/containers/GetStarted/GetStarted';
import SettingsPage from '@frontend/app/containers/SettingsApp/SettingsPage';
import CommunitiesLayout from '@frontend/app/containers/Communities/CommunitiesLayout';
import MemberDetailPage from '@frontend/app/containers/MemberDetail/MemberDetailPage';
import MemberPage from '@frontend/app/containers/Members/MemberPage';
import BudgetReportingContainer from '@frontend/app/containers/BudgetReporting/BudgetReportingContainer';
import { InfluencerWhitelistAppDashboard } from '@frontend/applications/InfluencerWhitelist/pages/InfluencerWhitelistAppDashboard';
import { ReportingContainer } from '@frontend/applications/ReportsApp/containers/Reporting/Reporting';
import MessagingApp from '@frontend/app/containers/MessagingApp/MessagingApp';
import { ProjectsRoutes } from '@frontend/app/containers/Projects/Routes';
import { useAuth } from '@frontend/context/authContext';
import { useEventContext } from '@frontend/app/context/EventContext';
import { ErrorBoundary } from '@frontend/utils';
import { TopNavbar } from '@frontend/app/components';
import {
  useCheckFirstLogin,
  useGetAllProjectsWithDeletedCountQuery,
  useClientFeatureEnabled,
  useMemberPagePrevUrl,
} from '@frontend/app/hooks';
import { OverlaySpinner } from '@components';
import { EmbeddedReporting } from '@frontend/app/containers/EmbeddedReporting/EmbeddedReporting';
import { BudgetDashboard } from '@frontend/app/containers/BudgetDashboard/BudgetDashboard';

import { MainLayout } from './Layout';
import { MessageTemplatesPage } from '../EmailSettings/MessageTemplatesPage/MessageTemplatesPage';

const { useLayoutEffect, useEffect } = React;

import Onboarding from '../Onboarding/Onboarding';
import { GroupCreatorDetail } from '../GuestHome/Groups/GroupCreatorDetail/GroupCreatorDetail';

import styles from './Home.scss';

type THomePageProps = RouteComponentProps<{}>;

const OldLayout = ({ children }) => (
  <div className={cx(styles.Home, styles.hasTopNav)}>
    <TopNavbar />
    <div className={styles.content}>{children}</div>
  </div>
);

const HomePage: React.FunctionComponent<THomePageProps> = React.memo((props) => {
  const { location, history } = props;
  const { targetUrl, user } = useAuth();
  const addEvent = useEventContext();
  const showOnboarding = useClientFeatureEnabled(ClientFeature.SHOW_ONBOARDING);
  const showOnboardingForTesting = useClientFeatureEnabled(ClientFeature.SHOW_ONBOARDING_FOR_TESTING);
  const refreshUI = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const isBudgetDashboardEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_DASHBOARD);
  useClientFeatureEnabled(ClientFeature.INSTAGRAM_PARTNERSHIP_ADS, {
    nextFetchPolicy: 'cache-first',
  });
  useClientFeatureEnabled(ClientFeature.META_BRANDED_CONTENT, {
    nextFetchPolicy: 'cache-first',
  });

  // redirects to target url before authenticated
  useLayoutEffect(
    () => {
      if (targetUrl && targetUrl !== location.pathname) {
        history.replace(targetUrl);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetUrl],
  );
  useEffect(
    () => addEvent(EventName.LoadPage, { pageName: location.pathname }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );
  useEffect(
    () => addEvent(EventName.LoadSession, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (refreshUI) document.body.classList.add('ant-refresh');
    else document.body.classList.remove('ant-refresh');

    return () => {
      document.body.classList.remove('ant-refresh');
    };
  }, [refreshUI]);

  useMemberPagePrevUrl(history);

  const {
    data: { count: allProjectWithDeletedCount = 0 } = {},
    loading: isLoading,
  } = useGetAllProjectsWithDeletedCountQuery();

  useCheckFirstLogin(user);

  if (isLoading) return <OverlaySpinner />;

  if ((allProjectWithDeletedCount === 0 && showOnboarding) || showOnboardingForTesting) {
    return <Onboarding />;
  }

  const CurrentLayout = refreshUI ? MainLayout : OldLayout;

  return (
    <CurrentLayout>
      <ErrorBoundary>
        <Switch>
          <Route path="/home" component={GetStarted} />
          <Route path="/settings/:applicationId" component={SettingsPage} />
          <Route path="/communities" component={CommunitiesLayout} />
          <Route path="/member_table" component={MemberPage} />
          <Route path="/members/:memberId(\d+)" component={MemberDetailPage} />
          <Route path="/messages" component={MessagingApp} />
          <Route path="/projects" component={ProjectsRoutes} />
          <Route path="/app/:applicationId" component={ApplicationPage} />
          <Route path="/email/templates" component={MessageTemplatesPage} />
          <Route path="/influencer_whitelist" component={InfluencerWhitelistAppDashboard} />
          <Route path="/impact_dashboard" component={ReportingContainer} />
          <Route path="/social" component={Social} />
          <Route
            path="/budget"
            component={
              isBudgetDashboardEnabled && isBudgetAllocationEnabled ? BudgetDashboard : BudgetReportingContainer
            }
          />
          <Route path="/embedded_reporting/:id" component={EmbeddedReporting} />
          <Route path="/creator" component={GroupCreatorDetail} />
          <Redirect to="/home" />
        </Switch>
      </ErrorBoundary>
    </CurrentLayout>
  );
});

HomePage.displayName = 'HomePage';

const Home = withRouter(HomePage);

Home.displayName = 'HomeWithRouter';

export { Home };
