import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { GetSocialAnalyticsQuery } from '@frontend/gql/social/graphql';
import MembersCard from '../members-card';
import ReachCard from '../reach-card';
import ImpressionsCard from '../impressions-card';
import EngagementCard from '../engagement-card';
import { DebugView } from '../DebugView';
import { GraphQLQueryVariables } from '../../types';

interface MembersTabProps {
  queryVariables: GraphQLQueryVariables;
  data: GetSocialAnalyticsQuery | undefined;
  isLoading?: boolean;
}

export const MembersTab = ({ queryVariables, data, isLoading }: MembersTabProps) => {
  const totalInsights = data?.client?.socialAnalytics?.totalInsights;

  return (
    <>
      <div className="p-4">
        <H3>Members Analytics</H3>
        <P>
          Analyze member performance and engagement metrics. Track individual contributions and identify top performers
          in your social media campaigns.
        </P>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 p-4">
        {isLoading ? (
          <>
            <Skeleton className="h-[110px] rounded-xl" />
            <Skeleton className="h-[110px] rounded-xl" />
            <Skeleton className="h-[110px] rounded-xl" />
            <Skeleton className="h-[110px] rounded-xl" />
          </>
        ) : (
          <>
            <MembersCard data={totalInsights} />
            <ReachCard data={totalInsights} />
            <EngagementCard data={totalInsights} />
            <ImpressionsCard data={totalInsights} />
          </>
        )}
      </div>
      <DebugView queryVariables={queryVariables} data={data || {}} />
    </>
  );
};
