import { Card, CardContent } from '@frontend/shadcn/components/ui/card';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Label } from '@frontend/shadcn/components/ui/label';
import { ColorPicker } from '@frontend/applications/AffiliatesApp/pages/LandingPage/utils/shadColorPicker';
import { useLandingPageContext } from '@frontend/applications/AffiliatesApp/pages/LandingPage/context/LandingPageContext';
import React, { useRef } from 'react';

const buttonConfigs = [
  {
    title: 'Solid Button',
    key: 'solidButton',
    settings: [
      { label: 'Background', key: 'backgroundColor', type: 'color' },
      {
        label: 'Corner Radius',
        key: 'cornerRadius',
        type: 'number',
        unit: 'px',
      },
      {
        label: 'Label Size',
        key: 'labelSize',
        type: 'number',
        unit: 'px',
      },
      { label: 'Label Color', key: 'labelColor', type: 'color' },
    ],
  },
  // {
  //   title: 'Outline Button',
  //   key: 'outlineButton',
  //   settings: [
  //     { label: 'Border Color', key: 'backgroundColor', type: 'color' },
  //     { label: 'Corner Radius', key: 'cornerRadius', type: 'number', unit: 'px' },
  //     { label: 'Label Size', key: 'labelSize', type: 'number', unit: 'px' },
  //     { label: 'Label Color', key: 'labelColor', type: 'color' },
  //   ],
  // },
];

const defaultButtonSettings = {
  backgroundColor: '#FFFFFF',
  cornerRadius: 8,
  labelSize: 14,
  labelColor: '#000000',
};

const Frame = (): JSX.Element => {
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const colorPickerRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleSettingChange = (buttonKey: string, settingKey: string, value: string, type: string) => {
    let processedValue = value;

    // Convert to number for numeric fields
    if (type === 'number') {
      const numValue = parseFloat(value);
      processedValue = Number.isNaN(numValue) ? defaultButtonSettings[settingKey] : numValue;
    }

    setLandingPageData((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        buttons: {
          ...prev.style?.buttons,
          [buttonKey]: {
            ...(prev.style?.buttons?.[buttonKey] || defaultButtonSettings),
            [settingKey]: processedValue,
          },
        },
      },
    }));
  };

  // Safe access to button settings with fallback
  const getButtonSetting = (buttonKey: string, settingKey: string) =>
    landingPageData.style?.buttons?.[buttonKey]?.[settingKey] ?? defaultButtonSettings[settingKey];

  return (
    <div className="flex flex-col gap-4">
      {buttonConfigs.map((config) => (
        <Card key={config.key} className="w-full border-none">
          <CardContent className="pt-2">
            <div className="flex flex-col gap-4">
              <h2 className="text-neutralgrey-5 text-[14px] font-medium leading-6">
                {config.title}
                {' '}

              </h2>
              <div className="grid grid-cols-4 gap-4">
                {config.settings.map((setting, settingIndex) => (
                  <div key={setting.key} className="flex flex-col gap-2">
                    <Label className="text-neutralgrey-5 text-xs font-medium">
                      {' '}
                      {setting.label}
                      {' '}
                    </Label>
                    {setting.type === 'color' ? (
                      <ColorPicker
                        ref={(el) => (colorPickerRefs.current[settingIndex] = el)}
                        value={getButtonSetting(config.key, setting.key)}
                        onChange={(color) => handleSettingChange(config.key, setting.key, color, 'color')}
                        className="flex items-center gap-2.5 pl-1 pr-2 py-1 bg-neutralgrey-0 rounded-lg border border-neutralgrey-2"
                      />
                    ) : (
                      <div className="flex items-center bg-neutralgrey-0 rounded-lg border border-neutralgrey-2 w-fit">
                        <Input
                          type="number"
                          min={0}
                          value={getButtonSetting(config.key, setting.key)}
                          onChange={(e) => handleSettingChange(config.key, setting.key, e.target.value, setting.type)}
                          className="border-0 text-neutralgrey-6"
                        />
                        <span className="pr-3 text-neutralgrey-4 text-xs">
                          {' '}
                          {setting.unit}
                          {' '}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default Frame;
