import {
    Button,
    Dropdown,
    IColumnsType, Input, Menu, Row, Select, Tag, Tooltip, Typography, Modal, message,
} from '@revfluence/fresh';
import React, {
    memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  capitalize,
 first, isArray, lowerCase, map, uniqBy,
} from 'lodash';
import DateFilter from '@frontend/applications/ReportsApp/pages/Reporting/components/DateFilter';
import { dateValues } from '@frontend/applications/ReportsApp/pages/Reporting/components/Header/Header';
import { DateRangesLabel } from '@frontend/app/containers/HomePageMetrics/components/DateSelect';
import _ from 'lodash';
import { InfoCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { CheckIcon, EllipsisIcon, XmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import styles from './ConversionTable.scss';
import { getLocaleString, getTimeStamp } from '../../utils/dateTimeUtils';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { GetAffiliateConversionHistory_affliateConversionHistory } from '../../queries/types/GetAffiliateConversionHistory';
import AffiliateTableSTA from '../AffiliateTableSTA/AffiliateTableSTA';
import { arrangeTableColumns } from '../../utils/columnOrder';
import { IMemberConversionTable } from '../MemberTable/types';
import { useAcceptRejectConversion } from '../../hooks/acceptAndRejectConversionMutation';
import { DataFormat, formatValue } from '../../utils';
import { CONVERSION_SOURCE } from '../OfferConversionHistoryDrawer/types';

const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;
interface IProps {
    affiliateConversionHistory: GetAffiliateConversionHistory_affliateConversionHistory[];
    source: OFFER_SOURCE;
    refetchData: () => void
    refresh: () => void
}
enum CONVERSION_STATUS {
    ACCEPTED = 'Accepted',
    REJECTED = 'Rejected',
    DUPLICATE = 'Duplicate',
}
const getTagType = (status: CONVERSION_STATUS) => {
    switch (status) {
        case CONVERSION_STATUS.ACCEPTED:
            return 'success';
        case CONVERSION_STATUS.REJECTED:
            return 'error';
        case CONVERSION_STATUS.DUPLICATE:
            return 'warning';
        default:
            return 'default';
    }
};
const orderArray: string[] = ['date', 'orderId', 'tuneOrderId', 'affiliatInfoOne', 'saleAmount', 'exchangeRates', 'sales', 'commisionEarned', 'status'];
const sample = (
  <span>
    Status
    {' '}
    <Tooltip title="By default, all conversions are marked as accepted. You can reject/accept a conversion until it has been paid.">
      <InfoCircleTwoTone style={{ marginLeft: 4 }} />
    </Tooltip>
  </span>
);
export const ConversionTable: React.FC<Readonly<IProps>> = memo((props) => {
    const {
        affiliateConversionHistory, source, refetchData, refresh,
    } = props;

    const [selectedStore, setSelectedStore] = useState<string>('All Stores');
    const [dateRangee, setDateRangee] = useState<[Date?, Date?]>([null, null]);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [conversionIds, setConversionIds] = useState<(number | string)[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [conversionType, setConversionType] = useState('');
    const { enableMultipleShopify: isEnabledMultipleShopify, rejectMemberConversions } = useClientFeatures();
    useEffect(() => {
        setCurrentPage(1);
    }, [searchText]);
    const [acceptRejectConversion] = useAcceptRejectConversion({
        onCompleted() {
            message.success(`${conversionIds.length} conversion${conversionIds.length > 1 ? 's' : ''} marked as ${lowerCase(conversionType)}ed`);
            refetchData();
            refresh();
            setSelectedRowKeys([]);
            setConversionIds([]);
        },
    });
    const data = useMemo(() => {
        const [startDate, endDate] = dateRangee;
        const filteredaffiliateConversionHistory = affiliateConversionHistory.filter((affliate) => selectedStore === 'All Stores' || affliate.storeName === selectedStore);
        return map(filteredaffiliateConversionHistory, (conversion) => {
            let dupDiscountCode: string | undefined;
            try {
                if (conversion.discountCode) {
                  const duplicateDiscount = JSON.parse(conversion.discountCode);
                  if (isArray(duplicateDiscount)) {
                    dupDiscountCode = first(duplicateDiscount).code;
                  }
                }
              } catch (error) {
                console.log('conversion.discount is not a valid JSON');
              }
            const commonFields: IMemberConversionTable = {
              date: conversion.conversionDate,
              _raw: conversion,
              // payoutType: conversion.,
              id: String(conversion.conversionId),
              key: String(conversion.conversionId),
              sales: {
                sale: conversion.sale,
                currency: conversion.currency,
              },
              orderId: conversion.conversionId,
              exchangeRates: {
                rate: conversion.exchangeRate,
                currency: conversion.currency,
              },
              saleAmount: {
                sale: conversion.sale,
                baseSale: conversion.salesBase,
                currency: conversion.currency,
              },
              commisionEarned: conversion.payoutEarned,
              status: conversion.status,
              affiliatInfoOne: conversion.affiliatInfoOne,
              tuneOrderId: conversion.tuneOrderId,
              duplicateDiscountCode: dupDiscountCode,
              source: conversion.source === 'promo' ? CONVERSION_SOURCE.PROMO : CONVERSION_SOURCE.LINK,
            };
            return {
                ...commonFields,
            };
        }).filter((conversion) =>
            (startDate ? getTimeStamp(conversion.date) >= getTimeStamp(startDate.setHours(0, 0, 0, 0)) : true)
            && (endDate ? getTimeStamp(conversion.date) <= getTimeStamp(endDate.setHours(23, 59, 59, 999)) : true))
            .filter((conversion) => _.includes(String(conversion.orderId).toLowerCase(), searchText) || _.includes(String(conversion.tuneOrderId).toLowerCase(), searchText));
    }, [affiliateConversionHistory, selectedStore, dateRangee, searchText]);
    const uniqueStores = useMemo(() => {
        const stores = uniqBy(affiliateConversionHistory, 'storeName').map((obj) => obj.storeName);
        stores.unshift('All Stores');
        return stores;
    }, [affiliateConversionHistory]);
    const handleConversion = useCallback((key) => {
        setConversionType(key);
        const selectedKey = key === 'ACCEPT' ? 'Rejected' : 'Accepted';

        const filterIds = data
          .filter(
            (item) =>
              item.status === selectedKey
              && conversionIds.includes(item.source === CONVERSION_SOURCE.LINK ? item.orderId : item._raw.id),
          )
          .map((item) => ({ conversionId: String(item.source === CONVERSION_SOURCE.LINK ? item.orderId : item._raw.id), offerSource: item.source === CONVERSION_SOURCE.LINK ? OFFER_SOURCE.TUNE : OFFER_SOURCE.SHOPIFY }));
        confirm({
            title: `Are you sure you want to ${lowerCase(key)} ${conversionIds.length} conversion${conversionIds.length > 1 ? 's' : ''}?`,
            type: 'warning',
            onOk() {
                message.loading(`${key === 'ACCEPT' ? 'Accepting' : 'Rejecting'} ${conversionIds.length} conversion${conversionIds.length > 1 ? 's' : ''}`, 0);
                if (filterIds.length === 0) {
                    message.success(`${conversionIds.length} conversion${conversionIds.length > 1 ? 's' : ''} marked as ${lowerCase(key)}ed`);
                    setTimeout(() => {
                        message.destroy();
                    }, 1500);
                    return;
                }
                acceptRejectConversion({
                    variables: {
                        action: key,
                        data: filterIds,
                    },
                }).catch((error) => {
                    message.error(error.message);
                })
                    .finally(() => {
                        setTimeout(() => {
                            message.destroy();
                        }, 1500);
                    });
            },
            onCancel() {
            },
            autoFocusButton: null,
            okText: capitalize(key),
        });
    }, [acceptRejectConversion, setConversionType, conversionIds, data]);

    const menuItems = useMemo(() => (

      <Menu onClick={({ key }) => handleConversion(key)}>
        <Menu.Item key="ACCEPT" icon={<CheckIcon />} disabled={conversionIds.length == 0}>
          Accept Conversion
        </Menu.Item>
        <Menu.Item key="REJECT" icon={<XmarkIcon />} danger disabled={conversionIds.length == 0}>
          Reject Conversion
        </Menu.Item>
      </Menu>
    ), [conversionIds, handleConversion]);

    const isUsdStore = _.every(data, (item) => item.saleAmount.currency === 'USD');
    const searchBox = () => (
      <Input
        placeholder="Search for order Id"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        prefix={<SearchOutlined />}
      />
    );
    const columns = useMemo((): IColumnsType[] => {
        const column: IColumnsType<IMemberConversionTable> = [
            {
                title: 'Date',
                key: 'date',
                dataIndex: 'date',
                render: (date) => getLocaleString(date),
                width: source === OFFER_SOURCE.TUNE ? 80 : 140,
            },
            {
                title: 'Order Id',
                key: 'orderId',
                dataIndex: 'orderId',
                render: (text, record) => (record.source === CONVERSION_SOURCE.LINK ? <Text>{record.tuneOrderId}</Text> : <Text>{text}</Text>),
                width: 80,
                ellipsis: {
                    showTitle: true,
                },
            },
            {
                title: 'Sales',
                key: 'saleAmount',
                dataIndex: 'saleAmount',
                render: (data, record) => `${isUsdStore || record.source === CONVERSION_SOURCE.LINK ? '$' : ''}${record.source === CONVERSION_SOURCE.LINK ? formatValue(DataFormat.Money, data.sale) : formatValue(DataFormat.Money, data.baseSale)} ${(source === OFFER_SOURCE.SHOPIFY && !isUsdStore) ? data.currency : ''}`,
                width: 100,

            },
            {
                title: 'Commission Earned ',
                key: 'commisionEarned',
                dataIndex: 'commisionEarned',
                render: (data) => `$ ${formatValue(DataFormat.Money, data)}`,
                width: 120,
            },
            {
                title: sample,
                key: 'status',
                dataIndex: 'status',
                render: (status, data) => {
                if (data.duplicateDiscountCode) {
                  return (
                    <Tooltip title={`Conversion duplicated by promo ${data.duplicateDiscountCode}`}>
                      <Tag color={getTagType(CONVERSION_STATUS.DUPLICATE)}>{CONVERSION_STATUS.DUPLICATE}</Tag>
                    </Tooltip>
                  );
                }
                return <Tag color={getTagType(status)}>{status}</Tag>;
            },
                width: 60,

            },
        ];

        if ((source === OFFER_SOURCE.SHOPIFY || source === OFFER_SOURCE.PROMO_LINK) && !isUsdStore) {
            column.push(
              {
                title: 'Exchange Rate(USD)',
                key: 'exchangeRates',
                dataIndex: 'exchangeRates',
                render: (data, record) =>
                  (record.source === CONVERSION_SOURCE.PROMO ? (
                    <Tag>
                      1
                      {data.currency}
                      {' '}
                      =
                      {' '}
                      {Number(data.rate).toFixed(4)}
                      {' '}
                      USD
                    </Tag>
                  ) : '--'),
                width: 140,
              },
              {
                title: 'Sales (USD)',
                key: 'sales',
                dataIndex: 'sales',
                render: (data, record) => (record.source === CONVERSION_SOURCE.PROMO ? `$ ${formatValue(DataFormat.Money, data.sale)}` : '--'),
                width: 100,
              },
            );
        }
        if (source === OFFER_SOURCE.PROMO_LINK) {
          column.push({
            title: 'Source',
            key: 'conversion_source',
            dataIndex: 'source',
            width: 120,
            render: (text) => <Tag color={text === CONVERSION_SOURCE.LINK ? 'blue' : 'purple'}>{text}</Tag>,
          });
        }

        return arrangeTableColumns(column);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);
    const renderCount = useCallback(() => {
        const tableCount = data.length;
        const selected = conversionIds.length > 0;
        const selectedCount = selected ? conversionIds.length : tableCount;
        return (
          <div className={styles.tableCount}>
            <Text strong>{`${tableCount} Conversion${tableCount > 1 ? 's' : ''} ${selected ? `(${selectedCount} selected)` : ''}`}</Text>
          </div>
        );
    }, [data, conversionIds]);
    const headerActions = (
      <>
        {renderCount()}
        <Row className={styles.conversionHeaderContainer}>
          {(isEnabledMultipleShopify && uniqueStores.length > 2) && (
            <Select defaultValue="All Stores" style={{ width: 173 }} onChange={(e) => setSelectedStore(e)}>
              {uniqueStores.map((s, index) => <Option value={s} key={index}>{s}</Option>)}
            </Select>
                )}
          <DateFilter
            key="date-filter"
            defaultLabel={DateRangesLabel.ALL_TIME}
            isCheckbox={false}
            options={dateValues}
            isCustomDate={false}
            onFilterChange={([filter]) => filter && setDateRangee(filter.value.dateRange)}
          />
          {rejectMemberConversions && (
            <Dropdown overlay={menuItems}>
              <Button>
                <EllipsisIcon />
              </Button>
            </Dropdown>
                )}
        </Row>
      </>
    );
    const rowSelection = {
        onChange: (key: React.Key[], selectedRows) => {
            const ids = selectedRows.map((item) => (item.source === CONVERSION_SOURCE.LINK ? item.orderId : item._raw.id));
            setConversionIds(ids);
            setSelectedRowKeys(key);
        },
        getCheckboxProps: (record) => ({
            disabled: record._raw.isPaid,
            name: record.name,
            tooltipTitle: record._raw.isPaid ? 'This conversion is already paid' : undefined,
        }),
        selectedRowKeys,
        renderCell: (_checked, record, _index, originNode) => (record._raw.isPaid ? (
          <Tooltip title="Conversions already paid">
            {originNode}
          </Tooltip>
        ) : originNode),
    };

    return (
      <>
        <Text className={styles.conversionHeaderTitle}>Conversion History</Text>
        <Text className={styles.conversionHeaderdesc} type="secondary">
          You can now have a comprehensive record of all sales conversions for this member.
        </Text>
        <AffiliateTableSTA<IMemberConversionTable>
          dataSource={data}
          columns={arrangeTableColumns(columns, orderArray)}
          pagination={false}
          rowSelection={rejectMemberConversions ? rowSelection : undefined}
          headerActions={headerActions}
          searchBox={searchBox()}
          searchText={searchText}
          sortField="name"
          className="affiliateMemberTable"
          enableCustomPagination={{
            pageSize: 20,
            total: data.length,
            page: currentPage,
            setPage: setCurrentPage,
            isDynamicPaginationEnabled: false,
          }}
          enableEditColumn={false}
        />
      </>
    );
});
