import { useState, useCallback, useEffect } from 'react';
import { useQuery } from 'urql';
import { GetSocialAnalyticsQuery } from '@frontend/gql/social/graphql';
import { getSocialAnalytics } from '../graphql';
import { GraphQLQueryVariables } from '../types';

interface UseAnalyticsDataProps {
  clientId: string | undefined;
  tab: string;
  queryVariables: GraphQLQueryVariables;
}

interface UseAnalyticsDataReturn {
  data: GetSocialAnalyticsQuery | undefined; // TODO: Type this properly
  fetching: boolean;
  refetching: boolean;
  handleRefresh: () => void;
}

export const useAnalyticsData = ({ clientId, tab, queryVariables }: UseAnalyticsDataProps): UseAnalyticsDataReturn => {
  const [refetching, setRefetching] = useState(false);
  const [{ data, fetching }, refetch] = useQuery({
    query: getSocialAnalytics,
    variables: queryVariables,
    pause: !clientId || tab !== 'members',
  });

  const handleRefresh = useCallback(() => {
    if (tab === 'members') {
      setRefetching(true);
      refetch({ requestPolicy: 'network-only' });
    }
  }, [tab, refetch]);

  useEffect(() => {
    if (!fetching) {
      setRefetching(false);
    }
  }, [fetching]);

  return {
    data,
    fetching,
    refetching,
    handleRefresh,
  };
};
