import React, { useEffect, useState } from 'react';
import { Empty, Typography } from '@revfluence/fresh';
import {
  isArray, isEmpty, join, map,
} from 'lodash';
import cx from 'classnames';
import { PhotoFilmIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './ContentHighlight.scss';
import Note from './Note';

const { Title, Text } = Typography;

const ContentHighlight = ({
  memberRecord, community, refetchMemberQuery, setbrandNoteError, userProfile, refetchUserProfile, socialProfileData, applicantReviewFields,
}) => {
  const [socialNetworkProfile, setSocialNetworkProfile] = useState(null);
  const [contentHighlights, setContentHighlights] = useState([]);
  const [hashTags, setHashTags] = useState<string>(null);
  const [postsToShow, setPosts] = useState([]);

  useEffect(() => {
    if (socialNetworkProfile) {
      const mentionedPosts = isArray(socialNetworkProfile[0].mentioned_posts)
        ? socialNetworkProfile[0].mentioned_posts
          .filter((post) => !isEmpty(post) && !isEmpty(post.image))
          .sort((a, b) => (a.ts > b.ts ? -1 : 1))
        : [];

      const recentPosts = isArray(socialNetworkProfile[0].posts)
        ? socialNetworkProfile[0].posts
          .filter((post) => !isEmpty(post) && !isEmpty(post.image))
          .sort((a, b) => (a.ts > b.ts ? -1 : 1))
        : [];

      const popularPosts = isArray(socialNetworkProfile[0].popular_posts)
        ? socialNetworkProfile[0].popular_posts
          .filter((post) => !isEmpty(post) && !isEmpty(post.image))
          .sort((a, b) => (a.ts > b.ts ? -1 : 1))
        : [];

      setPosts(!isEmpty(mentionedPosts) ? mentionedPosts : !isEmpty(popularPosts) ? popularPosts : recentPosts);
    }
  }, [socialNetworkProfile]);

  useEffect(() => {
    setSocialNetworkProfile(socialProfileData?.instagram || socialProfileData?.tiktok);
  }, [socialProfileData]);

  const TOP_POPULAR_POST_COUNT = 6;

  useEffect(() => {
    if (socialNetworkProfile && socialNetworkProfile.length == 1) {
      setContentHighlights(postsToShow.slice(0, TOP_POPULAR_POST_COUNT));
      setHashTags(join(socialNetworkProfile[0].top_hashtags, ' '));
    }
  }, [socialNetworkProfile, postsToShow]);
  return (
    <>
      <div className={cx(styles.contentHighlights)}>
        <div>
          <Title level={5} className={styles.title}>
            Content Highlights
          </Title>
          {
            !contentHighlights.length
              ? <Empty image={<PhotoFilmIcon />} size="small" description={<Typography.Paragraph type="secondary">No content highlights found</Typography.Paragraph>} />
              : (
                <div className={styles.highlightImages}>
                  {map(contentHighlights, (content) => (
                    <figure>
                      <img
                        src={content.image}
                        alt=""
                        title={content.link}
                        onClick={() => {
                          window.open(content.link);
                        }}
                      />
                    </figure>
                  ))}
                </div>
              )
          }
        </div>
        {hashTags && (
          <div className={styles.hashtags}>
            <Title level={5} className={styles.title}>
              <span>#</span>
              Hashtags
            </Title>
            <Text className={styles.hashtags}>{hashTags}</Text>
          </div>
        )}
      </div>
      <Note
        memberRecord={memberRecord}
        refetchMemberQuery={refetchMemberQuery}
        fields={community?.memberFieldSchemas}
        setbrandNoteError={setbrandNoteError}
        userProfile={userProfile}
        refetchUserProfile={refetchUserProfile}
        applicantReviewFields={applicantReviewFields}
      />
    </>
  );
};

export default ContentHighlight;
