import React from 'react';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';

export const PostsTab = () => (
  <div className="p-4">
    <H3>Posts Analytics</H3>
    <P>
      Analyze the performance of your social media posts. Track engagement metrics, reach, and impact across different
      content types and platforms.
    </P>
  </div>
  );
