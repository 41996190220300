import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Tabs, TabsContent } from '@frontend/shadcn/components/ui/tabs';
import { TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import { useAuth } from '@frontend/context/authContext';
import { ArrowsRotateIcon, SlidersIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
 Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger,
} from '@frontend/shadcn/components/ui/sheet';
import { z } from 'zod';
import { HeaderTabsList, HeaderTabsTrigger } from '../components/header';
import { SocialNetwork, SocialPostType } from '../../../gql/social/graphql';
import { useTabNavigation } from './hooks/useTabNavigation';
import { useAnalyticsData } from './hooks/useAnalyticsData';
import { useAnalyticsParams } from './hooks/useAnalyticsParams';
import { QueryVariables, DateType } from './types';
import { SummaryTab } from './components/tabs/SummaryTab';
import { PostsTab } from './components/tabs/PostsTab';
import { MembersTab } from './components/tabs/MembersTab';
import { NetworksTab } from './components/tabs/NetworksTab';
import { AnalyticsHeader } from './components/AnalyticsHeader';
import { FiltersForm } from './components/filters-form';
import { DateRangeForm } from './components/date-range-form';
import { filtersFormSchema } from './components/filters-form';

const defaultPostTypes: SocialPostType[] = [];
const defaultNetworks: SocialNetwork[] = [];

const DEFAULT_VARIABLES: Omit<QueryVariables, 'clientId'> = {
  dateType: 'thisWeek' as DateType,
  postTypes: defaultPostTypes,
  includeEstimates: false,
  networks: defaultNetworks,
};

export const SocialAnalytics = () => {
  const { tab } = useParams<{ tab: string }>();
  const { clientInfo } = useAuth();
  const location = useLocation();
  const { handleTabChange } = useTabNavigation();
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);

  const {
 queryVariables, handleApplyFilters, handleDateRangeSubmit, updateUrlParams,
} = useAnalyticsParams({
    clientId: clientInfo?.id,
    defaultVariables: DEFAULT_VARIABLES,
  });

  const {
 data, fetching, refetching, handleRefresh,
} = useAnalyticsData({
    clientId: clientInfo?.id,
    tab,
    queryVariables,
  });

  // Initialize URL params if they don't exist
  React.useEffect(() => {
    if (!location.search && clientInfo?.id) {
      const initialVariables = {
        ...DEFAULT_VARIABLES,
        clientId: clientInfo.id,
      };
      updateUrlParams(initialVariables);
    }
  }, [location.search, updateUrlParams, clientInfo?.id]);

  const handleCancelFilters = () => {
    setIsFiltersOpen(false);
  };

  const handleApplyFiltersAndClose = (formData: z.infer<typeof filtersFormSchema>) => {
    handleApplyFilters(formData);
    setIsFiltersOpen(false);
  };

  const headerActions = (
    <>
      <DateRangeForm
        onApply={handleDateRangeSubmit}
        defaultValues={{
          dateType: queryVariables.dateType,
          dateRange:
            queryVariables.startDate && queryVariables.endDate
              ? {
                  from: queryVariables.startDate,
                  to: queryVariables.endDate,
                }
              : undefined,
        }}
      />
      <Sheet open={isFiltersOpen} onOpenChange={setIsFiltersOpen}>
        <SheetTrigger asChild>
          <Button size="headerIcon" variant="outlineHeader">
            <SlidersIcon />
          </Button>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Filters</SheetTitle>
          </SheetHeader>
          <FiltersForm
            defaultValues={{
              network: queryVariables.networks,
              postTypes: queryVariables.postTypes,
            }}
            onApply={handleApplyFiltersAndClose}
            onCancel={handleCancelFilters}
          />
        </SheetContent>
      </Sheet>
      <Button size="default" variant="outlineHeader" disabled={refetching || tab !== 'members'} onClick={handleRefresh}>
        <ArrowsRotateIcon className={refetching ? 'mr-2 animate-spin' : 'mr-2'} />
        Refresh
      </Button>
    </>
  );

  const headerTabs = (
    <HeaderTabsList>
      <HeaderTabsTrigger value="summary">Summary</HeaderTabsTrigger>
      <HeaderTabsTrigger value="posts">Posts</HeaderTabsTrigger>
      <HeaderTabsTrigger value="members">Members</HeaderTabsTrigger>
      <HeaderTabsTrigger value="networks">Networks</HeaderTabsTrigger>
    </HeaderTabsList>
  );

  return (
    <TooltipProvider>
      <Tabs value={tab} onValueChange={handleTabChange}>
        <AnalyticsHeader actions={headerActions} tabs={headerTabs} />
        <div className="mt-4">
          <TabsContent value="summary">
            <SummaryTab />
          </TabsContent>
          <TabsContent value="posts">
            <PostsTab />
          </TabsContent>
          <TabsContent value="members">
            <MembersTab queryVariables={queryVariables} data={data} isLoading={fetching} />
          </TabsContent>
          <TabsContent value="networks">
            <NetworksTab />
          </TabsContent>
        </div>
      </Tabs>
    </TooltipProvider>
  );
};
