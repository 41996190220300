import * as React from 'react';
import { z } from 'zod';
import { DateRange } from 'react-day-picker';

export const dateTypes = [
  'thisWeek',
  'lastWeek',
  'lastMonth',
  'monthToDate',
  'lastQuarter',
  'quarterToDate',
  'last6Months',
  'yearToDate',
  'allTime',
  'custom',
] as const;

// Separate array for preset options (excludes 'custom')
export const presetDateTypes = dateTypes.filter((type) => type !== 'custom') as Exclude<
  typeof dateTypes[number],
  'custom'
>[];

export const dateTypeLabels: Record<typeof dateTypes[number], string> = {
  thisWeek: 'This Week',
  lastWeek: 'Last Week',
  lastMonth: 'Last Month',
  monthToDate: 'Month to Date',
  lastQuarter: 'Last Quarter',
  quarterToDate: 'Quarter to Date',
  last6Months: 'Last 6 Months',
  yearToDate: 'Year to Date',
  allTime: 'All Time',
  custom: 'Custom Range',
};

export const dateRangeSchema = z.object({
  dateType: z.enum(dateTypes),
  dateRange: z
    .object({
      from: z.string().optional(),
      to: z.string().optional(),
    })
    .optional(),
});

export type DateRangeFormData = z.infer<typeof dateRangeSchema>;

export interface DateRangeFormProps {
  className?: string;
  style?: React.CSSProperties;
  onApply: (values: DateRangeFormData) => void;
  defaultValues?: DateRangeFormData;
}

export interface DateRangeFormTriggerProps {
  className?: string;
  style?: React.CSSProperties;
  dateRange?: DateRangeFormData['dateRange'];
  open?: boolean;
  onClick?: () => void;
}

export interface DateRangeFormPresetsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

interface DateRangeFormCalendarBaseProps extends React.HTMLAttributes<HTMLDivElement> {
  dateRange?: DateRangeFormData['dateRange'];
  onDateRangeSelect: (range: DateRange | undefined) => void;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
}

export type DateRangeFormCalendarProps = DateRangeFormCalendarBaseProps;
