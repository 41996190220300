import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { EyeIcon } from '@revfluence/fresh-icons/regular/esm';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
} from '../../../components/highlight-cards';

export const impressionsCardFragment = graphql(`
  fragment ImpressionsCard_TotalInsights on TotalInsights {
    impressions
  }
`);

interface ImpressionsCardProps {
  data: FragmentType<typeof impressionsCardFragment> | null | undefined;
}

const ImpressionsCard: React.FC<ImpressionsCardProps> = ({ data }) => {
  const totalInsights = useFragment(impressionsCardFragment, data);
  const value = totalInsights?.impressions ?? 0;

  return (
    <Card>
      <HighlightCardHeader className="bg-purple-6">
        <HighlightCardTitle>
          <EyeIcon className="mr-2" />
          <span>Impressions</span>
        </HighlightCardTitle>
      </HighlightCardHeader>
      <HighlightCardContent>
        <HighlightCardContentRow>
          <HighlightCardContentColumn>
            <HighlightCardStatRow>
              <HighlightCardStat className="text-3xl leading-none">{formatNumberDefault(value)}</HighlightCardStat>
            </HighlightCardStatRow>
            <HighlightCardStatLabel>Total Impressions</HighlightCardStatLabel>
          </HighlightCardContentColumn>
        </HighlightCardContentRow>
      </HighlightCardContent>
    </Card>
  );
};

export default ImpressionsCard;
