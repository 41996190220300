import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Input } from '@frontend/shadcn/components/ui/input';
import { Label } from '@frontend/shadcn/components/ui/label';
import React from 'react';
import { useLandingPageContext } from '../../../../../context/LandingPageContext';

const TOTAL_CONTENT_LIMIT = 6;

const SettingsContent: React.FC = () => {
  const { landingPageData, setLandingPageData } = useLandingPageContext();

  const handleAllowUploadChange = (checked: boolean) => {
    setLandingPageData((prev) => ({
      ...prev,
      featuredContent: {
        ...prev.featuredContent,
        settings: {
          ...prev.featuredContent.settings,
          allowCreatorToUpload: checked,
          // Reset creator content count when toggling off
          noOfContentForCreator: checked ? prev.featuredContent.settings.noOfContentForCreator : 1,
        },
      },
    }));
  };

  const calculateMaxAvailable = () => {
    const currentUploadedCount = landingPageData.featuredContent.urls.length;
    const remainingSlots = TOTAL_CONTENT_LIMIT - currentUploadedCount;
    return Math.max(0, remainingSlots);
  };

  const maxAvailable = calculateMaxAvailable();

  const handleCreatorCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 1 && value <= maxAvailable) {
      setLandingPageData((prev) => ({
        ...prev,
        featuredContent: {
          ...prev.featuredContent,
          settings: {
            ...prev.featuredContent.settings,
            noOfContentForCreator: value,
          },
        },
      }));
    }
  };

  return (
    <div className="flex flex-col items-start gap-4 px-4">
      <div className="flex items-center gap-2 w-full">
        <Checkbox
          id="allow-upload"
          checked={landingPageData.featuredContent.settings.allowCreatorToUpload}
          onCheckedChange={handleAllowUploadChange}
          className="h-[18px] w-[18px] border-neutralgrey-6 bg-neutralgrey-6 text-neutral-color-palettegray-1"
        />
        <Label htmlFor="allow-upload" className="font-medium text-neutralgrey-6 text-sm leading-6">
          Allow Creators to upload content
        </Label>
      </div>
      {landingPageData.featuredContent.settings.allowCreatorToUpload && maxAvailable > 0 && (
        <div className="flex flex-col items-start gap-2 pl-6">
          <Label htmlFor="creator-count" className="text-neutralgrey-5 text-xs leading-5">
            Numbers of content creators can upload (max available
            {' '}
            {maxAvailable}
            )
          </Label>
          <Input
            id="creator-count"
            type="number"
            value={landingPageData.featuredContent.settings.noOfContentForCreator}
            onChange={handleCreatorCountChange}
            placeholder="Enter count for creators"
            min={1}
            max={maxAvailable}
            className="w-[100px] h-8 px-3 py-[5px] bg-neutralgrey-0 border-neutralgrey-2 text-neutralgrey-6"
          />
        </div>
      )}
      {maxAvailable === 0 && landingPageData.featuredContent.settings.allowCreatorToUpload && (
        <div className="pl-6 text-sm text-red-500">
          Maximum content limit reached. No more slots available for creators.
        </div>
      )}
    </div>
  );
};

export default SettingsContent;
