import * as React from 'react';

import {
  IShopifyPromoCodeFormValues, ProductCollections, PurchaseType, RecurringCycleLimit, TFormOptionData,
} from '@affiliates/components';
import {
  OFFER_PAYOUT_TYPE,
  OFFER_PRICE_RULE_TYPE,
  OFFER_PROMO_USAGE_LIMIT_RULE,
  OFFER_SOURCE, OFFER_STATUS,
} from '@affiliates/types/globalTypes';
import { useApolloClient, useGetProductCollections } from '@affiliates/hooks';
import { IToastRefHandles, LoadSpinner, Toast } from '@components';
import { logger } from '@common';
import moment from 'moment';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import { OfferFormContainer } from './OfferFormContainer';
import { IBaseProps, IChildArgs } from './types';
import { IShopifyCredentials } from '../../hooks';
import { GetConnectedShopify_clientConnections } from '../../queries/types/GetConnectedShopify';
import { GET_CONNECTED_SHOPIFY } from '../../queries/getConnectedShopifyQuery';

const { useState, useEffect, useRef } = React;
interface IProps extends IBaseProps<IShopifyPromoCodeFormValues> {
  shopifyCredentials: IShopifyCredentials;
  isSubscriptionEnable?: boolean;
  connectedAdvertiserForSync?: GetConnectedShopify_clientConnections[];
}

export const NewShopifyCodeContainer: React.FC<Readonly<IProps>> = (props) => {
  const [productCollectionOptions, setProductCollectionOptions] = useState<ProductCollections[]>(null);
  const [connectedShopifyData, setConnectedShopifyData] = useState<GetConnectedShopify_clientConnections[]>([]);
  const [isShopifyDataLoading, setIsShopifyDataLoading] = useState<boolean>(false);
  const staApolloClient = useApolloClient();
  const { migrateToGraphQL, enableMultipleShopify: isEnabledMultipleShopify } = useClientFeatures();
  const collectionsQuery = useGetProductCollections();
  const toastRef = useRef<IToastRefHandles>();

  useEffect(() => {
    if (isEnabledMultipleShopify) {
      (async () => {
        const { data, loading } = await staApolloClient.query({ query: GET_CONNECTED_SHOPIFY });
        if (data?.clientConnections) {
          const allConnections: GetConnectedShopify_clientConnections[] = data.clientConnections;
          setConnectedShopifyData(allConnections);
          setIsShopifyDataLoading(loading);
        }
      })();
    }
  }, [isEnabledMultipleShopify, staApolloClient]);

  useEffect(() => {
    if (isShopifyDataLoading) {
      return;
    }

    if (collectionsQuery?.data) {
      setProductCollectionOptions(collectionsQuery.data);
    }
    if (collectionsQuery.error) {
      logger.error(collectionsQuery.error.message);
      const toast = toastRef.current;
      toast.showMessage({
        content: 'There was an error loading Shopify collections. Please refresh the page and try again.',
        type: 'error',
      });
    }
  }, [collectionsQuery, setProductCollectionOptions, isShopifyDataLoading]);

  if (isShopifyDataLoading) {
    return <LoadSpinner />;
  }
  const initialValues: IChildArgs<IShopifyPromoCodeFormValues>['initialValues'] = {
    codeSuffix: '',
    description: '',
    flatPayout: null,
    groupName: '',
    imageUrl: null,
    name: '',
    payoutType: null,
    payoutOptions: [{
      label: '',
      isDefault: true,
      payoutType: OFFER_PAYOUT_TYPE.SALE,
      isMemberAdded: false,
    }],
    percentPayout: null,
    prefixType: null,
    priceRuleAmount: null,
    priceRuleType: OFFER_PRICE_RULE_TYPE.PERCENTAGE,
    source: OFFER_SOURCE.SHOPIFY,
    specialLimitNewCustomersOnly: false,
    specialLimitOnePerSale: false,
    specialLimitUsageCapAmount: null,
    specialLimitUsageCapEnabled: false,
    usageLimitAmount: null,
    usageLimitRule: OFFER_PROMO_USAGE_LIMIT_RULE.NONE,
    productCollections: [],
    status: OFFER_STATUS.ACTIVE,
    activeTime: moment(),
    activeDate: moment(),
    endDate: null,
    endTime: null,
    recurringCycleLimitAmount: 0,
    offerCodePurchaseRestrictionsRule: RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT,
    isEndDateEnable: false,
    isNewFlow: migrateToGraphQL,
    purchaseType: PurchaseType.ONE_TIME,
    isMultipleShopifySyncEnabled: false,
    isSameDiscountMultipleShopify: true,
    multiShopifyEnabled: false,
    clientsForSync: [],
    connectedAdvertiserForSync: connectedShopifyData.filter((c) => !c.isPrimary),
    allClientForSync: [],
    isUngrouped: false,
    lockEditing: [],
    productDiscounts: false,
    shippingDiscounts: false,
    orderDiscounts: false,
    isPromoLink: false,
    isLandingPageEnabled: false,
    landingPageUrl: null,
    shopifyRedirectUrl: null,
    isSecureCodes: false,
    linkedShopifyOfferId: '',
    isReadOnly: false,
    offerType: null,
  };

  const offerDetails: IChildArgs<IShopifyPromoCodeFormValues>['offerDetails'] = {
    hasMembers: false,
    id: null,
    imageUrl: null,
    isDeleted: false,
    isExpired: false,
    linkedId: null,
  };

  const formOptionData: TFormOptionData = {
    productCollectionOptions,
  };

  return (
    <>
      <OfferFormContainer
        initialValues={initialValues}
        offerDetails={offerDetails}
        shopifyCredentials={props.shopifyCredentials}
        formOptionData={formOptionData}
        isSubscriptionEnable={props.isSubscriptionEnable}
        {...props}
      />
      <Toast ref={toastRef} useFresh />
    </>

  );
};
