import { useState } from 'react';
import * as React from 'react';
import { isNull, trim } from 'lodash';
import {
 Alert, Button, Col, Collapse, Divider, Row, Typography,
} from '@revfluence/fresh';

import { OFFER_PRICE_RULE_TYPE, OFFER_STATUS } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import {
 Form, FormInstance, Input, Select, Tooltip,
} from 'antd';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { LockIcon } from '@revfluence/fresh-icons/solid/esm';
import { OFFER_FORM_LOCKING_KEY } from '@frontend/applications/AffiliatesApp/types';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatures } from '@frontend/context/ClientFeatureContext';
import {
  OfferCodeOption,
  AddPrefix,
  OfferCodePrefix,
  OfferPurchaseType,
  OfferEndDate,
  OfferRecurringPaymentForSubscriptions,
  MultipleDiscountOptions,
} from './FormElements';
import {
  OfferCodeCollectionOptions,
  OfferCodeSuffix,
  OfferCodeGroupName,
  OfferCodeLimitNewCustomersOnly,
  OfferCodeLimitOnePerCustomer,
  OfferCodeLimitCodeUses,
  OfferCodePurchaseRestrictions,
  OfferCodeDiscountOptions,
  OfferPromoCodeCombinations,
} from '../ShopifyPromoCodeSection/FormElements';
import {
  FormAsyncAction,
  IShopifyPromoCodeFormOptions,
  IShopifyPromoCodeFormValues,
  OfferFormModes,
  PrefixTypeStrategy,
  PROMO_OFFER_TYPES,
  TDisabledMap,
} from '../../types';
import { AddSuffix } from './FormElements/AddSufix';

import styles from '../../OfferForm.scss';
import { OfferSync } from './FormElements/OfferSync';
import { MultipleShopifyStores } from './FormElements/MultipleShopifyStores';
import { NewOfferActiveDate, OldOfferActiveDate } from './FormGroup';
import { LinksForPromos } from './FormElements/LinksForPromos';

const { useMemo } = React;
const { Text, Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

interface IProps {
  disabled: TDisabledMap<IShopifyPromoCodeFormValues>;
  onFieldFocused: (type: string, selected: boolean) => void;
  showStatus: boolean;
  values: IShopifyPromoCodeFormValues;
  formOptionData: IShopifyPromoCodeFormOptions;
  isMigrationEnabled?: boolean;
  isSubscriptionEnable?: boolean;
  mode: OfferFormModes;
  formRef: FormInstance;
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[];
  connectedClients: GetConnectedShopify_clientConnections[];
  isNewFlow?: boolean;
  isEnabledMultipleShopify?: boolean;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  hasMembers?: boolean;
  shopifyStoreName?: string,
  isOfferPromoLink?: boolean,
  isLandingPageEnabled?: boolean,
}

export const ShopifyPromoCodeSectionNew: React.FC<Readonly<IProps>> = ({
  disabled,
  values,
  formOptionData,
  isSubscriptionEnable,
  connectedAdvertiserForSync,
  isNewFlow,
  handleFormAsyncActions,
  hasMembers,
  mode,
  formRef,
  connectedClients,
  shopifyStoreName,
  isOfferPromoLink,
  isLandingPageEnabled,
}) => {
  const [addPrefixChecked, setAddPrefixChecked] = useState(
    !(values.codePrefix === '' || values.codePrefix === undefined || values.codePrefix === null),
  );
  const [addSuffixChecked, setAddSuffixChecked] = useState(values.codeSuffix !== '');
  const [offerEndDate, setOfferEndDate] = useState(values.endDate !== null);
  const [offerType, setOfferType] = useState(null);
  const linkShopifyOffer = useClientFeatureEnabled(ClientFeature.LINK_SHOPIFY_OFFER);
  const handleOfferTypeChange = (value) => setOfferType(value);
  const isLinkedShopifyOfferSelected = linkShopifyOffer && offerType === 'linkedShopifyOffer';

  const [redirectError, setRedirectError] = useState(false);
  const { linkForPromo, secureCodes, utmSupportLinkForPromo } = useClientFeatures();
  const { migrateToGraphQL, enableMultipleShopify: isEnabledMultipleShopify } = useClientFeatures();
  const dateErrorMsg = values.endDate === null
      ? ''
      : values.activeDate.isSame(values.endDate, 'day')
      ? values.activeDate.isAfter(values.endDate, 'minute') || values.activeDate.isSame(values.endDate, 'minute')
        ? 'Start time must be before end time'
        : ''
      : values.activeDate.isSameOrAfter(values.endDate)
      ? 'Start date must be before end date'
      : '';

  const codeSuffix = useMemo(() => {
    if (addSuffixChecked) {
      values.isSuffixSelected = true;
      return (
        <div className={styles.codeSuffixWrapper}>
          <OfferCodeSuffix disabled={disabled.codeSuffix} name="codeSuffix" required />
        </div>
      );
    } else {
      // values.codeSuffix = '';
      values.isSuffixSelected = false;
    }
    return null;
  }, [addSuffixChecked, disabled.codeSuffix, values]);

  const codePrefix = useMemo(() => {
    if (addPrefixChecked) {
      values.isPrefixSelected = true;
      return (
        <div className={styles.codeSuffixWrapper}>
          <OfferCodePrefix disabled={disabled.codePrefix} name="codePrefix" />
        </div>
      );
    } else {
      values.isPrefixSelected = false;
      // values.codePrefix = '';
    }
    return null;
  }, [addPrefixChecked, disabled.codePrefix, values]);

  const endDate = useMemo(() => {
    if (offerEndDate) {
      values.isEndDateEnable = true;
      return (
        <>
          <div className={styles.CustomTextColor}>
            <Text>End Date</Text>
          </div>
          <Row>
            <Col span={24}>
              <OfferEndDate disabled={false} name="endDate" startDate={values.activeDate} />
            </Col>
          </Row>
        </>
      );
    } else {
      values.isEndDateEnable = false;
    }
    return null;
  }, [offerEndDate, values]);

  const enablePrifxAndSuffix = useMemo(() => {
    if (values.prefixType) {
      return false;
    }
    return true;
  }, [values.prefixType]);

  const promoExample = useMemo((): React.ReactNode => {
    let examplePromoCode = '';
    switch (values.prefixType) {
      case PrefixTypeStrategy.INSTAGRAM_ONLY:
        examplePromoCode = 'Instagramusername';
        break;
      case PrefixTypeStrategy.FULL_NAME_ONLY:
        examplePromoCode = 'Fullname';
        break;
      case PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_ONLY:
        examplePromoCode = 'Jsmith';
        break;
      case PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_ONLY:
        examplePromoCode = 'Sarahm';
        break;
      default:
        return 'summer20';
    }
    let example = '';
    if (addSuffixChecked && addPrefixChecked) {
      example = `${values?.codePrefix ?? ''}${examplePromoCode}${values?.codeSuffix ?? ''}`;
    } else if (addSuffixChecked) {
      example = `${examplePromoCode}${values?.codeSuffix ?? ''}`;
    } else if (addPrefixChecked) {
      example = `${values?.codePrefix ?? ''}${examplePromoCode}`;
    } else {
      example = examplePromoCode;
    }

    return trim(example.toUpperCase());
  }, [values.prefixType, values.codePrefix, values.codeSuffix, addPrefixChecked, addSuffixChecked]);

  const connectedStore: Set<string> = new Set<string>();
  const connectedSubscriptionEnabled = useMemo(() => {
    if (values.isMultipleShopifySyncEnabled && connectedClients?.length) {
      const connectedAdvertiserIds = values.clientsForSync.map((obj) => obj.advertiserId);
      const allEligible = connectedAdvertiserIds.every((advertiserId) => {
        const connection = connectedClients.find((conn) => conn.connectedAdvertiserId === advertiserId);
        if (!connection.isSubscriptionEligible) {
          connectedStore.add(connection.label);
        }
        return connection ? connection.isSubscriptionEligible : false;
      });
      return allEligible && isSubscriptionEnable;
    } else {
      return isSubscriptionEnable;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    connectedClients,
    values.isMultipleShopifySyncEnabled,
    values.clientsForSync.length,
    isSubscriptionEnable,
    connectedStore,
  ]);
  // eslint-enable-next-line react-hooks/exhaustive-deps
  const isAtLeastOneConnected = connectedClients?.some((item) => item.isPrimary === false && item.isConnected === true);
  return (
    <>
      <Collapse defaultActiveKey={['3', '4', '5', '6', '7', '8', '9', '10', '11', '12']} ghost>
        {/* Discount and offer type components based on feature flag: If linkShopifyOffer enabled use new UI else prev UI for discounts */}
        {linkShopifyOffer ? (
          <>
            <Panel className={styles.formSection} key={4} header="Offer Type">
              <div className={styles.headerSpace}>
                <Text className={styles.descTextColor}>
                  Choose the type of offer you want to create. By default, Aspire supports creating Amount Off offers.
                  Alternatively, you can now connect an existing Buy X, Get Y offer from Shopify also.
                </Text>
              </div>
              {values.isMultipleShopifySyncEnabled && values.priceRuleType === OFFER_PRICE_RULE_TYPE.AMOUNT && (
                <div className={styles.AlertWrapper}>
                  <Alert
                    message="Discount type have been changed to percentage only. Please update the discount. Changes will not be saved till you update the offer."
                    type="warning"
                  />
                </div>
              )}

              <Form.Item label="Select Offer Type" className="" name="offerType">
                <Select
                  onChange={handleOfferTypeChange}
                  size="large"
                  placeholder="Select offer type"
                  disabled={disabled.offerType}
                >
                  <Option value={PROMO_OFFER_TYPES.AMOUNT_OFF}>Amount Off Purchase</Option>
                  <Option value={PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER}>Connect Existing Buy X, Get Y Offer</Option>
                </Select>
              </Form.Item>

              {values.offerType === PROMO_OFFER_TYPES.AMOUNT_OFF && (
                <>
                  <OfferCodeDiscountOptions
                    disabled={disabled.priceRuleType}
                    name="priceRuleType"
                    value={values.priceRuleType}
                    isNewFlow={isNewFlow}
                    isMultipleShopifyEnabled={values.isMultipleShopifySyncEnabled}
                    mode={mode}
                  />
                </>
              )}

              {values.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER && (
                <Form.Item
                  label={(
                    <span>
                      Shopify Discount Id
                      <Tooltip title="Enter the Shopify Discount ID for your 'Buy X, Get Y' offer. You can find this ID in the offer URL in your Shopify admin under the Discounts section.">
                        <InfoCircleOutlined className="ml-2" />
                      </Tooltip>
                    </span>
                  )}
                  name="linkedShopifyOfferId"
                >
                  <Input
                    disabled={disabled.linkedShopifyOfferId}
                    placeholder="Enter Shopify Discount ID for Buy X, Get Y Offer"
                    size="large"
                  />
                </Form.Item>
              )}

              {isEnabledMultipleShopify && values.isMultipleShopifySyncEnabled && (
                <MultipleDiscountOptions
                  disabled={!!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY)}
                  values={values}
                  handleFormAsyncActions={handleFormAsyncActions}
                  formRef={formRef}
                  connectedAdvertiserForSync={connectedAdvertiserForSync}
                />
              )}
            </Panel>
          </>
        ) : (
          <>
            <Panel className={styles.formSection} key={5} header="Discount Type and Value">
              <div className={styles.headerSpace}>
                <Text className={styles.descTextColor}>Choose the discount type (Flat/Percentage) for the offer. </Text>
              </div>
              {values.isMultipleShopifySyncEnabled && values.priceRuleType === OFFER_PRICE_RULE_TYPE.AMOUNT && (
                <div className={styles.AlertWrapper}>
                  <Alert
                    message="Discount type have been changed to percentage only. Please update the discount. Changes will not be saved till you update the offer."
                    type="warning"
                  />
                </div>
              )}
              <OfferCodeDiscountOptions
                disabled={disabled.priceRuleType}
                name="priceRuleType"
                value={values.priceRuleType}
                isNewFlow={isNewFlow}
                isMultipleShopifyEnabled={values.isMultipleShopifySyncEnabled}
                mode={mode}
              />

              {isEnabledMultipleShopify && values.isMultipleShopifySyncEnabled && (
                <MultipleDiscountOptions
                  disabled={!!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY)}
                  values={values}
                  handleFormAsyncActions={handleFormAsyncActions}
                  formRef={formRef}
                  connectedAdvertiserForSync={connectedAdvertiserForSync}
                />
              )}
            </Panel>
          </>
        )}
        <Panel
          className={`${styles.formSection} ${styles.promoCodeContainer}`}
          key={3}
          header="Promo Code Naming Strategy"
        >
          <div className={styles.headerSpace}>
            <Text className={styles.descTextColor}>
              Create a naming strategy for promo codes. All the promo codes will be generated in compliance with these
              rules.
            </Text>
          </div>
          <OfferCodeGroupName name="groupName" />
          <OfferCodeOption halfRow={!isNull(codeSuffix)} name="prefixType" mode={mode} />
          <AddPrefix
            name="codePrefix"
            disabled={enablePrifxAndSuffix || disabled.codePrefix}
            onAddPrefixChange={setAddPrefixChecked}
            checked={addPrefixChecked}
          />
          {codePrefix}
          <AddSuffix
            name="codeSuffix"
            disabled={enablePrifxAndSuffix || disabled.codeSuffix}
            onAddSuffixChange={setAddSuffixChecked}
            checked={addSuffixChecked}
          />
          {codeSuffix}

          {!enablePrifxAndSuffix && (
            <div className={styles.samplePromoContainer}>
              <Text strong type="secondary">
                Promo Code Example:
              </Text>
              <br />
              <Text strong>{promoExample}</Text>
            </div>
          )}
        </Panel>
        {linkForPromo && (
          <Panel className={`${styles.formSection} !py-2`} key={12} header="Generate Unique Links for Promo codes">
            <LinksForPromos
              formValues={values}
              handleFormAsyncActions={handleFormAsyncActions}
              shopifyStoreName={shopifyStoreName}
              setRedirectError={setRedirectError}
              redirectError={redirectError}
              secureCodes={secureCodes}
              mode={mode}
              hasMembers={hasMembers}
              isOfferPromoLink={isOfferPromoLink}
              isUtmSupport={utmSupportLinkForPromo}
              isLandingPageEnabled={isLandingPageEnabled}
            />
          </Panel>
        )}
        {isEnabledMultipleShopify && isAtLeastOneConnected && (
          <Panel
            className={styles.formSection}
            key={10}
            header={
              isLinkedShopifyOfferSelected
                ? 'Connect other shopify stores discounts'
                : 'Sync offer to other Shopify stores'
            }
          >
            {values.isLandingPageEnabled && (
              <div className="mb-4">
                <Alert
                  message="Multiple Shopify is not available"
                  description="We currently don't support multiple Shopify stores with Landing Pages. This feature will be available soon."
                  type="info"
                  showIcon
                />
              </div>
            )}
            <OfferSync
              disabled={
                !values.isNewFlow
                || !!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY)
                || values.status === OFFER_STATUS.PAUSED
                // || values.isSecureCodes
                || values.isLandingPageEnabled
              }
              name="multiShopifyEnabled"
              handleFormAsyncActions={handleFormAsyncActions}
              isMultipleShopifySyncEnabled={values.isMultipleShopifySyncEnabled}
              hasMembers={hasMembers}
              isLinkedShopifyOfferSelected={isLinkedShopifyOfferSelected}
              isLandingPageEnabled={values.isLandingPageEnabled}
            />
            {values.isMultipleShopifySyncEnabled && (
              <>
                <Title level={5}>Select Shopify Stores</Title>
                <MultipleShopifyStores
                  disabled={
                    !!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY)
                    || values.status === OFFER_STATUS.PAUSED
                  }
                  mode={mode}
                  values={values}
                  handleFormAsyncActions={handleFormAsyncActions}
                  formRef={formRef}
                  connectedAdvertiserForSync={connectedAdvertiserForSync}
                  offerType={values.offerType}
                />
              </>
            )}
            {!!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY) && (
              <Alert
                icon={<LockIcon scale={1.5} />}
                description="Offer sync is currently locked for editing while date settings are being modified. To change offer sync options, please update or cancel changes to date settings."
                type="warning"
              />
            )}
          </Panel>
        )}
        {!isLinkedShopifyOfferSelected && (
          <Panel className={`${styles.formSection} ${styles.purchaseTypeContainer}`} key={6} header="Purchase Type">
            <div className={styles.headerSpace}>
              <Text className={styles.descTextColor}>
                Choose the purchase type for your offer, you can opt for one-time purchases, subscriptions, or a
                combination of both.
              </Text>
            </div>

            <OfferPurchaseType disabled={!connectedSubscriptionEnabled} name="purchaseType" />
            {!connectedSubscriptionEnabled && (
              <div className={styles.AlertWrapper}>
                <Alert
                  message=""
                  showIcon
                  description={(
                    <Text>
                      To create subscription-based offer, your connected Shopify store
                      {' '}
                      <strong>{Array.from(connectedStore).join(', ')}</strong>
                      {' '}
                      must have the subscription feature
                      enabled.
                    </Text>
                  )}
                  type="warning"
                  subAction
                  action={(
                    <a
                      href="https://help.shopify.com/en/manual/products/purchase-options/subscriptions/setup"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button style={{ background: '#fffbe6' }} disabled={false}>
                        Enable subscription in Shopify
                      </Button>
                    </a>
                  )}
                />
              </div>
            )}
            {!values.isMultipleShopifySyncEnabled && (
              <>
                <Divider className={styles.dividerColor} />
                <OfferCodeCollectionOptions
                  collections={formOptionData.productCollectionOptions}
                  name="productCollections"
                  value={values.productCollections}
                  disabled={false}
                />
              </>
            )}
          </Panel>
        )}
        {!isLinkedShopifyOfferSelected && (
          <Panel className={styles.formSection} key={7} header="Additional Settings">
            <div className={styles.headerSpace}>
              <Text className={styles.descTextColor}>Manage the usage and access of Promo Codes.</Text>
            </div>
            {(values?.purchaseType === 'Subscription' || values?.purchaseType === 'Both') && (
              <OfferRecurringPaymentForSubscriptions
                value={values.offerCodePurchaseRestrictionsRule}
                disabled={false}
                name="offerCodePurchaseRestrictionsRule"
              />
            )}
            <OfferCodePurchaseRestrictions disabled={false} name="usageLimitRule" value={values.usageLimitRule} />
            <OfferCodeLimitOnePerCustomer disabled={values.isSecureCodes} name="specialLimitOnePerSale" />
            <OfferCodeLimitNewCustomersOnly disabled={false} name="specialLimitNewCustomersOnly" />
            <OfferCodeLimitCodeUses
              disabled={values.isSecureCodes}
              name="specialLimitUsageCapEnabled"
              value={!!values.specialLimitUsageCapEnabled}
            />
          </Panel>
        )}
        {!isLinkedShopifyOfferSelected && (
          <Panel className={styles.formSection} key={8} header="Combination">
            <div className={styles.headerSpace}>
              <Text className={styles.descTextColor}>Promo codes for this offer can be combined with</Text>
            </div>
            <OfferPromoCodeCombinations
              value={values}
              handleFormAsyncActions={handleFormAsyncActions}
              disabled={false}
            />
          </Panel>
        )}
        {!migrateToGraphQL && (
          <Panel className={`${styles.formSection} ${styles.offerStatusContainer}`} key={9} header="Offer Status">
            <OldOfferActiveDate
              values={values}
              dateErrorMsg={dateErrorMsg}
              endDate={endDate}
              offerEndDate={offerEndDate}
              setOfferEndDate={setOfferEndDate}
            />
          </Panel>
        )}
        {!isLinkedShopifyOfferSelected && (
          <Panel
            className={`${styles.formSection} ${styles.offerStatusContainer}`}
            key={11}
            header="Promo Code Activate Date"
          >
            <NewOfferActiveDate
              values={values}
              dateErrorMsg={dateErrorMsg}
              endDate={endDate}
              offerEndDate={offerEndDate}
              setOfferEndDate={setOfferEndDate}
              handleFormAsyncActions={handleFormAsyncActions}
            />
          </Panel>
        )}
      </Collapse>
    </>
  );
};
