import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col, Empty, Image, Input, Modal, Row, Select, Space, Typography, message,
} from '@revfluence/fresh';
import {
  BoxesStackedIcon,
 ChevronDownIcon, ChevronUpIcon, PlusIcon, XmarkIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { pluralize } from '@frontend/app/utils/strings';
import { Spin } from 'antd';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { CatalogSelectionRuleConditionOperationType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { v4 as uuidv4 } from 'uuid';
import { LazyQueryExecFunction } from '@apollo/client';
import { GetProductsFromShopify, GetProductsFromShopifyVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductsFromShopify';
import { GET_IMPORTED_PRODUCTS } from '@frontend/applications/ProductFulfillmentApp/queries/importedProducts';
import { GET_IMPORTED_PRODUCTS_STATS } from '@frontend/applications/ProductFulfillmentApp/queries';
import { GET_PRODUCT_DETAILS } from '@frontend/applications/ProductFulfillmentApp/queries/productDetails';
import { GET_COLLECTION_DETAILS } from '@frontend/applications/ProductFulfillmentApp/queries/collectionDetails';
import { GET_IMPORTED_COLLECTIONS_STATS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/shopifyCollections';
import { GET_IMPORTED_COLLECTIONS_STATUSES_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import styles from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImport.scss';
import { Condition, useFindAndImportContext, useConditionById } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/FindAndImportContext';
import { useCreateCustomCollectionImport } from '@frontend/app/containers/Settings/ProductFulfillment/Products/hooks/useCreateCustomCollectionImport';
import { useGetImportedProductsStats } from '@frontend/app/containers/Settings/ProductFulfillment/Products/hooks/useGetAllProductsStats';
import { useProductsContext } from '@frontend/app/containers/Settings/ProductFulfillment/Products/ProductsContext';
import { Pagination } from '@frontend/app/containers/Settings/ProductFulfillment/Products/FindAndImport/Pagination';
import { useGetProductsFromShopifyForLandingPages } from '@frontend/applications/AffiliatesApp/pages/LandingPage/hooks/useGetProductsFromShopifyForLandingPages';

const { Text, Title } = Typography;

const PAGE_SIZE = 50;

export interface ConditionRowProps {
  id: string;
}
export const ConditionRow = ({ id }: ConditionRowProps) => {
  const {
    setConditionById,
    setTags,
    setVendors,
    setProductTypes,
    setConditions,
    conditions,
    tags,
    vendors,
    productTypes,
    setIsSelectionChanged,
   } = useFindAndImportContext();

  const { condition, index } = useConditionById(id);

  const selectedValues = condition.type === 'tags'
    ? tags
    : condition.type === 'vendors'
      ? vendors
      : productTypes;

  const handleConditionChange = (value: Condition['type']) => {
    setConditionById(id, value);
    setIsSelectionChanged(true);
  };

  const handleValueChange = (values: string[]) => {
    if (condition.type === 'tags') {
      setTags(values);
    } else if (condition.type === 'vendors') {
      setVendors(values);
    } else if (condition.type === 'productTypes') {
      setProductTypes(values);
    }
    setIsSelectionChanged(true);
  };

  const handleRemoveCondition = () => {
    const newConditions = conditions.filter((c) => c.id !== id);
    setConditions(newConditions);
  };

  const isConditionPresent = (type: Condition['type']) => !!conditions.find((c) => c.type === type);

  return (
    <Row wrap={false}>
      <Col span={6}>
        <Select
          placeholder="Select a filter"
          value={condition.type}
          onChange={handleConditionChange}
          className={styles.conditionSelect}
        >
          <Select.Option value="tags" disabled={isConditionPresent('tags')}>Tag</Select.Option>
          <Select.Option value="vendors" disabled={isConditionPresent('vendors')}>Vendor</Select.Option>
          <Select.Option value="productTypes" disabled={isConditionPresent('productTypes')}>Product Type</Select.Option>
        </Select>
      </Col>
      <Col flex={1}>
        <Select
          mode="tags"
          placeholder="Press enter after typing to add a value"
          style={{ width: '100%' }}
          value={selectedValues}
          onChange={handleValueChange}
          dropdownStyle={{ display: 'none' }}
          disabled={!condition.type}
        />
      </Col>
      {index !== 0 && (
        <Col>
          <Button type="text" icon={<XmarkIcon />} onClick={handleRemoveCondition} />
        </Col>
      )}
    </Row>
  );
};

export interface FindContainerProps {
  fetchShopifyProducts: LazyQueryExecFunction<GetProductsFromShopify, GetProductsFromShopifyVariables>
  isShopifyProductsLoading: boolean
  cantImportReason: string
}
export const FindContainer = ({ fetchShopifyProducts, isShopifyProductsLoading }: FindContainerProps) => {
  const {
 conditions, setConditions, tags, vendors, productTypes, operation, setOperation, resetPagination, setIsSelectionChanged, isSelectionChanged, setIsFindClicked,
} = useFindAndImportContext();

  const [isConditionOpen, setIsConditionOpen] = useState(true);

  const handleAddCondition = () => {
    const newCondition: Condition = {
      id: uuidv4(),
      type: null,
    };
    setConditions([...conditions, newCondition]);
  };

  const handleOperationChange = (value: CatalogSelectionRuleConditionOperationType) => {
    setOperation(value);
    setIsSelectionChanged(true);
  };

  const handleFindProducts = async () => {
    await fetchShopifyProducts({
      variables: {
        shopifyProductsFilter: {
          tags,
          vendors,
          productTypes,
          operation,
        },
      },
      fetchPolicy: 'network-only',
    });
    resetPagination();
    setIsSelectionChanged(false);
    setIsFindClicked(true);
  };

  const handleShowConditions = () => {
    setIsConditionOpen((prev) => !prev);
  };

  const hasCondition = !!(tags.length || vendors.length || productTypes.length);

  return (
    <div className={styles.findContainer}>
      <Row align="middle" justify="space-between" className="mb-3">
        <Col className="flex items-center gap-4">
          <Text weight="semibold"> Products must match</Text>
          <Select value={operation} onChange={handleOperationChange} bordered={false}>
            <Select.Option value={CatalogSelectionRuleConditionOperationType.OR}>
              any condition
            </Select.Option>
            <Select.Option value={CatalogSelectionRuleConditionOperationType.AND}>
              all conditions
            </Select.Option>
          </Select>
        </Col>
        <Col>
          <Button type="text" icon={isConditionOpen ? <ChevronUpIcon /> : <ChevronDownIcon />} onClick={handleShowConditions} size="small" />
        </Col>
      </Row>
      {
        isConditionOpen && conditions.map((condition) => (
          <ConditionRow key={condition.id} id={condition.id} />
        ))
      }
      {isConditionOpen && (
      <Row align="middle" justify="space-between">
        <Col>
          <Button icon={<PlusIcon />} disabled={conditions.length === 3} onClick={handleAddCondition} type="link" className="!text-primary">
            Add condition
          </Button>
        </Col>
        <Col>
          <Space>
            {(!hasCondition || isSelectionChanged) && (
              <Button
                onClick={handleFindProducts}
                loading={isShopifyProductsLoading}
                size="small"
                disabled={!isSelectionChanged || !hasCondition}
              >
                Find Products
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    )}
    </div>
  );
};

export const FindAndImportModal = () => {
  const {
 pageNumber, visited, setPageNumber, setVisited, resetState, screen, collectionName, setCollectionName, setScreen,
 tags, productTypes, vendors, operation, isFindClicked,
 excludeProductIds, setExcludeProductIds,
} = useFindAndImportContext();

  const { isFindAndImportOpen, setIsFindAndImportOpen } = useProductsContext();

  const { clientConfig, loading: isClientConfigLoading } = useGetClientConfig();

  const { data: productStats, loading: isStatsLoading, refetch: refetchStats } = useGetImportedProductsStats();

  const { createCustomCollectionImport, isCreatingCustomCollectionImport } = useCreateCustomCollectionImport({
    onCompleted: () => {
      handleClose();
      message.success('Product import has been started.');
    },
  });

  const {
 fetchShopifyProducts, isShopifyProductsLoading, shopifyProductsResp, fetchMoreShopifyProducts,
} = useGetProductsFromShopifyForLandingPages();

  const paginatedShopifyProducts = shopifyProductsResp?.shopifyProducts?.slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE);

  useEffect(() => {
    refetchStats();
  }, [isFindAndImportOpen, refetchStats]);

  const importLimit = clientConfig?.importSyncSettings?.productsImportLimit || 50_000;

  const isProductMaxLimitReached = productStats?.shopifyProductsCount >= importLimit;
  const availableProductLimit = importLimit - (productStats?.shopifyProductsCount || 0);

  const isLoading = isClientConfigLoading || isStatsLoading;

  const hasNextPage = pageNumber * PAGE_SIZE < shopifyProductsResp?.totalCount;
  const hasPreviousPage = pageNumber > 1;

  const handleNextPage = async () => {
    if (!visited[pageNumber + 1]) {
      await fetchMoreShopifyProducts({
        variables: {
          afterCursor: shopifyProductsResp?.pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getProductsFromShopify: {
              ...fetchMoreResult.getProductsFromShopify,
              shopifyProducts: [
                ...prev.getProductsFromShopify.shopifyProducts,
                ...fetchMoreResult.getProductsFromShopify.shopifyProducts,
              ],
            },
          };
        },
      });
      setVisited(pageNumber + 1);
    }
    setPageNumber(pageNumber + 1);
  };
  const handlePrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const handleClose = () => {
    setIsFindAndImportOpen(false);
    resetState();
  };

  const handleCollectionNameChange = (name: string) => {
    setCollectionName(name);
  };

  const handleScreenChange = (screen: 'find' | 'import') => {
    setScreen(screen);
  };

  const handleSave = () => {
    createCustomCollectionImport({
      variables: {
        customCollectionName: collectionName,
        shopifyProductsFilter: {
          tags,
          vendors,
          productTypes,
          operation,
        },
        excludeProductIds,
      },
      refetchQueries: [
        GET_IMPORTED_PRODUCTS,
        GET_IMPORTED_PRODUCTS_STATS,
        GET_PRODUCT_DETAILS,
        GET_COLLECTION_DETAILS,
        GET_IMPORTED_COLLECTIONS_STATS_QUERY,
        GET_IMPORTED_COLLECTIONS_STATUSES_QUERY,
      ],
    });
  };

  const handleExcludeProduct = (productId: string) => {
    const excluded = excludeProductIds.includes(productId);
    if (excluded) {
      setExcludeProductIds(excludeProductIds.filter((id) => id !== productId));
    } else {
      setExcludeProductIds([...excludeProductIds, productId]);
    }
  };

  let cantImportReason = '';
  if (availableProductLimit - (shopifyProductsResp?.totalCount || 0) < 0) {
    cantImportReason = 'Found products are more than available product limit. Please adjust your filters and try again.';
  } else if (!shopifyProductsResp?.totalCount) {
    cantImportReason = 'No products found';
  }

  return (
    <Modal
      title={(
        <Space className={styles.header}>
          <Title level={4}>Select Featured Product</Title>
        </Space>
      )}
      open={isFindAndImportOpen}
      onCancel={handleClose}
      width={699}
      className={styles.FindAndImport}
      footer={null}
    >
      {screen === 'find' && (
        <Space direction="vertical">
          {!isLoading && isProductMaxLimitReached && <Alert message={`Maximum product limit reached. You can import up to ${importLimit} products only.`} type="error" showIcon />}
          <FindContainer
            fetchShopifyProducts={fetchShopifyProducts}
            isShopifyProductsLoading={isShopifyProductsLoading}
            cantImportReason={cantImportReason}
          />
          {isShopifyProductsLoading ? (
            <Row justify="center" align="middle" className={styles.loaderContainer}>
              <Col>
                <Space direction="vertical" className={styles.space}>
                  <Spin size="large" />
                  <Text>Fetching the products...</Text>
                </Space>
              </Col>
            </Row>
          ) : isFindClicked && (
            shopifyProductsResp?.totalCount ? (
              <>
                <Space className={styles.paginationContainer}>
                  <Text weight="semibold">
                    {shopifyProductsResp?.totalCount}
                    {' '}
                    {pluralize(shopifyProductsResp?.totalCount || 0, 'Product')}
                    {' '}
                    (
                    {shopifyProductsResp.totalCount - excludeProductIds.length}
                    {' '}
                    selected)
                  </Text>
                  <Pagination
                    page={pageNumber}
                    pageSize={PAGE_SIZE}
                    itemsOnPage={paginatedShopifyProducts?.length}
                    items={shopifyProductsResp?.totalCount}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    onNext={handleNextPage}
                    onPrev={handlePrevPage}
                  />
                </Space>
                <Space direction="vertical" className={styles.collectionTable}>
                  {paginatedShopifyProducts?.map((product) => (
                    <div className={styles.collectionListItem} key={product.id}>
                      <Checkbox
                        checked={!excludeProductIds.includes(product.id)}
                        onChange={() => handleExcludeProduct(product.id)}
                      />
                      <Image src={product.imgUrl || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'} width={40} height={40} preview={false} />
                      <div className={styles.collectionListItemTitle}>
                        <Text weight="semibold">{product.title}</Text>
                        <Text type="secondary">
                          {product.variantCount}
                          {' '}
                          {pluralize(product.variantCount, 'Variant')}
                        </Text>
                      </div>
                    </div>
                  ))}
                </Space>
              </>
            ) : (
              <Row justify="center" align="middle" className={styles.loaderContainer}>
                <Col>
                  <Empty
                    size="small"
                    description={(
                      <Space direction="vertical">
                        <span style={{ fontSize: '14px' }}>
                          No products found.
                        </span>
                        <Text type="secondary">
                          Please adjust your filters and try again.
                        </Text>
                      </Space>
                    )}
                    image={<BoxesStackedIcon />}
                  />
                </Col>
              </Row>
            )
          )}
        </Space>
      )}
      {screen === 'import' && (
        <div className={styles.findContainer}>
          <Text weight="semibold">
            Import
            {' '}
            {shopifyProductsResp?.totalCount}
            {' '}
            {pluralize(shopifyProductsResp?.totalCount, 'Product')}
          </Text>
          <Input
            placeholder="Add a label for your collection"
            width="100%"
            value={collectionName}
            onChange={(e) => handleCollectionNameChange(e.target.value)}
          />
          <Space>
            <Button
              type="primary"
              onClick={handleSave}
              loading={isCreatingCustomCollectionImport}
              disabled={!collectionName}
            >
              Save & Import
            </Button>
            <Button onClick={() => handleScreenChange('find')} disabled={isCreatingCustomCollectionImport}>Back</Button>
          </Space>
        </div>
      )}
    </Modal>
  );
};
